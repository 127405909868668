import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  @media screen and (max-width: 800px) {
    padding: 22px;
  }
    #empresaId {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const ContainerForm = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Duas colunas de tamanhos iguais */
  gap: 16px; /* Espaço entre os elementos */

  // Ajuste para telas menores
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr; /* Uma única coluna em telas pequenas */
    padding: 22px;
  }

  label {
    grid-column: span 2; /* Faz os labels ocuparem a largura completa por padrão */
    font-weight: bold;
  }

  input,
  select,
  button {
    width: 100% !important; 
    padding: 8px;
    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }

 

    #empresaId {
    color: ${(props) => props.theme.colors.black2};
    width: 50%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const TitlePage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #262626;
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;

  column-gap: 1rem;
  max-width: 100%;
  div button {
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.black2};
    font-size: 16px;
    background-color: transparent;
    min-width: 140px;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px !important;
    font-weight: 600 !important;
    transition: 0.4s;
  }
  div button.selected {
    border-bottom: 1px solid ${(props) => props.theme.colors.black2};
  }
`;

export const ContainerInputDate = styled.div`
  flex: 1;
  column-gap: 1rem;
  display: flex;
  width: 100%;

  input {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    border-radius: 4px;
    height: 50px;
    width: 48%;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray7};
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const Filter = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  column-gap: 1rem;

  select {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    border-radius: 4px;
    width: 100%;
    height: 50px;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray7};
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonPDF = styled.div`
  display: flex;

  button {
    width: 244px;
    height: 40px;

    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};

    display: flex;
    justify-content: center;
    border-radius: 4px;
    border: none;
    align-items: center;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    transition: 0.2s;

    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
    }
  }
`;

export const Animeted = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  flex-direction: column;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .animate-spin {
    animation: spin 1s linear infinite;
  }

  p {
    font-family: Montserrat;
    font-size: 18px;
    color: ${(props) => props.theme.colors.black2};
    margin: 0px;
  }

  svg {
    color: ${(props) => props.theme.colors.black2};
  }
`;

export const LabelInputForm = styled.h3`
  color: #2f2f2f;
  margin-bottom: 10px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;


export const InputForm = styled.input`
  width: 50%;
  height: 50px;
  margin-bottom: 41px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 16px;
`;
interface PropsButtonForm {
  background: string;
}

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 50%;
  height: 50px;
  background-color: ${(props) => props.background};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.black2
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 8px;
  display: block;  // Faz com que o botão ocupe a largura disponível e fique em uma nova linha
  margin-top: 10px; // Ajuste o valor conforme necessário para o espaçamento
`;
export const DivYouAreHere = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  position: fixed;
  width: 100%;
  z-index: 3;
`;

export const ButtomYouAreHere = styled.button`
  border-radius: 5px;
  border: 0px;
  padding: 10px 20px;
  font-size: 13px;
  margin: 0px 20px;
  box-shadow: none;
`;

export const ToggleButton = styled.button`
position: absolute;
top: 10px;
right: 10px;
z-index: 1000;
background-color: #007bff;
color: white;
border: none;
padding: 8px 12px;
border-radius: 4px;
cursor: pointer;
font-size: 14px;

&:hover {
  background-color: #0056b3;
}

&:active {
  background-color: #003f7f;
}
`;

export const MinimizedContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f7f7f7;
  }
`;

interface ButtonProps {
  disabled?: boolean;
  isSelected?: boolean;
}

export const ButtonTab = styled.button<ButtonProps>`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #d3d3d3;
      color: #808080;
      cursor: not-allowed;
      opacity: 0.6;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #007bff;
      color: white;
    `}

  ${({ disabled, isSelected }) =>
    !disabled &&
    !isSelected &&
    css`
      background-color: #f8f9fa;
      color: black;

      &:hover {
        background-color: #e2e6ea;
      }
    `}
`;