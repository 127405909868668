import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FiEdit2, FiTrash, FiPlusCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import Search from '../../components/Search-isolate';
import HeaderTable from '../../components/HeaderTable';
import Button from '../../components/Button';
import DropDown from '../../components/DropDown';
import api from '../../services/api';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';

import {
  Container,
  ContainerSearch,
  ContainerTable,
  ContainerAccess,
  AnimationContainer,
  ContentSubBody,
  ContentSubLabel,
} from './styles';

interface FormData {
  usuario: string;
  setor: string;
  acesso: string;
}

interface ResponseGet {
  ID: number;
  CPF: string;
  EMAIL: string;
  acesso: number;
  Nome: string;
  Setor: string;
  SetorID: number;
  idUsuario: number;
}

const UserAccess: React.FC = () => {
  // const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [createUser, setCreateUser] = useState(false); // botao ativa true para abir tela de cadastro de user
  const [editUser, setEditUser] = useState(false); // editar cadastro do user
  const [setor, setSetor] = useState(1);
  const [acesso, setacesso] = useState(1);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [userAdd, setUserAdd] = useState<ResponseGet>(); // dados do usuario para add nivel de acesso
  const [userEdit, setUserEdit] = useState<ResponseGet>(); // dados do usuario para edit nivel de acesso
  const [refresh, setRefresh] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const dropDownSetor = [
    { valor: 'Nenhum', id: 1 },
    { valor: 'Operacional', id: 2 },
    { valor: 'Call Center', id: 3 },
    { valor: 'Diretoria', id: 4 },
    { valor: 'Controladoria', id: 5 },
    { valor: 'Sesmt', id: 6 },
    { valor: 'Administrativo', id: 7 },
    { valor: 'Financeiro', id: 8 },
    { valor: 'Jurídico', id: 9 },
    { valor: 'Compras', id: 10 },
    { valor: 'Estoque', id: 11 },
    { valor: 'Faturamento', id: 12 },
    { valor: 'Recursos Humanos', id: 13 },
    { valor: 'Departamento Pessoal', id: 14 },
    { valor: 'Marketing', id: 16 },
    { valor: 'Todos', id: 15 },
  ];

  const dropDownacesso = [
    { valor: 'Estagiário', id: 1 },
    { valor: 'Auxiliar', id: 2 },
    { valor: 'Assistente', id: 3 },
    { valor: 'Analista', id: 4 },
    { valor: 'Supervisor', id: 5 },
    { valor: 'Gerente', id: 6 },
    { valor: 'Diretor', id: 7 },
    { valor: 'Presidente', id: 8 },
  ];

  const headers = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Setor', field: 'setor' },
    { name: 'Nível de Acesso', field: 'acesso' },
    { name: 'Editar/Remover', field: 'editar' },
  ];
  useEffect(() => {
    setLoading(true);
    api
      .get(`/acesso/getacesso`)
      .then((data) => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await api
        .post('acesso/', {
          user_id: userAdd?.idUsuario,
          acesso,
          setor,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Nível de Acesso do Usuário Criado com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
          setacesso(1);
          setSetor(1);
          setCreateUser(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Usuário já está associado a um setor.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar Nível de Acesso do Usuário',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [setor, acesso, userAdd, refresh]);

  const handleSubmitEdit = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await api
        .put('/acesso/updateAcesso', {
          user_id: userEdit?.idUsuario,
          Novoacesso: acesso,
          NovoSetor: setor,
          setor: userEdit?.SetorID,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Nível de Acesso do Usuário Atualizado com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
          setacesso(1);
          setSetor(1);
          setEditUser(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao Atualizar Nível de Acesso do Usuário',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar Nível de Acesso do Usuário',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [userEdit, acesso, setor, refresh]);

  const handleDelete = useCallback(
    async (userId: number, setorId: number) => {
      try {
        setLoading(true);
        await api
          .delete(`acesso/removeAcesso/`, {
            data: {
              user_id: userId,
              setor: setorId,
            },
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Nível de Acesso do Usuário foi Deletado!',
              showConfirmButton: false,
              timer: 1500,
            });
            setRefresh(!refresh);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao Deletar Nível de Acesso do Usuário',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });

        setacesso(1);
        setSetor(1);
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao Deletar Nível de Acesso do Usuário',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [refresh],
  );

  function acessoNumberToString(id: number): string {
    const data = dropDownacesso.filter((dados) => id === dados.id);
    return data[0].valor;
  }

  function setorAndNivelStringToNumber(valor: string, tipo: string): number {
    if (tipo === 'setor') {
      const data = dropDownSetor.filter((dados) => valor === dados.valor);
      return data[0].id;
    }
    const data = dropDownacesso.filter((dados) => valor === dados.valor);
    return data[0].id;
  }

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];

    if (search || searchCPF) {
      computedResponses = response;
    }

    if (search) {
      // computedResponses = response;
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.Nome.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      // computedResponses = response;
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [response, search, searchCPF]);

  return (
    <Container>
      <ModalConexao />
      <ContainerTable>
        <ContainerSearch>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar Nome"
          />
          <Search
            onSearch={(value: string) => {
              setSearchCPF(value);
            }}
            nomePlaceHolder="Buscar CPF"
          />
        </ContainerSearch>
        <table>
          <HeaderTable headers={headers} />
          <tbody>
            {responseData.map((res: ResponseGet) => (
              <tr key={res.CPF + res.Setor}>
                <td style={{ minWidth: 300 }}>{res.Nome}</td>
                <td>{maskCpf(res.CPF)}</td>
                <td>{res.Setor}</td>
                <td>{acessoNumberToString(res.acesso)}</td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCreateUser(true);
                      setEditUser(false);
                      setUserAdd(res);
                    }}
                  >
                    <FiPlusCircle />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditUser(true);
                      setCreateUser(false);
                      setUserEdit(res);
                    }}
                  >
                    <FiEdit2 />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleDelete(res.idUsuario, res.SetorID);
                    }}
                  >
                    <FiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>
      <ContainerTable>
        {editUser && (
          <>
            <ContainerAccess>
              <AnimationContainer>
                <Form ref={formRef} onSubmit={handleSubmitEdit}>
                  <ContentSubBody style={{ height: 50 }}>
                    <ContentSubLabel
                      style={{
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Arial',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        padding: 12,
                        height: 40,
                        minWidth: 150,
                        maxWidth: 450,
                        backgroundColor: '#ffffff',
                        color: '#888888',
                      }}
                    >
                      {userEdit?.Nome}
                    </p>
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Setor
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setSetor(setorAndNivelStringToNumber(value, 'setor'));
                      }}
                      objetoEnum={dropDownSetor}
                      minWidth={150}
                      style={{ margin: 50 }}
                    />
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Acesso
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setacesso(setorAndNivelStringToNumber(value, 'NA'));
                      }}
                      objetoEnum={dropDownacesso}
                      minWidth={150}
                    />
                    <ContentSubLabel
                      style={{
                        marginLeft: 0,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <Button
                      type="submit"
                      style={{
                        maxWidth: 150,
                        marginLeft: 'auto',
                        marginRight: 3,
                        maxHeight: 40,
                        marginTop: 0,
                      }}
                    >
                      Atualizar
                    </Button>
                    <Button
                      type="button"
                      style={{
                        maxWidth: 150,
                        marginLeft: 0,
                        maxHeight: 40,
                        marginTop: 0,
                        backgroundColor: '#b30000',
                      }}
                      onClick={() => setEditUser(!editUser)}
                    >
                      Cancelar Edição
                    </Button>
                  </ContentSubBody>
                </Form>
              </AnimationContainer>
            </ContainerAccess>
          </>
        )}
        {createUser && (
          <>
            <ContainerAccess>
              <AnimationContainer>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <ContentSubBody style={{ height: 50 }}>
                    <ContentSubLabel
                      style={{
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Arial',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        padding: 12,
                        height: 40,
                        minWidth: 150,
                        maxWidth: 450,
                        backgroundColor: '#ffffff',
                        color: '#888888',
                      }}
                    >
                      {userAdd?.Nome}
                    </p>
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Setor
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setSetor(setorAndNivelStringToNumber(value, 'setor'));
                      }}
                      objetoEnum={dropDownSetor}
                      minWidth={150}
                      style={{ margin: 50 }}
                    />
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Acesso
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setacesso(setorAndNivelStringToNumber(value, 'NA'));
                      }}
                      objetoEnum={dropDownacesso}
                      minWidth={150}
                    />
                    <ContentSubLabel
                      style={{
                        marginLeft: 0,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <Button
                      type="submit"
                      style={{
                        maxWidth: 150,
                        marginLeft: 'auto',
                        marginRight: 3,
                        maxHeight: 40,
                        marginTop: 0,
                      }}
                    >
                      Cadastrar
                    </Button>
                    <Button
                      type="button"
                      style={{
                        maxWidth: 150,
                        marginLeft: 0,
                        maxHeight: 40,
                        marginTop: 0,
                        backgroundColor: '#b30000',
                      }}
                      onClick={() => setEditUser(!editUser)}
                    >
                      Cancelar Adição
                    </Button>
                  </ContentSubBody>
                </Form>
              </AnimationContainer>
            </ContainerAccess>
          </>
        )}
      </ContainerTable>
      {loading && <Loading />}
    </Container>
  );
};

export default UserAccess;
