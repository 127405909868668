import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { uuid } from 'uuidv4';
import jsPDF from 'jspdf';
import moment from 'moment';
import * as AiIcons from 'react-icons/ai';
import * as VscIcons from 'react-icons/vsc';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// @ts-ignore
import Zoom from 'react-img-zoom';
import Tooltip from '../Tooltip';
import HeaderTable from '../HeaderTable';
import Search from '../Search-isolate';
import { maskTel } from '../Input/mask';

import {
  ContainerModal,
  Container,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerButtonsPdfExcel,
  ContainerButtonDetalhes,
  ContainerTableMacon,
  ContainerSearch,
} from './styles';

interface ResponseGet {
  ID: number;
  AMBIENTE_NOME: string;
  EMPRESA: string;
  OCORRENCIAS: [];

  email?: string;
  password?: string;
  dataNascimento?: string;
  cpf?: string;
  telefone?: string;
  companyId?: number;
  ativo?: number;
  coordenador?: number;
  avatar?: string;
  avatar_url?: string;
  tipo?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface ResponseGetHistoric {
  avatar: string;
  data: string;
  dataFinal: string;
  email_usuario: string;
  id_usuario: number;
  imagem: string;
  nome_usuario: string;
  observacao: string;
  ocorrencia: string;
  telefone: string;
}

interface ModalDetalhesAmbientes {
  dados?: ResponseGet;
  dadosHistorico?: ResponseGetHistoric[];
  dadosHistoricoDatas?: string;
  onClose?(): void;
}

const headers = [
  { name: 'Ronda', field: 'nomeAmbiente' },
  { name: 'Colaborador', field: 'nomeColaborador' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  { name: 'Data e Hora', field: 'dataHora' },
  { name: 'Ocorrência', field: 'ocorrencia' },
  { name: 'Observação', field: 'observacao' },
  { name: 'Foto', field: 'foto' },
];

const Modal: React.FC<ModalDetalhesAmbientes> = ({
  onClose = () => {
    '';
  },
  dados,
  dadosHistorico,
  dadosHistoricoDatas,
}) => {
  const [searchColaborador, setSearchColaborador] = useState('');
  const [searchData, setSearchData] = useState('');
  const [searchOcorrencia, setSearchOcorrencia] = useState('');
  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [
    procedimentosHistoricoExcelPdf,
    setProcedimentosHistoricoExcelPdf,
  ] = useState<ResponseGetHistoric[]>([]);
  const [userResponseData, setUserResponseData] = useState<number>();

  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [exibirDetalhesData, setExibirDetalhesData] = useState(false);
  const [dadosHistoricoData, setDadosHistoricoData] = useState<string[]>([]);
  const [dadosHistoricoIdUser] = useState<number[]>([]);
  const [dadosHistoricoDataIndex] = useState<number[]>([]);
  const [newDadosHistoricoData] = useState<ResponseGetHistoric[]>([]);
  const [newDadosHistorico, setNewDadosHistorico] = useState<
    ResponseGetHistoric[]
  >();
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    ResponseGetHistoric[]
  >();
  const [newHistoricoProcedimentos, setNewHistoricoProcedimentos] = useState<
    ResponseGetHistoric[]
  >();
  const [newDetalhesHistoricoData, setNewDetalhesHistoricoData] = useState<
    ResponseGetHistoric[]
  >([]);
  const [
    newDetalhesHistoricoProcedimentos,
    setNewDetalhesHistoricoProcedimentos,
  ] = useState<ResponseGetHistoric[]>([]);
  const [zoomImg, setZoomImg] = useState(false);
  const [img, setImg] = useState('');

  useEffect(() => {
    dadosHistorico !== undefined &&
      dadosHistorico.forEach((element, index) => {
        // if (dadosHistoricoDataInicial.indexOf(element.data) === -1) {
        if (dadosHistoricoIdUser.indexOf(element.id_usuario) === -1) {
          // dadosHistoricoDataInicial.push(element.data);
          dadosHistoricoIdUser.push(element.id_usuario);
          dadosHistoricoDataIndex.push(index);
        }
        // }
      });

    dadosHistorico !== undefined &&
      dadosHistoricoDataIndex !== undefined &&
      dadosHistoricoDataIndex.forEach((element: number) => {
        newDadosHistoricoData.push(dadosHistorico[element]);
      });
    setNewDadosHistorico(newDadosHistoricoData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateFormat = useCallback((dataTemp) => {
    const data = dataTemp.substr(0, 10).split('-').reverse().join('/');
    const hora = dataTemp.substr(11, 8);

    return `${data} ${hora}`;
  }, []);

  const handleButtonDate = useCallback(
    (dado) => {
      dadosHistorico !== undefined &&
        dadosHistorico.forEach((element) => {
          // if (dado.data === element.data) {
          if (dado.id_usuario === element.id_usuario) {
            if (dadosHistoricoData.indexOf(element.data) === -1) {
              dadosHistoricoData.push(element.data);
              newDetalhesHistoricoData.push(element);
            }
          }
          // }
        });
      setNewDetalhesHistorico(newDetalhesHistoricoData);
    },
    [dadosHistorico, newDetalhesHistoricoData, dadosHistoricoData],
  );

  const handleButtonProcedimentos = useCallback(
    (dado) => {
      dadosHistorico !== undefined &&
        dadosHistorico.forEach((element) => {
          if (
            dado.data === element.data &&
            dado.id_usuario === element.id_usuario
          ) {
            newDetalhesHistoricoProcedimentos.push(element);
          }
        });
      setNewHistoricoProcedimentos(newDetalhesHistoricoProcedimentos);
    },
    [dadosHistorico, newDetalhesHistoricoProcedimentos],
  );

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  const handleDownloadPDF = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line
    doc.setFontSize(15);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Histórico da Ronda', 103, 15, 'center');
    doc.setFontSize(12);
    // tamanho 207 tela a4
    let posY: number;
    posY = 22;

    doc.text(`${dados?.AMBIENTE_NOME}`, 103, posY, 'center');
    // inicio bloco de dados pessoais

    dadosHistoricoExcelPdf?.map((res) => {
      posY += 15;
      doc.setFontType('bold');
      doc.text('Colaborador:', 10, posY, 'left');
      let quebraLinha = doc.splitTextToSize(
        `${res.nome_usuario ? res.nome_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('E-mail:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.email_usuario ? res.email_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Telefone:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.telefone ? maskTel(res.telefone) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 30, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Data e Hora:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.dataFinal ? handleDateFormat(res.dataFinal) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 36, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Ocorrência:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.ocorrencia === null ||
          res.ocorrencia === undefined ||
          res.ocorrencia === ''
            ? 'Sem ocorrência'
            : res.ocorrencia
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 35, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Observação:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.observacao === null ||
          res.observacao === undefined ||
          res.observacao === ''
            ? 'Sem observação'
            : res.observacao
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 37, posY, { align: 'left', maxWidth: 150 });

      if (posY > 240) {
        doc.addPage();
        posY = 10;
      }
    });

    // doc.save(`relatorio-ronda-${moment().format('DD-MM-YYYY')}.pdf`);
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }, [dados, dadosHistoricoExcelPdf, handleDateFormat]);

  const handleDownloadPDFDetalhes = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line
    doc.setFontSize(15);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Histórico da Ronda', 103, 15, 'center');
    doc.setFontSize(12);
    // tamanho 207 tela a4
    let posY: number;
    posY = 22;

    doc.text(`${dados?.AMBIENTE_NOME}`, 103, posY, 'center');
    // inicio bloco de dados pessoais

    detalhesHistoricoExcelPdf?.map((res) => {
      posY += 15;
      doc.setFontType('bold');
      doc.text('Colaborador:', 10, posY, 'left');
      let quebraLinha = doc.splitTextToSize(
        `${res.nome_usuario ? res.nome_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('E-mail:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.email_usuario ? res.email_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Telefone:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.telefone ? maskTel(res.telefone) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 30, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Data e Hora:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.dataFinal ? handleDateFormat(res.dataFinal) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 36, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Ocorrência:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.ocorrencia === null ||
          res.ocorrencia === undefined ||
          res.ocorrencia === ''
            ? 'Sem ocorrência'
            : res.ocorrencia
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 35, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Observação:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.observacao === null ||
          res.observacao === undefined ||
          res.observacao === ''
            ? 'Sem observação'
            : res.observacao
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 37, posY, { align: 'left', maxWidth: 150 });

      if (posY > 240) {
        doc.addPage();
        posY = 10;
      }
    });

    // doc.save(`relatorio-ronda-${moment().format('DD-MM-YYYY')}.pdf`);
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }, [dados, detalhesHistoricoExcelPdf, handleDateFormat]);

  const handleDownloadPDFProcedimentos = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line
    doc.setFontSize(15);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Histórico da Ronda', 103, 15, 'center');
    doc.setFontSize(12);
    // tamanho 207 tela a4
    let posY: number;
    posY = 22;

    doc.text(`${dados?.AMBIENTE_NOME}`, 103, posY, 'center');
    // inicio bloco de dados pessoais

    procedimentosHistoricoExcelPdf?.map((res) => {
      posY += 15;
      doc.setFontType('bold');
      doc.text('Colaborador:', 10, posY, 'left');
      let quebraLinha = doc.splitTextToSize(
        `${res.nome_usuario ? res.nome_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('E-mail:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.email_usuario ? res.email_usuario : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Telefone:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.telefone ? maskTel(res.telefone) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 30, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Data e Hora:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.dataFinal ? handleDateFormat(res.dataFinal) : '-'}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 36, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Ocorrência:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.ocorrencia === null ||
          res.ocorrencia === undefined ||
          res.ocorrencia === ''
            ? 'Sem ocorrência'
            : res.ocorrencia
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 35, posY, { align: 'left', maxWidth: 150 });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }

      posY += 6;
      doc.setFontType('bold');
      doc.text('Observação:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.observacao === null ||
          res.observacao === undefined ||
          res.observacao === ''
            ? 'Sem observação'
            : res.observacao
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 37, posY, { align: 'left', maxWidth: 150 });

      if (posY > 240) {
        doc.addPage();
        posY = 10;
      }
    });

    // doc.save(`relatorio-ronda-${moment().format('DD-MM-YYYY')}.pdf`);
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }, [dados, handleDateFormat, procedimentosHistoricoExcelPdf]);

  const responseData = useMemo(() => {
    if (newDadosHistorico !== undefined && dadosHistorico !== undefined) {
      let computedResponses: ResponseGetHistoric[] = [];
      let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
      computedResponses = newDadosHistorico;
      computedResponsesExcelPdf = dadosHistorico;

      if (searchColaborador) {
        computedResponses = computedResponses.filter(
          (res: ResponseGetHistoric) =>
            res.nome_usuario
              .toString()
              .toLowerCase()
              .includes(searchColaborador.toLowerCase()),
        );
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) =>
            res.nome_usuario
              .toString()
              .toLowerCase()
              .includes(searchColaborador.toLowerCase()),
        );
      }
      setDadosHistoricoExcelPdf(computedResponsesExcelPdf);
      return computedResponses;
    }
    dadosHistorico !== undefined && setDadosHistoricoExcelPdf(dadosHistorico);
    return newDadosHistorico;
  }, [dadosHistorico, newDadosHistorico, searchColaborador]);

  const responseDataDetalhes = useMemo(() => {
    if (
      newDetalhesHistorico !== undefined &&
      userResponseData !== undefined &&
      dadosHistorico !== undefined
    ) {
      let computedResponses: ResponseGetHistoric[] = [];
      let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
      computedResponses = newDetalhesHistorico;
      computedResponsesExcelPdf = dadosHistorico;
      if (searchData) {
        computedResponses = computedResponses.filter(
          (res: ResponseGetHistoric) =>
            handleDateFormat(res.dataFinal)
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase()),
        );
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) =>
            res.id_usuario.toString().includes(userResponseData.toString()),
        );
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) =>
            handleDateFormat(res.dataFinal)
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase()),
        );
      }
      setDetalhesHistoricoExcelPdf(computedResponsesExcelPdf);
      return computedResponses;
    }
    dadosHistorico !== undefined &&
      setDetalhesHistoricoExcelPdf(dadosHistorico);
    return newDetalhesHistorico;
  }, [
    dadosHistorico,
    handleDateFormat,
    newDetalhesHistorico,
    searchData,
    userResponseData,
  ]);

  const responseDataProcedimentos = useMemo(() => {
    if (
      newHistoricoProcedimentos !== undefined &&
      dadosHistorico !== undefined
    ) {
      let computedResponses: ResponseGetHistoric[] = [];
      let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
      computedResponses = newHistoricoProcedimentos;
      computedResponsesExcelPdf = newHistoricoProcedimentos;

      if (searchOcorrencia) {
        computedResponses = computedResponses.filter(
          (res: ResponseGetHistoric) =>
            res.ocorrencia
              .toString()
              .toLowerCase()
              .includes(searchOcorrencia.toLowerCase()),
        );
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) =>
            res.ocorrencia
              .toString()
              .toLowerCase()
              .includes(searchOcorrencia.toLowerCase()),
        );
      }
      setProcedimentosHistoricoExcelPdf(computedResponsesExcelPdf);
      return computedResponses;
    }
    newHistoricoProcedimentos !== undefined &&
      setProcedimentosHistoricoExcelPdf(newHistoricoProcedimentos);
    return newHistoricoProcedimentos;
  }, [dadosHistorico, newHistoricoProcedimentos, searchOcorrencia]);

  const handleZoomImg = useCallback(
    (imgString: string) => {
      setZoomImg(!zoomImg);
      setImg(imgString);
    },
    [zoomImg],
  );

  return (
    <>
      <div>
        <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
          <Container>
            <div className="teste">
              {!exibirDetalhes && !exibirDetalhesData && (
                <>
                  <ContainerHeaderDados>
                    <p className="titulo">Histórico da Ronda</p>
                  </ContainerHeaderDados>
                  <ContainerHeaderDados>
                    <strong>{dadosHistoricoDatas}</strong>
                    <ContainerSearch>
                      <div className="divSearch">
                        <Search
                          type="text"
                          className="search1"
                          onSearch={(value: string) => {
                            setSearchColaborador(value);
                          }}
                          nomePlaceHolder="Buscar Colaborador"
                        />
                      </div>
                    </ContainerSearch>
                  </ContainerHeaderDados>
                </>
              )}
              {exibirDetalhes && (
                <>
                  <ContainerHeaderDados>
                    <p className="titulo">Datas do Histórico</p>
                  </ContainerHeaderDados>
                  <ContainerHeaderDados>
                    <strong>{dadosHistoricoDatas}</strong>
                    <ContainerSearch>
                      <div className="divSearch">
                        <Search
                          type="text"
                          className="search1"
                          onSearch={(value: string) => {
                            setSearchData(value);
                          }}
                          nomePlaceHolder="Buscar Data e Hora"
                        />
                      </div>
                    </ContainerSearch>
                  </ContainerHeaderDados>
                </>
              )}
              {exibirDetalhesData && !zoomImg && (
                <>
                  <ContainerHeaderDados>
                    <p className="titulo">Detalhes do Histórico</p>
                  </ContainerHeaderDados>
                  <ContainerHeaderDados>
                    <strong>{dadosHistoricoDatas}</strong>
                    <ContainerSearch>
                      <div className="divSearch">
                        <Search
                          type="text"
                          className="search1"
                          onSearch={(value: string) => {
                            setSearchOcorrencia(value);
                          }}
                          nomePlaceHolder="Buscar Ocorrência"
                        />
                      </div>
                    </ContainerSearch>
                  </ContainerHeaderDados>
                </>
              )}

              <ContainerHeader>
                {!exibirDetalhes &&
                  !exibirDetalhesData &&
                  responseData !== undefined &&
                  responseData.map((res) => (
                    <ContainerMap key={uuid()}>
                      <button
                        className="btnDetalhes"
                        type="button"
                        onClick={() => [
                          setDadosHistoricoData([]),
                          setNewDetalhesHistoricoData([]),
                          handleButtonDate(res),
                          setUserResponseData(res.id_usuario),
                          setExibirDetalhes(true),
                        ]}
                      >
                        <AiIcons.AiOutlineRead size={60} />
                      </button>
                      <div>
                        <strong>
                          <p>
                            Colaborador:{' '}
                            {res.nome_usuario === null ||
                            res.nome_usuario.trim() === ''
                              ? '-'
                              : res.nome_usuario}
                          </p>
                          <p>
                            E-mail:{' '}
                            {res.email_usuario === null ||
                            res.email_usuario.trim() === ''
                              ? '-'
                              : res.email_usuario}
                          </p>
                          <p>
                            Telefone:{' '}
                            {res.telefone === null || res.telefone.trim() === ''
                              ? '-'
                              : maskTel(res.telefone)}
                          </p>
                        </strong>
                      </div>
                    </ContainerMap>
                  ))}
                {dadosHistorico !== undefined &&
                  dados !== undefined &&
                  dadosHistorico.length === 0 && (
                    <div className="historicoVazio">
                      <p>{`Não existe um histórico relacionado à ronda ${dados.AMBIENTE_NOME} para o intervalo de datas atual.`}</p>
                    </div>
                  )}
                {dadosHistorico !== undefined &&
                  dados === undefined &&
                  dadosHistorico.length === 0 && (
                    <div className="historicoVazio">
                      <p>Dados não encontrados para a ronda atual.</p>
                    </div>
                  )}
              </ContainerHeader>

              <ContainerHeader>
                {exibirDetalhes &&
                  responseDataDetalhes !== undefined &&
                  responseDataDetalhes.map((res) => (
                    <ContainerMap key={uuid()}>
                      <button
                        className="btnDetalhes"
                        type="button"
                        onClick={() => [
                          handleButtonProcedimentos(res),
                          setExibirDetalhes(false),
                          setExibirDetalhesData(true),
                        ]}
                      >
                        <BsIcons.BsCalendar size={60} />
                      </button>

                      <div>
                        <p>
                          Colaborador:{' '}
                          {res.nome_usuario === null ? '-' : res.nome_usuario}
                        </p>
                        <p>
                          E-mail:{' '}
                          {res.email_usuario === null ? '-' : res.email_usuario}
                        </p>
                        <p>
                          Telefone:{' '}
                          {res.telefone === null ? '-' : maskTel(res.telefone)}
                        </p>
                        <strong>
                          <p>
                            Data:{' '}
                            {res.data === null
                              ? '-'
                              : handleDateFormat(res.data)}
                          </p>
                        </strong>
                      </div>
                    </ContainerMap>
                  ))}
              </ContainerHeader>

              <ContainerHeader>
                {exibirDetalhesData &&
                  !zoomImg &&
                  responseDataProcedimentos !== undefined &&
                  responseDataProcedimentos.map((res) => (
                    <ContainerMap key={uuid()}>
                      {res.imagem === null ||
                      res.imagem.split('/').pop() === 'null' ? (
                        <button type="button" id="iconImgNull">
                          <AiIcons.AiOutlineException size={80} />
                        </button>
                      ) : (
                        <button
                          className="btnImg"
                          type="button"
                          onClick={() => handleZoomImg(res.imagem)}
                        >
                          <img
                            src={res.imagem}
                            alt={res.ocorrencia === null ? '-' : res.ocorrencia}
                          />
                        </button>
                      )}

                      <div>
                        <p>
                          Colaborador:{' '}
                          {res.nome_usuario === null ? '-' : res.nome_usuario}
                        </p>
                        <p>
                          E-mail:{' '}
                          {res.email_usuario === null ? '-' : res.email_usuario}
                        </p>
                        <p>
                          Telefone:{' '}
                          {res.telefone === null ? '-' : maskTel(res.telefone)}
                        </p>
                        <p>
                          Data:{' '}
                          {res.dataFinal === null
                            ? '-'
                            : handleDateFormat(res.dataFinal)}
                        </p>
                        <strong>
                          <p>
                            Ocorrência:{' '}
                            {res.ocorrencia === null ||
                            res.ocorrencia === undefined ||
                            res.ocorrencia === ''
                              ? 'Sem ocorrências'
                              : res.ocorrencia}
                          </p>
                          <p>
                            Observação:{' '}
                            {res.observacao === null ||
                            res.observacao === undefined ||
                            res.observacao === ''
                              ? 'Sem observações'
                              : res.observacao}
                          </p>
                        </strong>
                      </div>
                    </ContainerMap>
                  ))}
              </ContainerHeader>

              {zoomImg && (
                <div className="zoomImg">
                  <Zoom img={img} zoomScale={1} width={558} height={443} />
                </div>
              )}
            </div>

            {!exibirDetalhes && !exibirDetalhesData && (
              <>
                <ContainerButtonAtualizar>
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                </ContainerButtonAtualizar>
                <ContainerButtonsPdfExcel>
                  <Tooltip title="Exportar para PDF" className="classPdf">
                    <button
                      type="button"
                      id="pdf"
                      onClick={() => {
                        handleDownloadPDF();
                      }}
                    >
                      <VscIcons.VscFilePdf size={22} />
                    </button>
                  </Tooltip>

                  <Tooltip title="Exportar para Excel" className="classExcel">
                    <ReactHTMLTableToExcel
                      type="button"
                      id="excel"
                      table="tableListagemDadosAmbientes"
                      filename={`relatorio-ronda-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText={<RiIcons.RiFileExcel2Line size={22} />}
                    />
                  </Tooltip>
                </ContainerButtonsPdfExcel>
              </>
            )}

            {exibirDetalhes && (
              <>
                <ContainerButtonDetalhes>
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setExibirDetalhes(false);
                      setSearchColaborador('');
                      setSearchData('');
                      setSearchOcorrencia('');
                    }}
                  >
                    Voltar
                  </button>
                </ContainerButtonDetalhes>
                <ContainerButtonsPdfExcel>
                  <Tooltip title="Exportar para PDF" className="classPdf">
                    <button
                      type="button"
                      id="pdf"
                      onClick={() => {
                        handleDownloadPDFDetalhes();
                      }}
                    >
                      <VscIcons.VscFilePdf size={22} />
                    </button>
                  </Tooltip>

                  <Tooltip title="Exportar para Excel" className="classExcel">
                    <ReactHTMLTableToExcel
                      type="button"
                      id="excel"
                      table="tableListagemDetalhesAmbientes"
                      filename={`relatorio-ronda-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText={<RiIcons.RiFileExcel2Line size={22} />}
                    />
                  </Tooltip>
                </ContainerButtonsPdfExcel>
              </>
            )}

            {exibirDetalhesData && !zoomImg && (
              <>
                <ContainerButtonDetalhes>
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setExibirDetalhesData(false);
                      setExibirDetalhes(true);
                      setNewDetalhesHistoricoProcedimentos([]);
                      setSearchColaborador('');
                      setSearchData('');
                      setSearchOcorrencia('');
                    }}
                  >
                    Voltar
                  </button>
                </ContainerButtonDetalhes>
                <ContainerButtonsPdfExcel>
                  <Tooltip title="Exportar para PDF" className="classPdf">
                    <button
                      type="button"
                      id="pdf"
                      onClick={() => {
                        handleDownloadPDFProcedimentos();
                      }}
                    >
                      <VscIcons.VscFilePdf size={22} />
                    </button>
                  </Tooltip>

                  <Tooltip title="Exportar para Excel" className="classExcel">
                    <ReactHTMLTableToExcel
                      type="button"
                      id="excel"
                      table="tableListagemProcedimentosAmbientes"
                      filename={`relatorio-ronda-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText={<RiIcons.RiFileExcel2Line size={22} />}
                    />
                  </Tooltip>
                </ContainerButtonsPdfExcel>
              </>
            )}

            {zoomImg && (
              <ContainerButtonDetalhes>
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Ok
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setZoomImg(!zoomImg);
                  }}
                >
                  Voltar
                </button>
              </ContainerButtonDetalhes>
            )}
          </Container>
        </ContainerModal>
      </div>

      <ContainerTableMacon>
        <table id="tableListagemDadosAmbientes">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf !== undefined &&
              dadosHistoricoExcelPdf.map((res) => (
                <tr key={uuid()}>
                  <td>
                    {dados?.AMBIENTE_NOME === null ? '-' : dados?.AMBIENTE_NOME}
                  </td>
                  <td>{res.nome_usuario === null ? '-' : res.nome_usuario}</td>
                  <td>
                    {res.email_usuario === null ? '-' : res.email_usuario}
                  </td>
                  <td>{res.telefone === null ? '-' : res.telefone}</td>
                  <td>
                    {res.dataFinal === null
                      ? '-'
                      : handleDateFormat(res.dataFinal)}
                  </td>
                  <td>
                    {res.ocorrencia === null || res.ocorrencia.trim() === ''
                      ? 'Sem ocorrências'
                      : res.ocorrencia}
                  </td>
                  <td>
                    {res.observacao === null || res.observacao.trim() === ''
                      ? 'Sem observações'
                      : res.observacao}
                  </td>
                  <td>
                    {res.imagem === null ||
                    res.imagem.split('/').pop() === 'null' ? (
                      'Sem Foto'
                    ) : (
                      <a href={res.imagem === null ? '-' : res.imagem}>
                        Ir para Foto
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesAmbientes">
          <HeaderTable headers={headers} />
          <tbody>
            {detalhesHistoricoExcelPdf !== undefined &&
              detalhesHistoricoExcelPdf.map((res) => (
                <tr key={uuid()}>
                  <td>
                    {dados?.AMBIENTE_NOME === null ? '-' : dados?.AMBIENTE_NOME}
                  </td>
                  <td>{res.nome_usuario === null ? '-' : res.nome_usuario}</td>
                  <td>
                    {res.email_usuario === null ? '-' : res.email_usuario}
                  </td>
                  <td>{res.telefone === null ? '-' : res.telefone}</td>
                  <td>
                    {res.dataFinal === null
                      ? '-'
                      : handleDateFormat(res.dataFinal)}
                  </td>
                  <td>
                    {res.ocorrencia === null || res.ocorrencia.trim() === ''
                      ? 'Sem ocorrências'
                      : res.ocorrencia}
                  </td>
                  <td>
                    {res.observacao === null || res.observacao.trim() === ''
                      ? 'Sem observações'
                      : res.observacao}
                  </td>
                  <td>
                    {res.imagem === null ||
                    res.imagem.split('/').pop() === 'null' ? (
                      'Sem Foto'
                    ) : (
                      <a href={res.imagem === null ? '-' : res.imagem}>
                        Ir para Foto
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemProcedimentosAmbientes">
          <HeaderTable headers={headers} />
          <tbody>
            {procedimentosHistoricoExcelPdf !== undefined &&
              procedimentosHistoricoExcelPdf.map((res) => (
                <tr key={uuid()}>
                  <td>
                    {dados?.AMBIENTE_NOME === null ? '-' : dados?.AMBIENTE_NOME}
                  </td>
                  <td>{res.nome_usuario === null ? '-' : res.nome_usuario}</td>
                  <td>
                    {res.email_usuario === null ? '-' : res.email_usuario}
                  </td>
                  <td>{res.telefone === null ? '-' : res.telefone}</td>
                  <td>
                    {res.dataFinal === null
                      ? '-'
                      : handleDateFormat(res.dataFinal)}
                  </td>
                  <td>
                    {res.ocorrencia === null || res.ocorrencia.trim() === ''
                      ? 'Sem ocorrências'
                      : res.ocorrencia}
                  </td>
                  <td>
                    {res.observacao === null || res.observacao.trim() === ''
                      ? 'Sem observações'
                      : res.observacao}
                  </td>
                  <td>
                    {res.imagem === null ||
                    res.imagem.split('/').pop() === 'null' ? (
                      'Sem Foto'
                    ) : (
                      <a href={res.imagem === null ? '-' : res.imagem}>
                        Ir para Foto
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </>
  );
};

export default Modal;
