import React, { useState } from 'react';
import styled from 'styled-components';

// Estilos do Modal
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const ModalHeader = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  color: #333;
`;

const ModalButton = styled.button`
  margin: 8px;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #003f7f;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }

  &:active {
    background-color: #4e555b;
  }
`;

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (toGroup: number) => void;
  grupos: Grupo[];
  itemName: string;
};

interface Grupo {
  grupo: number;
  localizacoes: Localizacao[];
}

interface Localizacao {
  id: number;
  name: string;
}

type ModalState = {
  isOpen: boolean;
  itemId: number | null;
  fromGroup: number | null;
  itemName: string;
};

export const ModalAlterarGrupoRota: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, grupos, itemName }) => {
  const [targetGroup, setTargetGroup] = useState<number | null>(null);

  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <ModalHeader>Mover "{itemName}" para outro grupo</ModalHeader>
        <select
          onChange={(e) => setTargetGroup(Number(e.target.value))}
          defaultValue=""
          style={{
            width: '100%',
            padding: '8px',
            marginBottom: '16px',
            fontSize: '14px',
            color: 'black'
          }}
        >
          <option value="" disabled>
            Selecionar grupo
          </option>
          {grupos.map((g) => (
            <option key={g.grupo} style={{color: 'black'}} value={g.grupo}>
              Grupo {g.grupo}
            </option>
          ))}
        </select>
        <div>
          <ModalButton
            onClick={() => {
              if (targetGroup !== null) {
                onConfirm(targetGroup);
              }
            }}
            disabled={targetGroup === null}
          >
            Confirmar
          </ModalButton>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
};
