import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://bce0-54-94-95-6.ngrok.io/',
  // baseURL: 'http://161.35.50.129',
  // baseURL: 'https://dev.api.appgestaoqrseguranca.com/',
  baseURL: 'https://api.appgestaoqrseguranca.com/',
});

export default api;
