import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding: 58px 72px 48px 49px;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #cancel {
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.gray12};
      color: ${(props) => props.theme.colors.gray12};
    }

    button {
      width: 45%;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const ContainerCadastro = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 36px 74px 30px 74px;
  margin-top: 16px;
  border-radius: 8px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;
export const DivLineBottom = styled.div`
  width: 100%;
  height: 1px;
  margin: 25px auto 25px auto;

  background-color: ${(props) => props.theme.colors.gray1};
`;
export const HeaderForm = styled.h3`
  text-align: center;

  color: ${(props) => props.theme.colors.black2};
  font-size: 16px;
  font-weight: 700;
`;
export const LabelInputForm = styled.h3`
  color: ${(props) => props.theme.colors.black2};
  margin-bottom: 10px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;

export const SelectFilial = styled.select`
  color: ${(props) => props.theme.colors.black2};
  width: 100%;
  height: 48px;

  border: 1px solid ${(props) => props.theme.colors.white4};
  border-radius: 4px;

  font-size: 14px;
  font-weight: 600;
`;
