import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  position: relative;
  height: 320px;
  max-width: 500px;
  width: 90%;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black2};
  border-radius: 8px;
  margin: auto auto;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.white};
    height: 48px;
    width: 48px;
    border-start-end-radius: 8px;
    border-end-start-radius: 12px;
    margin-left: auto;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  svg {
    margin: 0px auto;
    height: 100px;
    width: 100px;
    color: ${(props) => props.theme.colors.gray8};
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  margin: 0px auto;
  justify-content: center;
  .titulo {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;
  }
  p {
    margin-top: 10px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  button {
    padding: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;
    border: none;
    min-width: 200px;
  }
`;
