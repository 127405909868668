import styled from 'styled-components';

export const ContainerPageDefault = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding: 58px 72px 48px 49px;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;
