import React, { useEffect, useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';
import NewModal from '../../../components/NewModal';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { UsuarioBD } from '../../../models/User';
import { ButtonClose, Container, ContainerBody } from './styles';
import { Rota } from '../../../models/Rota';
import {
  DivLineBottom,
  HeaderForm,
  LabelInputForm,
} from '../../DashboardOcorrencias/styles';
import {
  ButtonForm,
  ContainerButtonForm,
} from '../../CadastroCliente copy/styles';
import { Visita } from '../../../models/Visita';

interface Props {
  displayCardHide(): void;
  isOpen: boolean;
  onClose(): void;
  returnId(id: number): void;
  item: Visita;
}

export const ModalEditRota: React.FC<Props> = ({
  displayCardHide,
  isOpen,
  onClose,
  item,
  returnId,
}) => {
  const { empresaPrincipal } = useAuth();
  const [supervisores, setSupervisores] = useState<UsuarioBD[]>([]);
  const [currentSupervisor, setCurrentSupervisor] = useState(0);
  const [sendRouteData] = useState({
    visitaId: Number(item.id) || 0,
    supervisorId: 0,
    data: item.data,
  });

  const getItens = useCallback(async () => {
    const responseFuncionarios = await api.get(
      `/allUsers/${empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 1}`,
    );
    setSupervisores(
      responseFuncionarios.data.reduce(
        (
          acc: any,
          curr: { acesso: string; turno: 'DIURNO' | 'Diário' | 'Noturno' },
        ) => {
          const valueAcumulate = acc;

          if (curr.acesso === '2') {
            if (item.Posto.turno === 'Diurno' && curr.turno !== 'Noturno') {
              valueAcumulate.push(curr);
              return valueAcumulate;
            }
            if (item.Posto.turno === 'Noturno' && curr.turno === 'Noturno') {
              valueAcumulate.push(curr);
              return valueAcumulate;
            }
          }
          return valueAcumulate;
        },
        [],
      ),
    );
  }, [empresaPrincipal, item.Posto.turno]);

  const sendRoute = useCallback(async () => {
    if (currentSupervisor !== 0) {
      sendRouteData.supervisorId = currentSupervisor;
      api.patch('/rotas/transferir', [sendRouteData]).then((resp) => {
        Swal.fire({
          icon: 'success',
          title: 'Visita transferida para outro supervisor com sucesso!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });
        returnId(sendRouteData.visitaId);
        displayCardHide();
        onClose();
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Informe o supervisor! ',
      });
    }
  }, [currentSupervisor, displayCardHide, onClose, returnId, sendRouteData]);

  useEffect(() => {
    getItens();
  }, [getItens]);

  return (
    <NewModal isVisible={isOpen}>
      <ButtonClose>
        <button type="button" onClick={onClose}>
          <FiX size={22} />
        </button>
      </ButtonClose>
      <ContainerBody>
        <HeaderForm>Tranferência da visita</HeaderForm>
        <DivLineBottom />

        <LabelInputForm>Supervisor:</LabelInputForm>
        <select
          name="supervisores"
          id="supervisores"
          onChange={(e) => {
            setCurrentSupervisor(parseInt(e.target.value, 10));
          }}
        >
          <option value="0">Selecionar supervisor:</option>
          {supervisores &&
            supervisores.map((eSup) => {
              return (
                <option key={eSup.id} value={eSup.id}>
                  {eSup.nome}
                </option>
              );
            })}
        </select>
        <ContainerButtonForm>
          <ButtonForm type="button" background="#FFFFFF" onClick={onClose}>
            Cancelar
          </ButtonForm>
          <ButtonForm
            type="button"
            background="#393B41"
            onClick={() => {
              sendRoute();
            }}
          >
            Salvar
          </ButtonForm>
        </ContainerButtonForm>
      </ContainerBody>
    </NewModal>
  );
};
