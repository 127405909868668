import styled from 'styled-components';

export const Container = styled.div`
  .containerExit {
    display: flex;
    justify-content: end;
    padding: 20px 20px;
    a {
      border: none;
      background-color: transparent;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .bodyModal {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    img {
      width: 100px;
      height: 100px;
      margin: 20px auto;
    }

    h1 {
      margin: 20px auto;
      font-weight: 700;
    }

    h2 {
      margin: 0px auto;
    }
  }
`;
