import React from 'react';
import { TitlePage } from '../../components/TitlePage/styled';
import Plus from '../../assets/plus.png';
import ModalConexao from '../../components/ModalConexao';

import { Container } from '../DashboardOcorrencias/styles';
import { BtnAdd, ContainerIframe, ContainerRow } from './styles';

const Dashboard: React.FC = () => {
  const buildIframe = (): any => {
    return (
      <iframe
        title="bi"
        width="600"
        height="373.5"
        // src="https://app.powerbi.com/view?r=eyJrIjoiYWE3OWIxYjUtYzZjZS00ZGM1LWJlOGQtNGVjNDkwNGRlNmZiIiwidCI6ImY0N2IyYzUzLWIwODQtNDQ5Ny05OGI5LThhNzBkOGNmMTUyMiJ9"
        frameBorder="0"
        allowFullScreen
      />
    );
  };

  return (
    <>
      <ModalConexao />
      <Container>
        <ContainerRow>
          <TitlePage>Dashboard</TitlePage>

          <BtnAdd type="button">
            <img src={Plus} alt="asd" />
            <p>Adicionar</p>
          </BtnAdd>
        </ContainerRow>
        <ContainerIframe>{buildIframe()}</ContainerIframe>
      </Container>
    </>
  );
};

export default Dashboard;
