import styled from 'styled-components';

interface Props {
  color: string;
}

export const Card = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

export const Line = styled.div<Props>`
  height: 100%;
  width: 10px;
  min-width: 10px;
  background: ${(props) => props.color};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Info = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  font-family: Montserrat;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};

  b {
    margin-right: 6px;
  }
`;

export const DivTrioPhoto = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const DivTrio = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImagePerfil = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 35px;
`;
