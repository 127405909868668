import { Ronda } from './Rondas';
import { UsuarioBD } from './User';

export interface Revision {
  horaIni: string;
  horaFim: string;
}

export interface AgendaRonda {
  Ronda: Ronda;
  Usuario: UsuarioBD;
  active: boolean;
  companyId: number;
  createdAt: string;
  id: number;
  updatedAt: string;
  userId: number;
  userId2: number | string;
  freqAno: string;
  freqMes: string;
  freqSem: string;
  freqSem2: string;
  horaFim: string;
  horaIni: string;
  type: string;
  vigFim: string;
  vigIni: string;
  Revisoes: Revision[];
}

export interface AgendamentoRonda {
  id: number;
  companyId: number;
  agendaId: number;
  userId: number;
  dataAgend: string;
  horaIni: string;
  horaFim: string;
  status: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  viraNoite: boolean;
}

interface Select {
  value: string;
  label: string;
}

export const types: Select[] = [
  { label: 'Semanal', value: 'sem' },
  { label: 'Mensal', value: 'men' },
  { label: 'Anual', value: 'ano' },
  { label: 'Semana Alternada', value: '12X' },
];

export interface PostAgenda {
  Ronda: {
    id: number;
  };
  id?: number;
  userId: number;
  type: string; // ex: sem = semanal, men = mensal, ano = anual, null = sem periodicidade
  freqSem: string;
  freqMes: string;
  freqAno: string;
  vigIni: string;
  vigFim: string;
  horaIni: string;
  horaFim: string;
  companyId: number;
  active: boolean;
}

export const dataAgendaRonda: AgendaRonda[] = [];
