import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Select from 'react-select';

import NewModal from '../../../components/NewModal';
import Button from '../../../components/Button';
import { FilterOBJ, OptionSelect } from '../interface';

import { ButtonClose, Container, ButtonFiltro } from './styles';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  filterPrin: FilterOBJ;
  setFilterPrin: (val: FilterOBJ) => void;
  empresas: OptionSelect[];
  users: OptionSelect[];
  clientes: OptionSelect[];
  rotas: OptionSelect[];
}

export const ModalFilterAudRota: React.FC<Props> = ({
  isVisible,
  onClose,
  empresas,
  filterPrin,
  setFilterPrin,
  users,
  clientes,
  rotas,
}) => {
  const [filter, setFilter] = useState<FilterOBJ>({
    companyId: [],
    clientId: [],
    userId: [],
    rotaId: [],
  });

  useEffect(() => {
    if (isVisible) {
      setFilter(filterPrin);
    }
  }, [filterPrin, isVisible]);

  return (
    <NewModal isVisible={isVisible}>
      <ButtonClose>
        <button type="button" onClick={onClose}>
          <FiX size={22} />
        </button>
      </ButtonClose>
      <Container>
        <header>
          <h1>Filtros</h1>
        </header>

        <p>Empresa</p>
        <Select
          isClearable
          isMulti
          name="empresas"
          options={empresas}
          maxMenuHeight={200}
          // isLoading={loadingEmpresas}
          styles={{
            clearIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 3,
              color: '#323232',
            }),
          }}
          value={filter.companyId}
          onChange={(e) => {
            setFilter({
              ...filter,
              companyId: e.map((i) => ({ label: i.label, value: i.value })),
            });
          }}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
        />
        <p>Supervisor</p>
        <Select
          isClearable
          isMulti
          name="supervisor"
          options={users}
          maxMenuHeight={200}
          // isLoading={loadingColaboradores}
          // formatOptionLabel={formatOptionLabel}
          styles={{
            clearIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 3,
              color: '#323232',
            }),
          }}
          value={filter.userId}
          onChange={(e) => {
            setFilter({
              ...filter,
              userId: e.map((i) => ({ label: i.label, value: i.value })),
            });
          }}
          // value={colaboradoresForm}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
        />

        <p>Clientes</p>
        <Select
          isClearable
          isMulti
          name="clientes"
          options={clientes}
          maxMenuHeight={200}
          // isLoading={loadingColaboradores}
          // formatOptionLabel={formatOptionLabel}
          styles={{
            clearIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 3,
              color: '#323232',
            }),
          }}
          value={filter.clientId}
          onChange={(e) => {
            setFilter({
              ...filter,
              clientId: e.map((i) => ({ label: i.label, value: i.value })),
            });
          }}
          // value={colaboradoresForm}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
        />

        <p>Rotas</p>
        <Select
          isClearable
          isMulti
          name="rotas"
          options={rotas}
          maxMenuHeight={200}
          // isLoading={loadingColaboradores}
          // formatOptionLabel={formatOptionLabel}
          styles={{
            clearIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: '2px 8px',
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 3,
              color: '#323232',
            }),
          }}
          value={filter.rotaId}
          onChange={(e) => {
            setFilter({
              ...filter,
              rotaId: e.map((i) => ({ label: i.label, value: i.value })),
            });
          }}
          // value={colaboradoresForm}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
        />

        <br style={{ margin: '30px 0px' }} />

        <div className="action">
          <Button
            type="button"
            onClick={() => {
              setFilterPrin(filter);
              onClose();
            }}
          >
            Filtrar
          </Button>
          <ButtonFiltro
            type="button"
            onClick={() => {
              setFilter({
                companyId: [],
                clientId: [],
                userId: [],
                rotaId: [],
              });
            }}
          >
            Limpar filtros
          </ButtonFiltro>
        </div>
      </Container>
    </NewModal>
  );
};
