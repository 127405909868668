import React, { useEffect, useState } from 'react';
import { Container, TituloPrincipal, GrupoContainer, TituloGrupo, ListaLocalizacoes, ItemLocalizacao, ItemNome, BotaoMover, IconButton, ButtonSalvar, ButtonGerarRotaGrupo, IconsWrapper } from './styles';
import { SelectForm } from '../ElementsForms/styles';
import { User } from '../../models/User';
import api from '../../services/api';
import { Loading } from '../Loading';
import { ModalAlterarGrupoRota } from '../ModalAlterarGrupoRota';
import { FaCheck, FaCogs, FaExchangeAlt, FaSave, FaTrash } from 'react-icons/fa';
import { ModalConfirmGrupos } from '../ModalConfirmGrupos';
import { Postos } from '../../models/Postos';
import { Grupo } from './types';

interface Props {
  grupos: Grupo[];
  listSupervisores: User[];
  salvos?: boolean;
  atualizarGrupos: (novoGrupo: any) => void;
  gerarRotaGrupo: (novoGrupo: any, supervisores: any) => void;
  deletarGrupo: (novoGrupo: any) => void;
}

const Grupos: React.FC<Props> = ({ atualizarGrupos, gerarRotaGrupo, deletarGrupo, grupos, listSupervisores, salvos = false }) => {
  const [supervisores, setSupervisores] = useState<Record<number, string>>({});
  const [gruposA, setGrupos] = useState<Grupo[]>(grupos);
  const [modalData, setModalData] = useState<{
    isOpen: boolean;
    itemId: number | null;
    fromGroup: number | null;
    itemName: string;
  }>({ isOpen: false, itemId: null, fromGroup: null, itemName: '' });

  const [modalConfirmData, setModalConfirmData] = useState<{
    isOpen: boolean;
  }>({ isOpen: false });

  const openModal = (itemId: number, fromGroup: number, itemName: string) => {
    setModalData({ isOpen: true, itemId, fromGroup, itemName });
  };

  const closeModal = () => {
    setModalData({ isOpen: false, itemId: null, fromGroup: null, itemName: '' });
  };

  const confirmMove = (toGroup: number) => {
    if (modalData.itemId !== null && modalData.fromGroup !== null) {
      moveItemToGroup(modalData.itemId, modalData.fromGroup, toGroup);
    }
    closeModal();
  };

  const handleSupervisorChange = (grupoId: number, supervisorId: string) => {
    setSupervisores((prev) => ({
      ...prev,
      [grupoId]: supervisorId, // Atualiza apenas o supervisor do grupo correspondente
    }));
  };

  const moveItemToGroup = (itemId: number, fromGroup: number, toGroup: number) => {
    setGrupos((prev) => {
      const origem = prev.find((g) => g.grupo === fromGroup);
      const destino = prev.find((g) => g.grupo === toGroup);

      if (!origem || !destino) return prev; // Se algum grupo não existir, mantém o estado atual

      const item = origem.localizacoes.find((loc) => loc.id === itemId);
      if (!item) return prev;

      origem.localizacoes = origem.localizacoes.filter((loc) => loc.id !== itemId);

      destino.localizacoes = [...destino.localizacoes, item];

      return [...prev];
    });
  };

  useEffect(() => {
    if (salvos) {
      grupos.map((grupo) => {
        console.log(grupo)
        handleSupervisorChange(grupo.grupo, grupo.supervisorId!.toString());
      })
    } else {
      atualizarGrupos(grupos)
    }

    setGrupos(grupos);
  }, [grupos]);

  const salvarEstruturaGrupos = () => {
    setModalConfirmData({ isOpen: true });
  };

  const ongerarRotaGrupo = async (grupo: Grupo | Grupo[]) => {
    gerarRotaGrupo(grupo, supervisores)
  }

  const onDeletarGrupo = async (grupo: Grupo | Grupo[]) => {
    deletarGrupo(grupo)
  }

  const confirmarGrupos = async () => {
    gruposA.map(async (grupo) => {
      await api.post('/grupos', {
        grupo: grupo.grupo,
        supervisorId: supervisores[grupo.grupo],
        localizacoes: grupo.localizacoes.map((loc) => loc.id),
      });
    })
    const response = await api.post('/grupos', {})
  }

  return (
    <Container>
      {gruposA.map((grupo) => (
        <GrupoContainer key={grupo.grupo}>
          <TituloGrupo>Grupo {grupo.grupo}</TituloGrupo>
          <div style={{ display: 'flex', gap: '10px' }}>

            <ButtonSalvar gerar onClick={() => ongerarRotaGrupo(grupo)} title='Gerar a rota desse grupo'>
              <FaCogs size={20} /> {/* Ícone substituindo o texto */}
            </ButtonSalvar>
            {salvos && <ButtonSalvar trash onClick={() => onDeletarGrupo(grupo)} title='Apagar grupo'>
              <FaTrash size={20} /> {/* Ícone substituindo o texto */}
            </ButtonSalvar>}
          </div>
          <SelectForm
            id="supervisorId"
            onChange={({ currentTarget }) =>
              handleSupervisorChange(grupo.grupo, currentTarget.value)
            }
            value={supervisores[grupo.grupo] || ''}
            style={{ marginBottom: '41px' }}
          >
            <option value="">Selecionar supervisor para esse grupo</option>;
            {listSupervisores.map((e) => {
              return <option value={e.id}>{e.nome.toUpperCase()}</option>;
            })}
          </SelectForm>
          <ListaLocalizacoes>
            {grupo.localizacoes.map((localizacao) => (
              <ItemLocalizacao onClick={() =>
                openModal(localizacao.id, grupo.grupo, localizacao.name)
              }>
                <IconsWrapper>
                  <ItemNome>{localizacao.name}</ItemNome>
                  {localizacao.rotaGerada && (<>
                    <i style={{ color: "black" }}>Rota gerada em: {((new Date(localizacao.rotaGerada.createdAt)).toLocaleString()).split(',')[0]}</i>
                    <IconButton title="Rota gerada">
                      <FaCheck color='green' />
                    </IconButton>
                  </>
                  )}
                  <IconButton
                    onClick={() =>
                      openModal(localizacao.id, grupo.grupo, localizacao.name)
                    }
                    title="Mover para outro grupo"
                  >
                    <FaExchangeAlt />
                  </IconButton>
                </IconsWrapper>
              </ItemLocalizacao>
            ))}
          </ListaLocalizacoes>

        </GrupoContainer>
      ))}
      <ModalAlterarGrupoRota
        isOpen={modalData.isOpen}
        onClose={closeModal}
        onConfirm={confirmMove}
        grupos={grupos}
        itemName={modalData.itemName}
      />
      <ModalConfirmGrupos
        isOpen={modalConfirmData.isOpen}
        onClose={closeModal}
        confirmarGrupos={() => {
          confirmarGrupos();
        }}
      />
    </Container>
  );
};

export default Grupos;
