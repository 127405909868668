import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  animation: ${appearFromRight} 1s;
  padding: 58px 72px 48px 49px;
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }

  .widthTotal {
    width: 100%;
  }
`;
