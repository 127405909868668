import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.button`
  background: ${(props) => props.theme.colors.black3};
  font-family: Montserrat, sans-serif;
  height: 48px;
  border-radius: 4px;
  border: 0;
  color: ${(props) => props.theme.colors.white4};
  width: 102%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  &:hover {
    background: ${(props) => shade(0.2, props.theme.colors.black3)};
  }
`;
