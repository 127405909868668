import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 0px auto;
  h2 {
    margin-top: 40px;
    padding: 10px;
  }
`;

export const ContainerImg = styled.div`
  max-width: 1120px;
  display: flex;
  justify-content: center;
  img {
    margin-top: 30px;
    height: 120px;
  }
`;

export const ContainerBody = styled.div`
  max-width: 1120px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  margin-top: 20px;
`;

export const ContainerTexto = styled.div`
  max-width: 1120px;
  padding: 20px;
  margin: 0px auto;
  p {
    font-size: 16px;
    text-align: justify;
    text-indent: 1.5em;
  }
`;
