import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Leaflet from 'leaflet';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import { FiArrowLeft, FiArrowRight, FiCheck, FiX } from 'react-icons/fi';
// import ReactMapboxGl, { Layer, Source, Marker } from 'react-mapbox-gl';

import Axios from 'axios';
import { ThemeContext } from 'styled-components';
import ModalConexao from '../../components/ModalConexao';
import { GetRota2 } from '../../models/Rota';

import {
  Container,
  ContentInfo,
  Bool,
  DivRotas,
  InputDate,
  Label,
  Option,
  Select,
  ButtonVolt,
  Line,
  Content,
  ButtonVolt2,
  ItemMap,
  ContentRota,
} from './styles';
import './styles.css';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { UsuarioBD } from '../../models/User';
import { CardRotaMap } from '../../components/CardRotaMap';
import { Loading } from '../../components/Loading';
import { getMarkerIcon } from './mapIcon';

import { Coordenates } from '../../models/GoogleMaps';
import { filterFloat } from '../../hooks/md5';
import { GetDirectionsMapbox } from '../DetailsRotaAprov/interface';
import { ModalIntecorrencia } from '../../components/ModalIntercorrencia';

interface Coordenate {
  latitude: number;
  longitude: number;
}

const initCoordenate: Coordenate = {
  latitude: -3.7865847,
  longitude: -38.5101051,
};

export interface GetRota2User extends GetRota2 {
  username: string;
}

interface GroupRota2 {
  data: string;
  rotaId: string;
  companyId: number;
  turno: 'Diurno' | 'Noturno';
  supervisorId: number;
  supervisor: string;
  visitas: GetRota2[];
  coords: Coordenates;
}

export const MapsRotas: React.FC = (): JSX.Element => {
  const { empresaPrincipal } = useAuth();
  const { colors } = useContext(ThemeContext);

  const [map, setMap] = useState<Leaflet.Map | undefined | null>(undefined);
  const [dateFilter, setDateFilter] = useState(moment().format('YYYY-MM-DD'));
  const [options, setOptions] = useState('');
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingRota, setLoadingRota] = useState(false);
  const [optionSupervisor, setOptionSupervisor] = useState<{
    id: number;
    nome: string;
  }>({ id: -1, nome: 'Todos' });
  const [visitasAll, setVisitasAll] = useState<GetRota2[]>([]);
  const [optionsSup, setOptionsSup] = useState<{ id: number; nome: string }[]>(
    [],
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [oldSelectedIndex, setOldSelectedIndex] = useState(0);
  const [activePopup, setActivePopup] = useState<GetRota2 | null>(null);
  const [isShowFil, setIsShowFil] = useState(false);
  const [rotas, setRotas] = useState<GroupRota2[]>([]);
  const [rotaVisible, setRotaVisible] = useState<GroupRota2 | null>(null);
  const [showModalInter, setShowModalInter] = useState(false);

  const handleOptions = useCallback(
    (val: string) => {
      if (options === val) {
        setOptions('');
      } else {
        setOptions(val);
      }
    },
    [options],
  );

  const handleEventClickMarkers = useCallback(
    (local: GetRota2, index: number) => {
      setSelectedIndex(index);
      // ------ INDEX DIFERENTES (ELEMENTOS DIFERENTES FORAM CLICADOS) ---------
      if (oldSelectedIndex !== index) {
        // ----- FECHAR ANTIGO E ABRIR OUTRO ICON ------
        if (activePopup) {
          setActivePopup(local);
          setOldSelectedIndex(index);
        } else {
          // ------------ ABRIR UM NOVO ICON ------------
          setActivePopup(local);
          setOldSelectedIndex(index);
        }
        // ---------- INDEX IGUAIS (ELEMENTOS IGUAIS FORAM CLICADOS) -----------
      } else if (oldSelectedIndex === index) {
        if (activePopup) {
          // ----- FECHAR ------
          setActivePopup(null);
          setOldSelectedIndex(index);
          setSelectedIndex(-1);
        } else {
          // ----- ABRIR ------
          setActivePopup(local);
          setOldSelectedIndex(index);
        }
      }
      // console.log(local);
      // objMarker.openPopup();
    },
    [activePopup, oldSelectedIndex],
  );

  const getColor = useCallback(
    (status: string): string => {
      switch (status) {
        case 'Concluido':
          return colors.success;
        case 'Concluido com pendencias':
          return colors.primary;
        case 'Andamento':
          return colors.info;
        case 'Aprovada':
          return colors.warning;
        case 'A iniciar':
          return colors.warning;
        case 'Cancelado':
          return colors.error;
        case 'Não realizado':
          return colors.error;
        case 'Não realizada[Automática]':
          return colors.error;
        default:
          return colors.gray1;
      }
    },
    [
      colors.error,
      colors.gray1,
      colors.info,
      colors.primary,
      colors.success,
      colors.warning,
    ],
  );

  const getName = useCallback(
    (supID: number) => {
      const find = optionsSup.find((i) => i.id === supID);
      if (find) {
        return find.nome;
      }
      return 'Sem Nome';
    },
    [optionsSup],
  );

  const MarkerRender = useCallback(
    (mark: GetRota2, index: number, indexRot: number, rotaMark: GroupRota2) => {
      return (
        <div key={`${index.toString()}-${indexRot.toString()}}`}>
          <Marker
            icon={getMarkerIcon(index, mark.status)}
            position={[
              Number(mark.Visita.Posto.latitude),
              Number(mark.Visita.Posto.longitude),
            ]}
            keyboard
            eventHandlers={{
              click: () => {
                setRotaVisible(rotaMark);
                handleEventClickMarkers(mark, index);
              },
            }}
          />
          {activePopup !== null && (
            <Popup
              position={[
                filterFloat(String(activePopup.Visita.Posto.latitude)),
                filterFloat(activePopup.Visita.Posto.longitude.toString()),
              ]}
              closeButton={false}
              minWidth={240}
              maxWidth={240}
              className="map-popup"
              eventHandlers={{
                tooltipclose: () => {
                  setActivePopup(null);
                },
              }}
            >
              <div>
                <h3>{activePopup.Visita.Posto.name}</h3>
                <p>{activePopup.Visita.Posto.endereco}</p>
                <p>{getName(activePopup.supervisorId)}</p>
                <p>
                  {activePopup.status} - {activePopup.Visita.turno}
                </p>
                <p>
                  {activePopup.type === 'rota' ? 'VISITA' : 'INTERCORRÊNCIA'}
                </p>
              </div>
            </Popup>
          )}
        </div>
      );
    },
    [activePopup, getName, handleEventClickMarkers],
  );

  const getUserSupervisor = useCallback(async () => {
    try {
      setLoadingUser(true);
      const resp = await api.get(`/allUsers/${empresaPrincipal[0].ID}`);
      const users: UsuarioBD[] = resp.data;
      // console.log(users.filter((item) => item.acesso === '2'));
      setOptionsSup([
        ...users
          .filter((item) => item.acesso === '2')
          .map((item) => ({ id: item.id, nome: item.nome })),
      ]);
      setLoadingUser(false);
    } catch (err) {
      console.log(err);
      setLoadingRota(false);
    }
  }, [empresaPrincipal]);

  useEffect(() => {
    getUserSupervisor();
  }, [getUserSupervisor]);

  const getRotasDia = useCallback(async () => {
    try {
      const date = !dateFilter ? moment().format('YYYY-MM-DD') : dateFilter;
      setLoadingRota(true);
      const resp = await api.get(`/rotas?dataIni=${date}&dataFim=${date}`);
      const visitas: GetRota2[] = resp.data;
      setVisitasAll(
        visitas.filter(
          (item) => item.status !== 'A Aprovar' && item.status !== 'Reprovada',
        ),
      );
      setLoadingRota(false);
    } catch (err) {
      console.log(err);
      setLoadingRota(false);
    }
  }, [dateFilter]);

  useEffect(() => {
    getRotasDia();
  }, [getRotasDia]);

  const getCoordsInRota = useCallback(async (visits: GetRota2[]): Promise<
    Coordenates
  > => {
    try {
      if (visits.length <= 1) {
        return {
          coords: [],
          distance: 0,
          time: 0,
        };
      }

      const locations = visits
        .map(
          (item) =>
            `${item.Visita.Posto.longitude},${item.Visita.Posto.latitude}`,
        )
        .join(';');

      const resp = await Axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${locations}?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`,
      );

      const result: GetDirectionsMapbox = resp.data;

      return {
        coords: result.routes[0].geometry.coordinates.map((item) => ({
          latitude: item[1],
          longitude: item[0],
        })),
        distance: result.routes[0].distance,
        time: result.routes[0].duration,
      };
    } catch (err) {
      console.log(err);
      // console.log(err.message);
      return {
        coords: [],
        distance: 0,
        time: 0,
      };
    }
  }, []);

  const rotasMemo = useCallback(
    async (visitas: GetRota2User[]) => {
      const aux: GroupRota2[] = [];
      visitas.forEach((item) => {
        const findIndex = aux.findIndex(
          (i) =>
            i.data === item.data &&
            i.supervisorId === item.supervisorId &&
            i.rotaId === item.rotaId,
        );
        if (findIndex === -1) {
          const obj: GroupRota2 = {
            data: item.data,
            rotaId: item.rotaId,
            supervisorId: item.supervisorId,
            supervisor: item.username,
            visitas: [item],
            companyId: item.companyId,
            turno: item.Visita.turno,
            coords: {
              coords: [],
              distance: 0,
              time: 0,
            },
          };
          aux.push(obj);
        } else {
          aux[findIndex].visitas.push(item);
        }
      });

      const rots = aux.map((item) => {
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        const vis = item.visitas.sort((a, b) => {
          const a1 = a.Historico.length > 0 ? a.Historico[0].timeIni : now;
          const b1 = b.Historico.length > 0 ? b.Historico[0].timeIni : now;
          if (a1 > b1) return 1;
          if (a1 < b1) return -1;
          return 0;
        });
        return { ...item, visitas: vis };
      });

      const newRots: GroupRota2[] = [];
      rots.forEach(async (i) => {
        const list = i.visitas.filter(
          (vis) =>
            vis.status === 'Concluido' ||
            vis.status === 'Concluido com pendencias',
        );
        const obj: Coordenates = {
          coords: [],
          distance: 0,
          time: 0,
        };
        const coords = list.length > 1 ? await getCoordsInRota(list) : obj;
        newRots.push({
          ...i,
          coords,
        });
      });

      setRotas(newRots);
      return [];
    },
    [getCoordsInRota],
  );

  const visitasMemo: GetRota2User[] = useMemo(() => {
    let visits = [];
    visits = visitasAll;
    if (options === '1') {
      visits = visits.filter(
        (i) => i.status === 'Aprovada' || i.status === 'Não realizado',
      );
    } else if (options === '2') {
      visits = visits.filter((i) => i.status === 'Andamento');
    } else if (options === '3') {
      visits = visits.filter(
        (i) =>
          i.status === 'Concluido' || i.status === 'Concluido com pendencias',
      );
    }

    if (optionSupervisor.id !== -1) {
      visits = visits.filter((i) => i.supervisorId === optionSupervisor.id);
    }

    const vistAll = visits.map((i) => {
      const user = optionsSup.find((item) => i.supervisorId === item.id);
      if (user) {
        return { ...i, username: user.nome };
      }
      return { ...i, username: '' };
    });

    rotasMemo(vistAll);

    return vistAll;
  }, [optionSupervisor.id, options, optionsSup, rotasMemo, visitasAll]);

  return (
    <>
      <ModalConexao />
      {showModalInter && rotaVisible !== null && (
        <ModalIntecorrencia
          onClose={() => setShowModalInter(false)}
          rotaId={rotaVisible.rotaId}
          empresaFilialId={rotaVisible.companyId}
          supervisorID={rotaVisible.supervisorId}
          turno={rotaVisible.turno}
        />
      )}
      <Container>
        <div id="page-map" style={{ marginTop: 75 }}>
          {isShowFil ? (
            <Content>
              <div className="title">
                <p>Rotas</p>
                <ButtonVolt type="button" onClick={() => setIsShowFil(false)}>
                  <FiArrowLeft />
                </ButtonVolt>
              </div>
              <ContentInfo>
                <InputDate
                  type="Date"
                  defaultValue={dateFilter}
                  value={dateFilter}
                  onChange={(event) => {
                    setDateFilter(event.target.value);
                  }}
                />
                <Select
                  onChange={(e) => {
                    setOptionSupervisor({
                      id: parseInt(e.target.value, 10),
                      nome: '',
                    });
                  }}
                >
                  <option value={-1}>Todos</option>
                  {optionsSup.map((supervisor) => (
                    <option key={supervisor.id} value={supervisor.id}>
                      {`${supervisor.id} - ${supervisor.nome.trim()}`}
                    </option>
                  ))}
                </Select>
                <Option
                  onClick={() => {
                    handleOptions('1');
                  }}
                >
                  <Bool selected={options === '1'}>
                    <FiCheck color="#FFFFFF" />
                  </Bool>
                  <Label>A iniciar/Canceladas</Label>
                </Option>
                <Option
                  onClick={() => {
                    handleOptions('2');
                  }}
                >
                  <Bool selected={options === '2'}>
                    <FiCheck color="#FFFFFF" />
                  </Bool>
                  <Label>Andamento</Label>
                </Option>
                <Option
                  onClick={() => {
                    handleOptions('3');
                  }}
                >
                  <Bool selected={options === '3'}>
                    <FiCheck color="#FFFFFF" />
                  </Bool>
                  <Label>Concluídas c/s pendencias</Label>
                </Option>
                <Line />
                <DivRotas>
                  {visitasMemo.map((item, index) => {
                    return (
                      <CardRotaMap
                        item={item}
                        key={`${item.Visita.id}-${index.toString()}`}
                      />
                    );
                  })}
                </DivRotas>
              </ContentInfo>
            </Content>
          ) : (
            <ButtonVolt2 type="button" onClick={() => setIsShowFil(true)}>
              <FiArrowRight size={30} />
            </ButtonVolt2>
          )}
          {rotaVisible !== null && (
            <ContentRota selected={rotaVisible !== null}>
              <div className="title">
                <p>Rota {rotaVisible.rotaId}</p>
                <ButtonVolt type="button" onClick={() => setRotaVisible(null)}>
                  <FiX />
                </ButtonVolt>
              </div>
              <Line />
              <ContentInfo>
                <div className="info">
                  <span>
                    <p>Supervisor:{' '}</p>
                    {rotaVisible.supervisor}
                  </span>
                  <span>
                    <p>Distância:{' '}</p>
                    {(rotaVisible.coords.distance / 1000).toFixed(2)}Km
                  </span>
                  <span>
                    <p>Tempo:{' '}</p>
                    {Math.floor(rotaVisible.coords.time / 60)}min
                  </span>
                </div>
                <div className="list">
                  <div className="tl">
                    <p>Visitas</p>
                    <button
                      type="button"
                      onClick={() => setShowModalInter(true)}
                    >
                      Intercorrência
                    </button>
                  </div>
                  {rotaVisible.visitas.map((vis, index) => (
                    <div
                      className="card"
                      key={`${vis.visitaId}-${index.toString()}`}
                    >
                      <div
                        className="line"
                        style={{ backgroundColor: getColor(vis.status) }}
                      />
                      <div className="info">
                        <p>{vis.Visita.Posto.name}</p>
                        <p>
                          {vis.status === '' ? 'A iniciar' : vis.status} -{' '}
                          {vis.type === 'rota' ? 'Visita' : 'Intecorrência'}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </ContentInfo>
            </ContentRota>
          )}
          <MapContainer
            center={[initCoordenate.latitude, initCoordenate.longitude]}
            zoom={12}
            whenCreated={setMap}
            scrollWheelZoom
            style={{ width: '100%', height: '100%' }}
          >
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`}
            />
            {rotas.map((rot, indexRot) => {
              return rot.visitas.map((mark, index) => {
                return MarkerRender(mark, index + 1, indexRot, rot);
              });
            })}

            {rotaVisible === null ? (
              rotas
                .filter((i) => i.coords.coords.length > 1)
                .map((item, index) => (
                  <Polyline
                    key={`${item.rotaId}-${index.toString()}`}
                    positions={item.coords.coords.map((c) => [
                      c.latitude,
                      c.longitude,
                    ])}
                    color={colors.primary}
                    weight={3}
                  />
                ))
            ) : (
              <Polyline
                key={`${rotaVisible.rotaId}`}
                positions={rotaVisible.coords.coords.map((c) => [
                  c.latitude,
                  c.longitude,
                ])}
                color={colors.info}
                weight={4}
              />
            )}
          </MapContainer>
        </div>
        {(loadingRota || loadingUser) && <Loading />}
      </Container>
    </>
  );
};
