import styled from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const ContainerCadastro = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 36px 74px 30px 74px;
  margin-top: 16px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }

  border-radius: 8px;

  .containerInputs {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }

  .inputLabelContainer {
    width: 100%;
    margin: auto;
  }

  .containerButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 800px) {
    .containerInputs {
      display: flex;
      flex-direction: column;
      row-gap: 1px;
    }
  }
`;
