import React, { useState, useCallback, useEffect, useMemo } from 'react';

import jsPDF from 'jspdf';
import moment from 'moment';
import { FiSearch } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import { AgendamentoRonda, AgendaRonda } from '../../models/AgendaRonda';
import { Filiais } from '../../models/Filiais';
import { HistoricRondaV2 } from '../../models/HistoricAgendaAgendamento';

import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import { Loading } from '../../components/Loading';
import ModalConexao from '../../components/ModalConexao';
import ListRegistButton from '../../components/ListRegistButton';
import { ModalRondaDetails } from '../../components/ModalRondaDetails';

import {
  ItemTableRonda,
  ItemUserRonda,
  VitAg,
} from './HistoricRondaListVistoria';
import imgAvatar from '../../assets/VectorUser.svg';
import {
  ButtonPDF,
  Card,
  Container,
  ContainerData,
  ContentSearch,
  ListCards,
} from './styles';

interface Hour {
  hourIni: string;
  hourFim: string;
}

interface AgendRondaAgenmt extends AgendaRonda {
  agendaments: AgendamentoRonda[];
}

interface AgeHist {
  created: string;
  idAgendamento: number;
  historic: HistoricRondaV2[];
}

interface SelectOption {
  value: string;
  name: string;
}

const headersTable = [
  { name: 'Empresa', field: 'empresa', sortable: true },
  { name: 'Ronda', field: 'rondaName', sortable: true },
  { name: 'Categoria', field: 'categoryName', sortable: true },
  { name: 'Setor', field: 'setorName', sortable: true },
  { name: 'Andar', field: 'andar', sortable: true },
  { name: 'Usuário', field: 'username', sortable: true },
  { name: 'Tipo da Ronda', field: 'tipoRonda', sortable: true },
  { name: 'Dia Marcado', field: 'diaMarcado', sortable: true },
  {
    name: 'Horário Inicial (Marcado)',
    field: 'hourIniMarcado',
    sortable: true,
  },
  {
    name: 'Horário Final (Marcado)',
    field: 'hourFimMarcado',
    sortable: true,
  },
  { name: 'Tempo da Ronda (Marcado)', field: 'timeMarcado', sortable: true },
  {
    name: 'Horário Inicial (Realizado)',
    field: 'hourIniRealiz',
    sortable: true,
  },
  {
    name: 'Horário Final (Realizado)',
    field: 'hourFimRealiz',
    sortable: true,
  },
  {
    name: 'Imagem',
    field: 'image',
    sortable: false,
  },
  { name: 'Tempo da Ronda (Realizado)', field: 'timeRealiz', sortable: true },
  { name: 'Tempo Resultante', field: 'timeResultante', sortable: true },
  { name: 'Observação', field: 'observacao', sortable: true },
  { name: 'Ocorrências', field: 'ocorrencias', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
];

const selectFilter: SelectOption[] = [
  { value: 'filial', name: 'Filial' },
  { value: 'usuario', name: 'Usuário' },
  { value: 'ronda', name: 'Ronda' },
  { value: 'observacao', name: 'Observação' },
  { value: 'ocorrencia', name: 'Ocorrência' },
];

const HistoricPatrol: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [search, setSearch] = useState('');
  const [searchIni, setSearchIni] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [searchEnd, setSearchEnd] = useState(moment().format('YYYY-MM-DD'));
  const [rangeIni, setRangeIni] = useState(moment().subtract(8, 'days').format('YYYY-MM-DD'));
  const [rangeEnd, setRangeEnd] = useState(moment().format('YYYY-MM-DD'));
  const [selectSearch, setSelectSearch] = useState(selectFilter[0].value);
  const [optionCol, setOptionCol] = useState(true);
  const [isRondaDetails, setIsRondaDetails] = useState(false);
  const [rondasModal, setRondasModal] = useState<ItemUserRonda | null>(null);

  const [historic, setHistoric] = useState<HistoricRondaV2[]>([]);
  const [agend, setAgend] = useState<AgendRondaAgenmt[]>([]);
  const [filiais, setFiliais] = useState<Filiais[]>([]);

  const [loading, setLoading] = useState(false);
  const [loadingHist, setLoadingHist] = useState(false);

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const getHistoric = useCallback(
    async (dateIni: string, dateEnd: string) => {
      try {
        setLoadingHist(true);
        const idEP = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;
        const historicPatrol = await api.get(
          `/historicrondas?companyId=${idEP}&dataIni=${dateIni}&dataFim=${dateEnd}`,
        );
        setRangeIni(dateIni);
        setRangeEnd(dateEnd);

        const historicAll: HistoricRondaV2[] = historicPatrol.data;
        setHistoric(historicAll);
        setLoadingHist(false);
      } catch (err) {
        // console.log(err.message);
        setLoadingHist(false);
      }
    },
    [empresaPrincipal],
  );

  const getItens = useCallback(async () => {
    try {
      setLoading(true);

      const idEP = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;

      const responseAgenda = await api.get(`/agendarondas?companyId=${idEP}`);
      const agendAll: AgendaRonda[] = responseAgenda.data;
      const responseAgendament = await api.get(
        `/agendarondas/agendamentos?companyId=${idEP}&dataIni=${rangeIni}&dataFim=${rangeEnd}`,
      );
      const agenmen: AgendamentoRonda[] = responseAgendament.data;
      const filiaisGet = await api.get(
        `/empresas/${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
        }`,
      );
      setAgend(
        agendAll.map((ag) => {
          const agm = agenmen.filter((i) => i.agendaId === ag.id);
          return { ...ag, agendaments: agm };
        }),
      );
      setFiliais(filiaisGet.data);
      setLoading(false);
    } catch (err) {
      // console.log(err.message);
      setLoading(false);
    }
  }, [empresaPrincipal]);

  const getStatusHist = useCallback(
    (
      status: ('FEITO' | 'CANCELADO' | 'NÃO FEITO')[],
      dateAgend: string,
    ):
      | 'Concluído'
      | 'Concluído com Pendências'
      | 'Não Realizado'
      | 'Cancelado'
      | 'A iniciar' => {
      // 'FEITO' | 'CANCELADO' | 'NÃO FEITO'
      if (!status.includes('FEITO')) {
        if (!status.includes('NÃO FEITO')) {
          return 'Cancelado';
        }
        const now = moment().format('YYYY-MM-DD');
        if (dateAgend === now) {
          return 'A iniciar';
        }
        return 'Não Realizado';
      }
      if (status.includes('CANCELADO') || status.includes('NÃO FEITO')) {
        return 'Concluído com Pendências';
      }
      return 'Concluído';
    },
    [],
  );

  const getTime = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return ' - ';
  }, []);

  const timeResult = useCallback((timeHMS: string, timeHMS2: string) => {
    function segToHMS(segs: number): string {
      const hours = Math.trunc(segs / 3600);
      const minute = Math.trunc((segs - 3600 * hours) / 60);
      const seconds = segs - (3600 * hours + 60 * minute);
      // console.log(data, data2, hours, minute, seconds);
      return `${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    if (timeHMS !== ' - ' && timeHMS2 !== ' - ') {
      const [hour1, min1, seg1] = timeHMS.split(':');
      const [hour2, min2, seg2] = timeHMS2.split(':');
      const time1 = Number(seg1) + 60 * (Number(min1) + 60 * Number(hour1));
      const time2 = Number(seg2) + 60 * (Number(min2) + 60 * Number(hour2));
      const diff = time1 - time2;
      if (diff < 0) {
        const diff2 = time2 - time1;
        return `- ${segToHMS(diff2)}`;
      }
      return `+ ${segToHMS(diff)}`;
    }
    return ' - ';
  }, []);

  const listDados = useMemo(() => {
    const lists: ItemTableRonda[] = [];
    agend.forEach((ag) => {
      const histAg = historic.filter((i) => i.Agendamento.agendaId === ag.id);
      const aux: AgeHist[] = [];
      histAg.forEach((his) => {
        const findIndex = aux.findIndex(
          (i) =>
            (i.created === his.timeIni || i.created === his.createdAt) &&
            i.idAgendamento === his.agendamentoId,
        );
        if (findIndex === -1) {
          const obj: AgeHist = {
            created: his.timeIni ? his.timeIni : his.createdAt,
            historic: [his],
            idAgendamento: his.agendamentoId,
          };
          aux.push(obj);
        } else {
          aux[findIndex].historic.push(his);
        }
      });
      const vist = ag.Ronda.Vistorias;
      const horarios: Hour[] = [
        { hourIni: ag.horaIni, hourFim: ag.horaFim },
        ...ag.Revisoes.map((i) => ({
          hourIni: i.horaIni,
          hourFim: i.horaFim,
        })),
      ];
      const auxSort = aux.sort((a, b) => {
        if (a.created > b.created) return 1;
        if (a.created < b.created) return -1;
        return 0;
      });
      ag.agendaments
        .filter((i) => i.dataAgend <= rangeEnd && i.dataAgend >= rangeIni)
        .forEach((agm) => {
          const hist = auxSort.filter((i) => i.idAgendamento === agm.id);
          let histIndex = -1;
          const emp = filiais.find((fil) => fil.ID === ag.companyId);
          horarios.forEach((hour, indexH) => {
            const timeString = getTime(
              `2022-01-01 ${hour.hourIni}`,
              `2022-01-01 ${hour.hourFim}`,
            );
            const hisFind = hist.find((i, index) => index > histIndex);
            if (hisFind) {
              histIndex = +1;
            }
            const histsFind = hisFind ? hisFind.historic : [];
            const vists: VitAg[] = [];
            vist.forEach((vt) => {
              const find = histsFind.find((i) => i.vistoriaId === vt.id);
              if (find) {
                const objV: VitAg = {
                  idVistoria: vt.id,
                  image: find.image
                    ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${find.image}`
                    : '',
                  nameVistoria: vt.name,
                  observacao: find.observacao,
                  ocorrencia: find.ocorrencia,
                  status: find.image ? 'FEITO' : 'CANCELADO',
                  timeEnd: find.timeFim,
                  timeIni: find.timeIni,
                  sequencial: find.sequencial,
                };
                vists.push(objV);
              } else {
                const objV: VitAg = {
                  idVistoria: vt.id,
                  image: '',
                  nameVistoria: vt.name,
                  observacao: '',
                  ocorrencia: '',
                  status: 'NÃO FEITO',
                  timeEnd: '',
                  timeIni: '',
                  sequencial: indexH,
                };
                vists.push(objV);
              }
            });

            if (vists.length > 0) {
              const timeResul = getTime(
                vists[vists.length - 1].timeEnd,
                vists[0].timeIni,
              );
              const obj: ItemTableRonda = {
                idRonda: ag.Ronda.id,
                User: {
                  cpf: ag.Usuario.cpf,
                  email: ag.Usuario.email,
                  id: ag.Usuario.id,
                  imageUrl: ag.Usuario.avatar
                    ? `https://app-qrseguranca-avatar.s3.amazonaws.com/${ag.Usuario.avatar}`
                    : '',
                  name: ag.Usuario.nome,
                },
                andar: ag.Ronda.Andar,
                categoryName: ag.Ronda.Categoria.name,
                diaMarcado: agm.dataAgend,
                empresa: emp ? emp.NOME : ' - ',
                hourFimMarcado: hour.hourFim,
                hourFimRealiz: vists[vists.length - 1].timeEnd,
                hourIniMarcado: hour.hourIni,
                hourIniRealiz: vists[0].timeIni,
                observacao: vists[0].observacao,
                rondaName: ag.Ronda.name,
                setorName: ag.Ronda.Setor.name,
                status: getStatusHist(
                  vists.map((i) => i.status),
                  agm.dataAgend,
                ),
                timeMarcado: timeString,
                timeRealiz: timeResul,
                timeResultante: timeResult(timeString, timeResul),
                tipoRonda: indexH === 0 ? 'Principal' : 'Revisão',
                username: ag.Usuario.nome,
                vistorias: vists,
                ocorrencias: vists
                  .filter((i) => i.ocorrencia)
                  .map((i) => i.ocorrencia)
                  .join(' | '),
                image: vists
                  .filter((i) => i.image)
                  .map((i) => i.image)
                  .join(' | '),
              };
              lists.push(obj);
            }
          });

          hist
            .filter((i, index) => index > histIndex)
            .forEach((ht) => {
              const timeMarcad = getTime(
                `2022-01-01 ${ag.horaFim}`,
                `2022-01-01 ${ag.horaIni}`,
              );
              const timeResul = getTime(
                ht.historic[ht.historic.length - 1].timeFim,
                ht.historic[0].timeIni,
              );
              const obj: ItemTableRonda = {
                idRonda: ag.Ronda.id,
                User: {
                  cpf: ag.Usuario.cpf,
                  email: ag.Usuario.email,
                  id: ag.Usuario.id,
                  imageUrl: ag.Usuario.avatar
                    ? `https://app-qrseguranca-avatar.s3.amazonaws.com/${ag.Usuario.avatar}`
                    : '',
                  name: ag.Usuario.nome,
                },
                andar: ag.Ronda.Andar,
                categoryName: ag.Ronda.Categoria.name,
                diaMarcado: agm.dataAgend,
                empresa: emp ? emp.NOME : ' - ',
                hourFimMarcado: ag.horaIni,
                hourFimRealiz: ht.historic[ht.historic.length - 1].timeFim,
                hourIniMarcado: ag.horaFim,
                hourIniRealiz: ht.historic[0].timeIni,
                observacao: ht.historic[0].observacao,
                rondaName: ag.Ronda.name,
                setorName: ag.Ronda.Setor.name,
                status: getStatusHist(
                  ht.historic.map((i) => (i.image ? 'FEITO' : 'CANCELADO')),
                  agm.dataAgend,
                ),
                timeMarcado: timeMarcad,
                timeRealiz: timeResul,
                timeResultante: timeResult(timeMarcad, timeResul),
                tipoRonda: 'Extra',
                username: ag.Usuario.nome,
                vistorias: ht.historic.map((i) => ({
                  ocorrencia: i.ocorrencia,
                  idVistoria: i.vistoriaId,
                  image: i.image
                    ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${i.image}`
                    : '',
                  nameVistoria:
                    vist.find((v) => v.id === i.vistoriaId)?.name || '',
                  observacao: i.observacao,
                  sequencial: i.sequencial,
                  status: i.image ? 'FEITO' : 'CANCELADO',
                  timeIni: i.timeIni,
                  timeEnd: i.timeFim,
                })),
                ocorrencias: ht.historic.map((i) => i.ocorrencia).join(' | '),
                image: ht.historic.map((i) => i.image).join(' | '),
              };
              lists.push(obj);
            });
        });
    });
    return lists
      .map((i) => ({
        ...i,
        diaMarcado: moment(i.diaMarcado).format('DD/MM/YYYY'),
        hourIniRealiz: i.hourIniRealiz ? i.hourIniRealiz.slice(11, 19) : '',
        hourFimRealiz: i.hourFimRealiz ? i.hourFimRealiz.slice(11, 19) : '',
      }))
      .sort((a, b) => {
        if (a.diaMarcado > b.diaMarcado) return 1;
        return a.diaMarcado < b.diaMarcado ? -1 : 0;
      });
  }, [
    agend,
    filiais,
    getStatusHist,
    getTime,
    historic,
    rangeEnd,
    rangeIni,
    timeResult,
  ]);

  const listTableFilter = useMemo(() => {
    if (selectSearch === 'filial') {
      return listDados.filter((i) =>
        i.empresa.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    if (selectSearch === 'usuario') {
      return listDados.filter((i) =>
        i.username.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    if (selectSearch === 'ronda') {
      return listDados.filter((i) =>
        i.rondaName.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    if (selectSearch === 'observacao') {
      return listDados.filter((i) =>
        i.observacao.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    if (selectSearch === 'ocorrencia') {
      return listDados.filter((i) =>
        i.ocorrencias.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    return listDados;
  }, [listDados, search, selectSearch]);

  useEffect(() => {
    const now = moment().format('YYYY-MM-DD');
    const nowMenos7 = moment().subtract(1, 'days').format('YYYY-MM-DD');
    setRangeEnd(now);
    setRangeIni(nowMenos7);
    getItens();
    getHistoric(nowMenos7, now);
  }, [getHistoric, getItens]);

  const handleSearch = useCallback(() => {
    setRangeEnd(searchEnd);
    setRangeIni(searchIni);
    getHistoric(searchIni, searchEnd);
    getItens();
  }, [getHistoric, searchEnd, searchIni]);

  const handlePDF = useCallback(() => {
    const doc = new jsPDF();  // eslint-disable-line

    doc.setFontSize(20);
    doc.setTextColor(2, 2, 2);
    doc.setFontType('bold');
    const titulo = 'Relatório de Rondas';

    doc.text(titulo, 103, 15, 'center');

    let spacement = 40;
    let page = 1;
    let tamanhoTotal = 0;
    const posInit = 20;
    const posVal = 100;
    doc.setFontSize(14);
    doc.setFontType('normal');

    listTableFilter.forEach((item) => {
      if (spacement + tamanhoTotal + 35 + 5 * item.vistorias.length > 300) {
        doc.insertPage(page + 1);
        page += 1;
        spacement = 20;
        tamanhoTotal = 0;
      }

      doc.text(item.rondaName || '', posInit, spacement + tamanhoTotal);

      doc.text(item.username || '', posInit, spacement + 5 + tamanhoTotal);

      doc.text(
        `${item.categoryName} - ${item.setorName}` || '',
        posInit,
        spacement + 10 + tamanhoTotal,
      );

      doc.text(
        moment(item.hourIniRealiz).format('DD/MM/YYYY HH:MM:SS'),
        posInit,
        spacement + 15 + tamanhoTotal,
      );

      doc.text(
        `Tempo Estimado: ${item.timeMarcado}`,
        posInit,
        spacement + tamanhoTotal + 20,
      );
      doc.text(
        `Tempo de Execução: ${item.timeRealiz}`,
        posVal,
        spacement + tamanhoTotal + 20,
      );

      doc.text(
        `Observação: ${item.observacao}`,
        posInit,
        spacement + 25 + tamanhoTotal,
      );

      doc.setFontType('bold');
      doc.text('Vistoria', posInit, spacement + 30 + tamanhoTotal);
      doc.text('Ocorrência', posVal, spacement + 30 + tamanhoTotal);

      doc.setFontType('normal');
      item.vistorias.forEach((vist, index) => {
        doc.text(
          vist.nameVistoria,
          posInit,
          spacement + 35 + 5 * index + tamanhoTotal,
        );

        doc.text(
          vist.ocorrencia,
          posVal,
          spacement + 35 + 5 * index + tamanhoTotal,
        );
      });

      tamanhoTotal += 35 + 5 * item.vistorias.length + 10;
    });

    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }, [listTableFilter]);

  const listUser: ItemUserRonda[] = useMemo(() => {
    const list: ItemUserRonda[] = [];
    listTableFilter.forEach((item) => {
      const findIndex = list.findIndex((i) => i.idUser === item.User.id);
      if (findIndex === -1) {
        const obj: ItemUserRonda = {
          cpf: item.User.cpf,
          dadosRonda: [item],
          email: item.User.email,
          idUser: item.User.id,
          imageUrl: item.User.imageUrl,
          nameUser: item.username,
        };
        list.push(obj);
      } else {
        list[findIndex].dadosRonda.push(item);
      }
    });
    return list;
  }, [listTableFilter]);

  const datesMemo = useMemo(() => {
    if (rangeIni === rangeEnd) {
      return `De ${moment(rangeIni).format('DD/MM/YYYY')}`;
    }
    return `De ${moment(rangeIni).format('DD/MM/YYYY')} até ${moment(
      rangeEnd,
    ).format('DD/MM/YYYY')}`;
  }, [rangeEnd, rangeIni]);

  return (
    <>
      <ModalConexao />
      {rondasModal && (
        <ModalRondaDetails
          isOpen={isRondaDetails}
          onClose={() => setIsRondaDetails(false)}
          rondas={rondasModal}
          datasFiltro={datesMemo}
        />
      )}
      <Container>
        <TitlePage>Historico de rondas</TitlePage>
        <ListRegistButton
          object={{ list: 'Empresa', regist: 'Colaboradores' }}
          setStateCadastrar={setOptionCol}
          stateCadastrar={optionCol}
        />
        <ContentSearch>
          <div className="input">
            <FiSearch size={22} />
            <input
              value={search}
              placeholder="Buscar"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </div>
          <div className="select">
            <h4>Filtro:</h4>
            <select
              value={selectSearch}
              onChange={(event) => {
                setSelectSearch(event.target.value);
              }}
            >
              {selectFilter.map((i, index) => (
                <option key={`${index.toString()}-${i.value}`} value={i.value}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
        </ContentSearch>
        <ContainerData>
          <div>
            <input
              type="date"
              value={searchIni}
              onChange={(event) => {
                setSearchIni(event.target.value);
              }}
            />
          </div>
          <div>
            <input
              type="date"
              value={searchEnd}
              onChange={(event) => {
                setSearchEnd(event.target.value);
              }}
            />
          </div>
          <button type="button" onClick={handleSearch}>
            <FiSearch size={26} />
          </button>
        </ContainerData>
        {!optionCol ? (
          <>
            <Table
              tableId="tableListagemRondas"
              body={listTableFilter.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headersTable}
            />
            <PaginationComponent
              arrayStateFiltered={listTableFilter}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <div className="widthTotal">
              <ButtonPDF onClick={handlePDF}>Baixar PDF</ButtonPDF>
              <NewImportExel
                fileName="HistoricoRondas"
                tableId="tableListagemRondas"
              />
            </div>
          </>
        ) : (
          <ListCards>
            {listUser.length}
            {listUser.map((user, index) => (
              <Card
                key={`${user.idUser}|${index.toString()}`}
                onClick={() => {
                  setIsRondaDetails(true);
                  setRondasModal(user);
                }}
              >
                <div className="avatar">
                  <img
                    src={!user.imageUrl ? imgAvatar : user.imageUrl}
                    alt={user.nameUser}
                  />
                </div>
                <div className="info">
                  <div style={{ flex: 2 }}>
                    <p>Nome</p>
                    <span>{user.nameUser}</span>
                  </div>
                  <div style={{ flex: 1.1 }}>
                    <p>CPF</p>
                    <span>{user.cpf}</span>
                  </div>
                  <div style={{ flex: 1.4 }}>
                    <p>E-mail</p>
                    <span>{user.email}</span>
                  </div>
                </div>
              </Card>
            ))}
          </ListCards>
        )}
        {(loading || loadingHist) && <Loading />}
      </Container>
    </>
  );
};

export default HistoricPatrol;
