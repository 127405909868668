import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  position: relative;
`;

export const TituloPrincipal = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

export const ButtonSalvar = styled.button<{ gerar?: boolean, trash?: boolean, salvar?: boolean }>`
  background-color: ${(props) => (props.gerar || props.salvar ? '#007bff;' : '#e4005d;')} 
  color: white;
  border: none;
  padding: 10px; /* Padding menor para acomodar o ícone */
  margin-bottom: 20px;
  border-radius: 50%; /* Torna o botão circular */
  cursor: pointer;
  font-size: 20px;
  width: 40px; /* Largura igual à altura para circular */
  height: 40px; /* Altura fixa */
  ${(props) => (props.gerar || props.trash ? '' : 'position: absolute;')}
  ${(props) => (props.gerar || props.trash ? '' : 'margin: 10px 10px;')}
   /* Posiciona de forma absoluta */
  top: 20px; /* Distância do topo */
  right: 20px; /* Distância da direita */

  display: flex; /* Alinha o ícone centralizado */
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.gerar || props.salvar ? '#0056b3' : '#eaa7a7')}; /* Azul escuro */
    
  }

  &:disabled {
    background-color: #d3d3d3; /* Cinza claro */
    cursor: not-allowed;
  }
`;

export const ButtonGerarRotaGrupo = styled.button`
background-color: #007bff; /* Azul */
color: white;
border: none;
padding: 10px 20px;
margin-bottom: 20px;
border-radius: 5px;
cursor: pointer;
font-size: 16px;

top: 20px; /* Distância do topo */
right: 20px; /* Distância da direita */

&:hover {
  background-color: #0056b3; /* Azul escuro */
}

&:disabled {
  background-color: #d3d3d3; /* Cinza claro */
  cursor: not-allowed;
}
`;

export const ItemLocalizacao = styled.div`
  display: flex;
  justify-content: space-between; /* Espaça texto e ícones */
  align-items: center; /* Centraliza verticalmente */
  padding: 10px;
  width: 100%; /* Garante que o contêiner use toda a largura */
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Linha separadora */
  &:hover {
    background-color: #f9f9f9; /* Destaque ao passar o mouse */
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex: 2;
  gap: 10px; /* Espaçamento entre ícones */
  margin-left: auto; /* Empurra o grupo de ícones para a direita */
  align-items: center;
`;

export const ItemNome = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${(props) => props.theme.colors.primary || '#007BFF'};
  font-size: 18px;

  &:hover {
    color: ${(props) => props.theme.colors.gray1 || '#0056b3'};
  }
`;

export const BotaoMover = styled.button`
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #003f7f;
  }
`;

export const ListaLocalizacoes = styled.div`
  margin-top: 16px;
`;

export const TituloGrupo = styled.h2`
  font-size: 20px;
  color: #444;
  margin-bottom: 16px;
`;

export const GrupoContainer = styled.div`
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;