import styled from 'styled-components';

interface PropsButtonForm {
  background: string;
}

export const HeaderForm = styled.h3`
  text-align: center;

  color: ${(props) => props.theme.colors.black2};
  font-size: 16px;
  font-weight: 700;
`;
export const LabelInputForm = styled.h3`
  color: ${(props) => props.theme.colors.black2};
  margin-bottom: 10px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;
export const DivLineBottom = styled.div`
  width: 100%;
  height: 1px;
  margin: 25px auto 25px auto;

  background-color: ${(props) => props.theme.colors.gray1};
`;

export const ContainerCadastro = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 36px 74px 30px 74px;
  margin-top: 16px;
  border-radius: 8px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 41px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;
  padding-left: 16px;
`;

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 49.5%;
  height: 50px;
  background-color: ${(props) => props.background};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.gray12
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 4px;
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const SelectForm = styled.select`
  color: ${(props) => props.theme.colors.black2};
  width: 100%;
  height: 48px;

  border: 1px solid ${(props) => props.theme.colors.white4};
  border-radius: 4px;

  font-size: 14px;
  font-weight: 600;
`;

export const ContainerChecklist = styled.div`
  width: 100%;
  max-height: 200px;
  background-color: ${(props) => props.theme.colors.gray9};
  margin-bottom: 30px;
  border-radius: 8px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const BoxItem = styled.div`
  width: auto;
  min-height: 50px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  display: flex;
  padding: 8px;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  column-gap: 10px;
`;

export const Names = styled.p`
  color: ${(props) => props.theme.colors.black2};
  margin: 0px;
`;

export const CheckBox = styled.input`
  width: 25px;
  height: 25px;
  margin: 0px;
`;
