import styled, { keyframes } from 'styled-components';

interface Props {
  selected: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px 60px;
  max-height: calc(100vh - 200px);
  animation: ${appearFromRight} 1s;
  overflow: auto;

  @media screen and (max-width: 1152px) {
    padding: 22px;
    max-height: calc(100vh - 225px);
  }

  form {
    p {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${(props) => props.theme.colors.black};
      margin-bottom: 8px;
    }

    .p-multi-select {
      margin-top: 18px;
    }

    button[type='submit'] {
      margin-top: 40px;
    }
  }
`;

export const ContainerTable = styled.div`
  margin-top: 24px;
  max-height: calc(100vh - 340px);
  overflow: auto;

  table {
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    border-collapse: collapse;

    thead {
      background: ${(props) => props.theme.colors.white};

      th {
        padding: 20px 30px;
        text-align: left;
        //white-space: nowrap;
        position: relative !important;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: ${(props) => props.theme.colors.black};
      }

      th:nth-last-child(-n + 1) {
        text-align: center;
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: ${(props) => props.theme.colors.black};
          padding: 20px 30px;
          // white-space: nowrap;
        }

        &:nth-child(odd) {
          background-color: ${(props) => props.theme.colors.gray6};
        }
      }
    }
  }
`;

export const ConainerCadastroOption = styled.div`
  margin-bottom: 21px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
`;

export const RadioBox = styled.button`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  border: none;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props.theme.colors.black};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    margin-right: 20px;
  }

  transition: 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 45px;

  section {
    animation: ${appearFromRight} 1s;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    transition: transform 0.2s;

    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 16px;
      color: ${(props) => props.theme.colors.black};
    }

    aside {
      width: 100%;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 0.6px solid ${(props) => props.theme.colors.gray9};

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 74px;

      @media screen and (max-width: 1152px) {
        gap: 12px;
      }

      article {
        p {
          margin: 0;
        }
      }
    }
  }
`;

export const CalendarButton = styled.button`
  height: 43px;
  width: 100%;
  max-width: 300px;
  background: ${(props) => props.theme.colors.primary};
  justify-content: center;
  align-items: center;
  border-radius: 21.5px;
  display: flex;
  border: none;

  span {
    color: ${(props) => props.theme.colors.white};
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }
  svg {
    color: ${(props) => props.theme.colors.white};
    margin-right: 20px;
  }
`;

export const DotCalendar = styled.button<Props>`
  background: ${(props) =>
    props.selected ? props.theme.colors.primary : props.theme.colors.gray1};
  color: ${(props) =>
    props.selected ? props.theme.colors.white : props.theme.colors.black};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const SelectStyle = styled.select`
  flex: 1;
  background: ${(props) => props.theme.colors.white};
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;

  option {
    color: ${(props) => props.theme.colors.black};
    font-family: Montserrat;
    font-size: 14px;
    transition: 0.5s;
  }

  option:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ButtonPlus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  margin-left: 10px;
  height: 36px;
  width: 36px;
  border: none;
  svg {
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ValSelectedButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  overflow: auto;
  column-gap: 10px;

  button {
    height: 36px;
    min-width: 36px;
    border-radius: 18px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.primary};
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    padding: 4px;

    p {
      color: ${(props) => props.theme.colors.black};
      font-family: Montserrat;
      font-size: 14px;
      margin: 0px;
    }
  }
  button:hover {
    background: ${(props) => props.theme.colors.error};
    border: none;
    p {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ButtonAdd = styled.button`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};
  border: none;
  border-radius: 8px;
  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 22px;
  }
`;

export const ButtonRem = styled.button`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.error};
  border: none;
  border-radius: 8px;
  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 22px;
  }
`;

export const CheckboxLabel = styled.div`
  div {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    /* margin-left: 20px; */

    input {
      width: 25px;
      height: 25px;
    }
    span {
      color: ${(props) => props.theme.colors.black};
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const SpanError = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  background-color: ${(props) => props.theme.colors.error};
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  margin-bottom: 8px;

  span {
    color: ${(props) => props.theme.colors.white};
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
`;

export const CalendarDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;

  div.sem {
    display: flex;
    margin-left: 25px;
    column-gap: 1rem;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    div.sem {
      margin: 0px;
    }
  }
  @media screen and (max-width: 500px) {
    div.sem {
      margin: 0px;
      column-gap: 6px;
      max-width: 100%;
    }
  }
`;

export const CalendarMensal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    div.divButton {
      display: flex;
      width: 100%;
      max-width: 600px;
      align-items: center;

      div.input {
        flex: 1;
        color: ${(props) => props.theme.colors.black2};

        input {
          display: flex;
          height: 40px;
          background: ${(props) => props.theme.colors.white};
          border: 1px solid ${(props) => props.theme.colors.gray12};
          border-radius: 4px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          flex: 1;
          width: 100%;
          padding-left: 8px;
          padding-right: 8px;
          align-items: center;
          color: ${(props) => props.theme.colors.black2};
        }
      }
    }
  }
`;

export const DivRevision = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 2rem;
  row-gap: 1rem;

  div.add {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
