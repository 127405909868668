import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Montserrat';

  padding: 30px 74px 30px 74px;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }

  div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const HeaderForm = styled.h3`
  text-align: center;

  color: ${(props) => props.theme.colors.black2};
  font-size: 16px;
  font-weight: 700;
`;

export const DivLineBottom = styled.div`
  width: 100%;
  height: 1px;
  margin: 25px auto 25px auto;

  background-color: ${(props) => props.theme.colors.gray1};
`;

export const ButtonClose = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -74px;
  margin-top: -30px;
  /* margin-right: -74px; */
  /* position: absolute; */
  background-color: ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 4px;
  transition: 0.2s;

  @media screen and (max-width: 800px) {
    margin-right: -22px;
    margin-top: -22px;
  }

  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.error)};
  }
`;

interface PropsButtonForm {
  prin: boolean;
}

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 48%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.prin ? props.theme.colors.primary : props.theme.colors.white};
  color: ${(props) =>
    props.prin ? props.theme.colors.white : props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background-color: ${(props) =>
      shade(
        0.2,
        props.prin ? props.theme.colors.primary : props.theme.colors.white,
      )};
  }
`;

export const ContainerCadastro = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  margin-top: 16px;
  /* padding-top: 74px; */

  #filialUser {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }

  .containerButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .selectStyle {
    color: ${(props) => props.theme.colors.black2};
    height: 48px;
    width: 100%;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const BoxRowInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  column-gap: 1rem;

  .containerInputAndLabel {
    width: 100%;
    input {
      margin-top: 0 !important;
    }
  }

  .daysWeek {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const BoxMiddle = styled.div`
  width: 14px;
`;

export const LabelInputForm = styled.h3`
  color: ${(props) => props.theme.colors.black2};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;
  padding-left: 16px;
`;
