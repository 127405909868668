import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';

import NewImportExel from '../../components/NewImportExel';
import { NewSearch } from '../../components/NewSearch';
import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import { returnNameClient } from '../../components/UltilitiesFunctions/returnNameClient';
import { returnNameFilial } from '../../components/UltilitiesFunctions/returnNameFilial';
import { searchSupervisor } from '../../components/UltilitiesFunctions/searchSupervisor';
import { returnNameMotivos } from '../../components/UltilitiesFunctions/returnNameMotivos';
import { criarPDF } from './criarPDF';
import { ApontamentosClientes } from '../../models/ApontamentosClientes';
import PaginationComponent from '../../components/Pagination';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import { Filiais } from '../../models/Filiais';
import { MotivoPTh } from '../../models/Motivos';

import { ReactComponent as SearchIcon } from '../../assets/search_black_24dp.svg';
import {
  Container,
  ButtonVizualizarPDF,
  DivButtons,
  ContainerInputDate,
  InputTextFilter,
  DivFilter,
} from './styles';

const headers = [
  { name: 'Data', field: 'dataManut', sortable: true },
  { name: 'Filial', field: 'companyName', sortable: true },
  { name: 'Cliente', field: 'clienteName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Colaborador', field: 'colaboradorName', sortable: true },
  { name: 'Motivo', field: 'motivoName', sortable: true },
  { name: 'Supervisor da vistoria', field: 'supervisorName', sortable: true },
  { name: 'Observacao', field: 'observacao', sortable: true },
];
const headersFilter = [
  { name: 'Filial', field: 'companyName', sortable: true },
  { name: 'Cliente', field: 'clienteName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Colaborador', field: 'colaboradorName', sortable: true },
  // { name: 'Tipo de acesso', field: 'tipoAceso', sortable: true },
  { name: 'Supervisor', field: 'supervisorName', sortable: true },
  // { name: 'Observacao', field: 'observacao', sortable: true },
];

// Nome da filial

const Apontamentos: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const [dateInit, setDateInit] = useState<string>(
    moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  );

  // const [apontamentos, setApontamentos] = useState<ApontamentosClientes[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const [appointments, setAppointments] = useState<any[]>([]);
  const [clientes, setClients] = useState<Clientes[]>([]);
  const [users, setUsers] = useState<UsuarioBD[]>([]);
  const [filiais, setFiliais] = useState<Filiais[]>([]);
  const [motivos, setMotivos] = useState<MotivoPTh[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [apontamentosFiltered, setApontamentosFiltered] = useState<
    ApontamentosClientes[]
  >([]);

  const apontamentos: ApontamentosClientes[] = useMemo(() => {
    const newApontamentos = appointments.map((e: any, index: number) => {
      e.dataManut = moment(e.dataManut).format('DD/MM/YYYY');
      e.clienteName = returnNameClient(e.clienteId, clientes);
      e.colaboradorName = e.Colaborador.name;
      e.supervisorName = searchSupervisor(e.supervisorId, users);
      e.companyName = returnNameFilial(e.companyId, filiais);
      e.motivoName = returnNameMotivos(e.motivoId, motivos);
      e.postoName = e.Posto.name;

      return e;
    });

    return newApontamentos;
  }, [appointments, clientes, filiais, motivos, users]);

  const getItemsData = useCallback(async () => {
    setLoadingData(true);
    const ApontamentosGet = await api.get(
      `/apontamento/?companyId=${empresaPrincipal[0].ID}&dataIni=${dateInit}&dataFim=${dateEnd}`,
    );
    console.log('APO', ApontamentosGet.data);
    setLoadingData(false);
    setAppointments(ApontamentosGet.data);
  }, [dateEnd, dateInit, empresaPrincipal]);

  const getItems = useCallback(async () => {
    setLoading(true);

    const clientesGet = await api.get(
      `/clientes/empresa?empresaId=${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );

    const filiaisGet = await api.get(
      `/empresas/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );

    const usersGet = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );

    const motivosGet = await api.get(`/motivo`);

    setLoading(false);
    setUsers(usersGet.data);
    setClients(clientesGet.data);
    setFiliais(filiaisGet.data);
    setMotivos(motivosGet.data);
  }, [empresaPrincipal]);

  const verifyDate = useCallback(() => {
    const datValidInit = moment(dateInit, 'YYYY-MM-DD', true).isValid();
    const datValidEnd = moment(dateEnd, 'YYYY-MM-DD', true).isValid();

    if (dateInit > dateEnd) {
      Swal.fire({
        icon: 'warning',
        title: 'A data inicial não pode ser maior que a final!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    } else if (datValidInit === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Data inicial inválida!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    } else if (datValidEnd === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Data final inválida!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    }
  }, [dateEnd, dateInit]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  useEffect(() => {
    getItemsData();
  }, [getItemsData]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Apontamentos</TitlePage>

        <DivFilter>
          <ContainerInputDate>
            <InputTextFilter>
              <SearchIcon />
              <input
                type="date"
                value={dateInit}
                onChange={(e) => {
                  setDateInit(e.currentTarget.value);
                }}
                onBlur={() => {
                  verifyDate();
                }}
              />
            </InputTextFilter>
            <InputTextFilter>
              <SearchIcon />
              <input
                type="date"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e.currentTarget.value);
                }}
                onBlur={() => {
                  verifyDate();
                }}
              />
            </InputTextFilter>
          </ContainerInputDate>
          <NewSearch
            arraySetState={setApontamentosFiltered}
            arrayState={apontamentos}
            selectFilters={headersFilter}
          />
        </DivFilter>

        <Table
          header={headers}
          body={apontamentosFiltered.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg,
          )}
          tableId="TableRiscos"
          clientes={clientes}
        />
        <PaginationComponent
          arrayStateFiltered={apontamentosFiltered}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />

        <DivButtons>
          {apontamentosFiltered.length > 0 && (
            <ButtonVizualizarPDF
              onClick={() => {
                criarPDF(apontamentosFiltered);
              }}
            >
              Vizualizar PDF
            </ButtonVizualizarPDF>
          )}
          <NewImportExel fileName="Riscos" tableId="TableApontamentos" />
        </DivButtons>
      </Container>
      {(loading || loadingData) && <Loading />}
    </>
  );
};

export default Apontamentos;
