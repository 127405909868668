import React from 'react';
import { Container, ContainerBarra } from './styles';

interface ProgressBarInterface {
  percentage?: number;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const ProgressBar = ({ percentage = 0 }: ProgressBarInterface) => {
  return (
    <Container>
      <ContainerBarra porcentagem={percentage} />
    </Container>
  );
};

export default ProgressBar;
