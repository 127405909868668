import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  cor: boolean;
}

interface IdClientesProps {
  divSelecionada: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromTop = keyframes`
  from {
    transform: translateY(0);
  }
  to{
    transform: translateY(-9px);
  }
`;

const appearFromBottom = keyframes`
  from {
    transform: translateY(0);
  }
  to{
    transform: translateY(9px);
  }
`;

const appearFromBottomDivClientesGeral = keyframes`
  from {
    transform: translateY(-40px);
  }
  to{
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin: 0px auto;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  animation: ${appearFromRight} 1s;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    min-width: 150px;
    padding: 0 10px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerCadastro = styled.div<ContainerProps>`
  margin-top: 0px;
  overflow-x: hidden;
  background: ${(props) => (props.cor ? '#3d3946' : 'transparent')};
  border-radius: ${(props) => (props.cor ? '10px' : '0')};

  .divTable {
    border-radius: 10px;
    animation: ${appearFromRight} 1s;
  }

  .divDropDown {
    width: 100%;
    div {
      width: 100%;
    }
    p {
      align-items: center;
      margin: 0 10px 4px 0;
      font-size: 18px;
      font-family: Roboto, sans-serif;
    }
    select {
      width: 100%;
      height: 60px;
      margin-bottom: 10px;
      background-color: #28262e;
      border-radius: 10px;
      padding: 0 10px 0 10px;
      border: none;
      border: 2px solid ${(props) => props.theme.colors.success};
    }
    & + .divDropDown {
      margin-top: 12px;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  margin: 10px auto;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    border-radius: 5px;
    border: none;
  }

  #btnSubmit {
    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }

  #btnSave {
    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }

  #btnCancel {
    background-color: #660000;
    margin-right: 15px;
    &:hover {
      background-color: ${shade(0.3, '#660000')};
    }
  }
`;

export const AnimationContainer = styled.div`
  max-width: 850px;
  flex-direction: column;
  margin: 0px auto;
  padding: 20px;
  animation: ${appearFromRight} 1s;

  h1 {
    margin-top: 14px;
    font-size: 25px;
    font-family: Roboto, sans-serif;
  }

  form {
    flex: 1;
    margin: 30px 0;
    margin-bottom: 5px;
    width: 100%;

    div.divInput {
      & + div.divInput {
        margin-top: 10px;
      }
    }

    main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      animation: ${appearFromRight} 1s;
    }

    .divClientesBtn {
      display: flex;
      width: 100%;
      margin-top: 40px;

      button {
        margin-left: auto;
        width: 30px;
        height: 30px;
        outline: none;
        border: none;
        background-color: #312e38;
        border: 2px solid ${(props) => shade(0.3, props.theme.colors.success)};
        margin-top: 1px;
        transition: 0.2s;

        svg {
          margin-top: 2px;
        }

        & + button {
          margin-left: 15px;
          border: 1px solid red;
        }

        &:hover {
          transform: scale(1.06);
        }
      }

      .divClientesGeral {
        outline: none;
        border: none;
        background-color: 'transparent';
      }
    }

    .divClientesVazio {
      display: flex;
      width: 100%;
      height: 60px;
      background-color: #323232;
      border: 2px solid #999999;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      h2 {
        margin-bottom: 0 !important;
      }
    }
  }
`;

export const ContainerDivCliente = styled.div<IdClientesProps>`
  animation: ${appearFromRight} 1s;
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: #312e38;
  border: 2px solid
    ${(props) =>
      props.divSelecionada === true ? props.theme.colors.success : '#999999'};
  border-radius: 10px;
  padding: 10px;
  transition: 0.2s;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  div {
    flex: 1;
    background: #3d3946;
    border-radius: 5px;
    padding: 5px 8px 0 8px;
    p {
      strong {
        font-weight: bold;
      }
    }
    & + div {
      margin-left: 10px;
    }
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  border-radius: 10px 10px 0 0;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 50vh;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.success};
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => shade(0.1, props.theme.colors.success)};
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.success};
    border-top: 0px;

    th {
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${(props) => props.theme.colors.white};

      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    background-color: ${(props) => props.theme.colors.white};
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.black3};

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        transition: transform 0.2s;

        svg {
          color: #fff;
        }

        & + button {
          margin-left: 20px;
        }

        &:hover {
          transform: scale(1.08);
        }
      }
    }
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.success};
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: ${(props) => props.theme.colors.white3};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.gray12};
      }
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: initial;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  .divSearch {
    & + .divSearch {
      margin-left: 15px;
    }
  }

  div {
    & + div {
      margin-left: 15px;
    }
  }

  .search4 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${(props) => props.theme.colors.success}; */
  border-radius: 0 0 10px 10px;
  width: 100%;
  .divPaginacao {
    display: flex;
    align-items: center;
    width: 0;
    margin-left: auto;
  }

  .btn {
    margin-left: auto;
    margin-right: 15px;
    height: 40px;
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.white};
    border-width: 0px;
    padding: 0 10px;
    font-weight: bold;

    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background-color: #28262e;
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ContainerSequentialRote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button.buttonUp {
    border: none;
    outline: none;
    background: transparent;
    height: 25px;
    transition: color 0.2s;

    &:hover {
      animation: ${appearFromTop} 0.7s infinite;
      color: ${(props) => props.theme.colors.success};
    }
  }

  button.buttonDown {
    border: none;
    outline: none;
    background: transparent;
    height: 25px;
    transition: color 0.2s;

    &:hover {
      animation: ${appearFromBottom} 0.7s infinite;
      color: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerObservation = styled.div`
  width: fit-content;
  height: auto;
  padding: 3px 8px 3px 8px;
  background: #28262e;
  border-radius: 5px;
  margin-bottom: 8px;

  h5 {
    margin: 0;
  }
`;

export const ContainerClientesGeral = styled.div`
  animation: ${appearFromBottomDivClientesGeral} 1s;
`;

export const ContainerCard = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  overflow: auto;
`;
