import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const DivFilter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 16px;
  margin-bottom: 25px;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerInputDate = styled.div`
  flex: 1;
  column-gap: 1rem;
  display: flex;
  width: 100%;
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: ${(props) => props.theme.colors.black2};
  text-align: center;
`;

export const ContentFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  .input {
    background-color: ${(props) => props.theme.colors.secondary};
    display: flex;
    flex: 1;
    height: 48px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.gray1};
    box-sizing: border-box;
    border-radius: 4px;
    svg {
      margin: 0px 18px;
      color: ${(props) => props.theme.colors.gray2};
      font-size: 19px;
    }
    input {
      flex: 1;
      height: 100%;
      padding: 0px;
      margin: 0px;
      border: none;
      color: ${(props) => props.theme.colors.black};
      font-size: 16px;
      font-family: 'Montserrat';
      font-weight: 600;
    }
  }

  select {
    height: 48px;
    width: 198px;
    background: ${(props) => props.theme.colors.white};
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0px 18px;
    padding: 0px 6px;
    color: ${(props) => props.theme.colors.black};
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
  }

  button:hover {
    background: ${(props) => props.theme.colors.gray8};
    border-color: ${(props) => props.theme.colors.gray8};
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

interface Props {
  choose: boolean;
}

export const ButtonOption = styled.button<Props>`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin: 6px;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.choose ? props.theme.colors.primary : props.theme.colors.white};
  border: 1px solid
    ${(props) =>
      props.choose ? props.theme.colors.primary : props.theme.colors.gray1};
  padding: 8px 0px 0px 0px;
  transition: 0.5s;
  svg {
    color: ${(props) =>
      props.choose ? props.theme.colors.white : props.theme.colors.gray1};
    font-size: 20px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid ${(props) => props.theme.colors.gray1};
  margin: 36px 0px;
`;

export const List = styled.div``;

export const ExportEcel = styled.div`
  button: {
    width: 244px;
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 4px;
  }
  button:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const GridUni = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;

  div.title {
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    height: 50px;
    border-radius: 0px 0px 4px 4px;
    color: ${(props) => props.theme.colors.gray6};
    font-size: 16px;
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);

    p {
      color: ${(props) => props.theme.colors.gray6};
      font-size: 16px;
      font-family: 'Montserrat';
      font-weight: 600;
      margin: 0px;
      text-align: center;
    }
    div {
      height: 20px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors.gray1};
      font-weight: 400;
    }
  }

  div.title.pen {
    border-top: 2px solid ${(props) => props.theme.colors.warning};
  }
  div.title.fin {
    border-top: 2px solid ${(props) => props.theme.colors.success};
  }
  div.title.and {
    border-top: 2px solid ${(props) => props.theme.colors.info};
  }
  div.title.ina {
    border-top: 2px solid ${(props) => props.theme.colors.black3};
  }
  div.title.can {
    border-top: 2px solid ${(props) => props.theme.colors.error};
  }
  div.card {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 10%);
    margin: 12px 0px;
    height: 92px;
    width: 100%;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Montserrat';
    color: ${(props) => props.theme.colors.gray6};
    padding: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    div.info {
      flex: 1;

      p {
        margin: 0px;
        font-size: 12px;
      }
      h3 {
        margin: 0px;
        padding: 0px;
        font-size: 16px;
      }
      span {
        font-size: 10px;
      }
    }

    img {
      margin-left: 10px;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      border: 1px dashed ${(props) => props.theme.colors.primary};
    }
    div.img {
      margin-left: 10px;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed ${(props) => props.theme.colors.primary};
      svg {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const DivHead = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    height: 50px;
    width: 248px;
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    p {
      color: ${(props) => props.theme.colors.white};
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      margin: 0px;
    }

    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
    }
  }

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
