import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  font-family: Arial;

  select {
    font-size: 16px;
    height: 40px;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
    margin-right: 40px;
    //box-shadow: 0 2px 4px #0d0d0d;
    border-color: ${(props) => shade(0.2, props.theme.colors.success)};
    cursor: pointer;

    &:hover {
      background: ${(props) => shade(0.4, props.theme.colors.success)};
    }

    label + & {
      margin-left: 0px;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.gray7};
    }
  }
`;
