import styled, { keyframes } from 'styled-components';
import { lighten, shade } from 'polished';
import Search from '../../components/Search-isolate';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;

  .divTable {
    border-radius: 10px;
    animation: ${appearFromRight} 1.5s;
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  border-radius: 10px 10px 0 0;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 45vh;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.success};
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => shade(0.1, props.theme.colors.success)};
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.success};
    border-top: 0px;

    th {
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${(props) => props.theme.colors.white};

      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    background-color: ${(props) => props.theme.colors.white};
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.black3};

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        transition: transform 0.2s;

        svg {
          color: #fff;
        }

        & + button {
          margin-left: 20px;
        }

        &:hover {
          transform: scale(1.08);
        }
      }
    }
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.success};
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: ${(props) => props.theme.colors.white3};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.gray12};
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};
  border-radius: 0 0 10px 10px;
  height: auto;

  .divPaginacao {
    display: flex;
    align-items: center;
    width: 0;
    margin-left: auto;
  }

  .btn {
    margin-left: auto;
    margin-right: 15px;
    height: 40px;
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.white};
    border-width: 0px;
    padding: 0 10px;
    font-weight: bold;

    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: initial;
  overflow-x: auto;
  animation: ${appearFromRight} 1s;

  .divSearchButtonGeral {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background: #28262e;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors.success};
    outline: none;
    background-color: ${(props) => props.theme.colors.black3};
    transition: 0.2s;
    svg {
      margin: 0 10px;
    }
    &:hover {
      background-color: ${(props) => lighten(0.1, props.theme.colors.black3)};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background-color: #28262e;
  h2 {
    animation: ${appearFromRight} 1s;
    margin: 0;
    text-align: center;
  }
`;

export const ContentSearch = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background: #28262e;

  .divSearchGeral {
    display: flex;
    margin-right: -15px;
  }
`;

export const SearchCustom = styled(Search)`
  margin-right: 15px;
  border-top-right-radius: 0px !important;
`;
