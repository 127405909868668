import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FiRefreshCw } from 'react-icons/fi';
import Leaflet, { LeafletEvent } from 'leaflet';

import { useAuth } from '../../hooks/auth';
import { GetRotaComplete } from '../../models/Rota';
import { User, UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import ModalConexao from '../../components/ModalConexao';
import api from '../../services/api';
import logo1 from '../../assets/1.png';
import logo2 from '../../assets/2.png';
import logo3 from '../../assets/3.png';
import logo4 from '../../assets/4.png';
import logo5 from '../../assets/5.png';
import logo6 from '../../assets/6.png';
import logo7 from '../../assets/7.png';
import logo8 from '../../assets/8.png';
import logo9 from '../../assets/9.png';
import logo10 from '../../assets/10.png';
import logo11 from '../../assets/11.png';
import logo12 from '../../assets/12.png';
import logo13 from '../../assets/13.png';
import logo14 from '../../assets/14.png';
import logo15 from '../../assets/15.png';
import logo16 from '../../assets/16.png';
import logo17 from '../../assets/17.png';
import logo18 from '../../assets/18.png';
import logo19 from '../../assets/19.png';
import logo20 from '../../assets/20.png';
import {
  Container,
  TitlePage,
  Filter,
  Options,
  Actions,
  Animeted,
  ContainerInputDate,
  LabelInputForm,
  InputForm,
  ButtonForm,
  DivYouAreHere,
  ButtomYouAreHere,
  ButtonTab,
  ContainerForm,
} from './styles';
import Grupos from '../../components/GruposRoteirizacao';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import { filterFloat } from '../../hooks/md5';
import { Loading } from '../../components/Loading';
import { set } from 'date-fns';
import { id } from 'date-fns/locale';
import { Postos } from '../../models/Postos';
import { Grupo } from '../../components/GruposRoteirizacao/types';
import { useToast } from '../../hooks/toast';
import { ButtonSalvar } from '../../components/GruposRoteirizacao/styles';
import { FaSave } from 'react-icons/fa';

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
];


const initialPosition = { lat: -3.8413879, lng: -38.5156058 };

const logoLaranjaIcon = Leaflet.icon({
  iconUrl: logo1,
  iconSize: [35, 35],
  iconAnchor: [35, 35],
  popupAnchor: [170, 2],
});

export const Roteirizacao: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(true);
  const [isSelectedMap, setIsSelectedMap] = useState(false);
  const [isSelectedGrupos, setIsSelectedGrupos] = useState(false);
  const [isSelectedGruposSalvos, setIsSelectedGruposSalvos] = useState(false);
  const [userIdSelect, setUserIdSelect] = useState(-1);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState(initialPosition);
  const [map, setMap] = useState<Leaflet.Map | undefined | null>(undefined);
  const [activePopup, setActivePopup] = useState<any | null>(null);
  const [turno, setTurno] = useState<string>("Diurno");
  const [distancia, setDistancia] = useState('');
  const [minimo, setMinimo] = useState('');
  const [dataAtual, setDataAtual] = useState('');
  const [grupos, setGrupos] = useState<Grupo[]>([]);
  const [gruposJaSalvos, setGruposJaSalvos] = useState<Grupo[]>([]);
  const [listSupervisores, setListSupervisores] = useState<User[]>([]);
  const { addToast } = useToast();

  const gerarRotas = (async () => {
    setIsLoading(true);
    const response = await api.get('/rotas/automatizado', { params: { distancia, minimo, turno, dataAtual } });

    setGrupos(response.data);
    setIsLoading(false);
    setIsSelectedSupervisor(false)
    setIsSelectedMap(true)
  })

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Adiciona 0 à esquerda, se necessário
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam em 0, então adicionamos 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  function getMarkerIcon(index: number, status: string, icon: any): Leaflet.Icon {
    return Leaflet.icon({
      iconUrl: icon,
      iconSize: [35, 35],
      iconAnchor: [35, 35],
      popupAnchor: [170, 2],
    });
  }

  const MarkerRender = useCallback(
    (marker: any, index: number, icon: any): JSX.Element | null => {
      if (marker.latitude) {
        return (
          <>
            <Marker
              key={`${marker.id}`}
              icon={getMarkerIcon(index, marker, icon)}
              position={[
                filterFloat(marker.latitude),
                filterFloat(marker.longitude),
              ]}
              keyboard
              // draggable
              // autoPan
              eventHandlers={{
                click: () => {
                  setActivePopup({ ...marker, grupo: index });
                },
              }}
            />
            {activePopup !== null && (
              <Popup
                position={[
                  filterFloat(String(activePopup.latitude)),
                  filterFloat(activePopup.longitude),
                ]}
                closeButton={false}
                minWidth={240}
                maxWidth={240}
                className="map-popup"
                eventHandlers={{
                  tooltipclose: () => {
                    setActivePopup(null);
                  },
                }}
              >
                <div>
                  <h2>Grupo {activePopup.grupo}</h2>
                  <h3>{activePopup.name}</h3>
                  <h4>Posto - {activePopup.id}</h4>
                  <p>{activePopup.endereco}</p>
                  <p>{activePopup.supervisorId}</p>
                </div>
              </Popup>
            )}
          </>
        );
      } else {
        return <></>
      }
    },
    [activePopup],
  );

  const atualizarGrupos = (novos: Grupo[]) => {
    setGrupos(novos)
  }

  const buscarGruposSalvos = async () => {
    const response = await api.get('/rotas/grupos-salvos');

    const novosGrupos = response.data.map((grupo: any) => {
      return {
        ...grupo,
        localizacoes: grupo.postos?.map((posto: any) => {
          return {
            ...posto,
            id: posto.id,
            name: posto.posto?.name,
          }
        })
      }
    })
    setGruposJaSalvos(novosGrupos)

    const responseSupervisores = await api.get('/users/listSupervisors');

    setListSupervisores(responseSupervisores.data);
  }

  const deletarGrupo = async (grupo: Grupo) => {
    try {
      setIsLoading(true);

      await api.delete(`/rotas/grupo/${grupo.id}`).then((res) => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description:
            res.data.message,
        });
      });
      await buscarGruposSalvos();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          'Erro ao deletar grupo!',
      });
      console.error('Erro ao deletar grupo:', error);
    }
  }

  const gerarRotaGrupo = async (grupo: Grupo | Grupo[], supervisores: User[]) => {
    setIsLoading(true);
    try {

      const grupos = Array.isArray(grupo) ? grupo : [grupo];

      const promises = grupos.map(async (g) => {
        const responseSalvarGrupo = await api.post('/rotas/grupo', {
          ...g,
          supervisorId: supervisores[g.grupo],
        });

        return api.post('/rotas/gerar-rota-grupo', {
          grupoId: responseSalvarGrupo.data.grupoRota,
          supervisorId: supervisores[g.grupo],
          data: dataAtual,
          turno: turno,
        });
      });

      await Promise.all(promises);
    } catch (error) {
      console.error('Erro ao gerar rotas:', error);
    } finally {
      setIsLoading(false);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description:
          'Grupos salvos e rotas geradas com sucesso!',
      });
    }
  };

  useEffect(() => {
    const hoje = new Date();
    const amanha = new Date(hoje);
    amanha.setDate(hoje.getDate() + 1);
    setDataAtual(formatDate(amanha))
    buscarGruposSalvos()

  }, [])

  const onSetIsSelectedGruposSalvos = (value: boolean) => {
    if (value) {
      buscarGruposSalvos()
    }
    setIsSelectedGruposSalvos(value)

  }

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Roteirização Automática</TitlePage>

        <Options>
          <div>
            <ButtonTab
              disabled={false}
              isSelected={isSelectedSupervisor}
              onClick={() => {
                setIsSelectedSupervisor(true);
                setIsSelectedMap(false)
                setIsSelectedGrupos(false);
                onSetIsSelectedGruposSalvos(false);
              }}
            >
              1. Gerar Rotas
            </ButtonTab>
          </div>
          <div>
            <ButtonTab
              disabled={!grupos.length}
              isSelected={isSelectedMap}
              onClick={() => {
                setIsSelectedMap(true);
                setIsSelectedGrupos(false);
                setIsSelectedSupervisor(false);
                onSetIsSelectedGruposSalvos(false);
              }}
            >
              2. Mapa com rotas geradas
            </ButtonTab>
          </div>
          <div>
            <ButtonTab
              disabled={!grupos.length}
              isSelected={isSelectedMap}
              onClick={() => {
                setIsSelectedGrupos(true);
                setIsSelectedMap(false);
                setIsSelectedSupervisor(false);
                onSetIsSelectedGruposSalvos(false);

              }}
            >
              3. Listagem de rotas
            </ButtonTab>
          </div>
          <div>
            <ButtonTab
              isSelected={isSelectedGruposSalvos}
              onClick={() => {
                setIsSelectedGrupos(false);
                setIsSelectedMap(false);
                setIsSelectedSupervisor(false);
                onSetIsSelectedGruposSalvos(true);
              }}
            >
              4. Grupos salvos
            </ButtonTab>
          </div>
        </Options>

        {isSelectedSupervisor && (<>
          <ContainerForm>
            <InputForm
              name="distancia"
              placeholder="Distância máxima em km para formar grupos"
              onChange={(e) => {
                setDistancia(e.target.value)
              }}
              value={distancia}
            />
            <InputForm
              name="minimo"
              placeholder="Número mínimo de pontos para formar um grupo"
              onChange={(e) => {
                setMinimo(e.target.value)
              }}
              value={minimo}
            />
            <InputForm
              disabled
              placeholder='Data referência'
              name="distancia"
              value={dataAtual}
            />
            <select
              name="empresaId"
              id="empresaId"
              placeholder='Turno'
              onChange={(e) => {
                setTurno(e.target.value)
              }}
              style={{ marginBottom: '41px' }}
              value={turno}
            >
              <option value="Diurno">Diurno</option>
              <option value="Noturno">Noturno</option>
            </select>
            <ButtonForm
              background="#393B41"
              onClick={() => {
                gerarRotas();
              }}
            >
              Gerar
            </ButtonForm>
          </ContainerForm>
        </>
        )}

        {isSelectedGrupos && grupos.length > 0 && (<Container>
          {gruposJaSalvos.length > 0 && (<ButtonSalvar salvar onClick={() => gerarRotaGrupo(gruposJaSalvos, listSupervisores)} title='Salvar estrutura de grupos e gerar rotas'>
            <FaSave size={25} />
          </ButtonSalvar>)}
          <Grupos grupos={grupos} atualizarGrupos={atualizarGrupos} gerarRotaGrupo={gerarRotaGrupo} listSupervisores={listSupervisores} deletarGrupo={deletarGrupo} />
        </Container>)}

        {isSelectedGruposSalvos && (<Container>
          <Grupos grupos={gruposJaSalvos} salvos={true} atualizarGrupos={atualizarGrupos} gerarRotaGrupo={gerarRotaGrupo} listSupervisores={listSupervisores} deletarGrupo={deletarGrupo} />
        </Container>)}

        {isSelectedMap && (<>
          <MapContainer
            center={[location.lat, location.lng]}
            zoom={15}
            whenCreated={setMap}
            scrollWheelZoom
            style={{ width: '100%', height: '100%' }}
          >

            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`}
            />

            {grupos.length > 0 && grupos.map((marker, index) => {
              const icon = logos[Math.floor(Math.random() * logos.length)];
              const ind = marker.grupo;
              return marker.localizacoes.map((localizacao, i) => {
                return MarkerRender(localizacao, ind, icon)
              })
            })}
          </MapContainer>


        </>
        )}
        {isLoading && (
          <Loading />
        )}

      </Container >
    </>
  );
};
