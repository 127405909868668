import styled from 'styled-components';

interface PropsContainer {
  visibleCalendar: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  .Autocomplete {
    padding-left: 5px;
    width: 100%;

    margin-bottom: 40px;

    .css-1eyj61a {
      display: none;
    }
    .chakra-input {
      width: 100% !important;
    }

    .css-1588jgr {
      width: 100% !important;
      max-width: 100% !important;
    }

    input {
      padding-left: 10px;
      width: 100%;
      height: 50px;
    }
  }
`;

export const ContainerInputDate = styled.div`
  width: 100%;
  height: 100px;
  overflow: auto;

  margin-bottom: 20px;

  display: flexbox;

  /* input {
    width: 200px;
    height: 50px;
    margin: 5px 8px;
  } */
`;

export const DivInput = styled.div`
  width: 200px;
  height: 52px;
  margin: 0 5px;

  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;

  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;
`;

export const ButtomRemoveDate = styled.button`
  background-color: transparent;
  width: 25px;
  height: 25px;

  border: none;
  border-radius: 50px;

  margin-top: auto;
  margin-bottom: auto;

  img {
    width: 20px;
    height: 20px;
    margin: auto;
  }
`;
