/* eslint-disable array-callback-return */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Swal from 'sweetalert2';
import { FiEdit, FiFilter, FiHelpCircle, FiTrash } from 'react-icons/fi';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from 'styled-components';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { PostoJson, Postos } from '../../models/Postos';
import { Filiais } from '../../models/Filiais';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import NewInput from '../../components/NewInput';
import NewModal from '../../components/NewModal';
import BodyAddPosto from '../../components/NewModal/BodyAddPosto';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import Table from '../../components/Table';
import ModalConexao from '../../components/ModalConexao';
import { SliderButtonDefault } from '../../components/SliderButtonDefault';
import Tooltip from '../../components/Tooltip';
import { Loading } from '../../components/Loading';
import { TitlePage } from '../../components/TitlePage';
import { FilterOBJ, ModalFilterPosto } from './ModalFilter';

import {
  ContainerCadastro,
  AnimationContainer,
  DivLineBottom,
  HeaderForm,
  ContainerInputForm,
  LabelInputForm,
  ButtonAddPost,
  SubHeaderEvidence,
  ContainerTablePost,
  ButtonForm,
  ContainerButtonForm,
  ButtonTable,
  Container,
  ButtonIcon,
  ContentFilter,
  Legend,
  Text,
  Circle,
  Containerl,
} from './styles';
import FileInput from '../../components/FileInput';
import { useToast } from '../../hooks/toast';

const headers = [
  { name: 'Cliente ID', field: 'id', sortable: true },
  { name: 'Empresa', field: 'empresaId', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Telefone', field: 'telefone', sortable: true },
  { name: 'Cpf/Cnpj', field: 'documento', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Status', field: 'activated', sortable: true },
  { name: 'Editar', field: 'editOption', sortable: true },
  { name: 'Excluir', field: 'deleteOption', sortable: true },
];

const headersFilter = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Endereço', field: 'endereco', sortable: true },
  { name: 'Bairro', field: 'bairro', sortable: true },
  { name: 'Município', field: 'municipio', sortable: true },
  { name: 'Estado', field: 'estado', sortable: true },
  { name: 'CEP', field: 'cep', sortable: true },
];

interface Clientes {
  id?: string | number;
  importId?: string;
  name: string;
  empresaId?: number;
  telefone: string;
  documento: string;
  type: string;
  origem: string;
  active?: boolean;
  bairro?: string;
  cep?: string;
  endereco?: string;
  estado?: string;
  municipio?: string;
}

const CadastroCliente: React.FC = () => {
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();
  const history = useHistory();
  const { user, empresaPrincipal } = useAuth();
  const [filiais, setFiliais] = useState<Filiais[]>([]);
  const [loading, setLoading] = useState(false);

  const [idCurrentClient, setIdCurrentClient] = useState(0);
  const [filialCliente, setFilialCliente] = useState('0');
  const [nomeCliente, setNomeCliente] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [phone, setPhone] = useState('');
  const [clientesFiltered, setClientesFiltered] = useState<Postos[]>([]);
  const [centroCusto, setCentroCusto] = useState<Postos[]>([]);
  const [currentCentroCusto, setCurrentCentroCusto] = useState<
    Postos | undefined
  >();
  const [indexCurrentCusto, setIndexCurrentCusto] = useState(-1);
  const [visibilityModalCentroCusto, setVisibilityModalCentroCusto] = useState(
    false,
  );
  const [clientes, setClientes] = useState<Clientes[]>([]);

  const [cadastrar, setCadastrar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState<FilterOBJ>({
    endereco: '',
    name: '',
    turno: '',
    type: '',
  });

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const { addToast } = useToast();

  const handleSubmitDelete = useCallback(
    async (idCat: number): Promise<void> => {
      const postos = await api.get(`/postos/cliente?clienteId=${idCat}`);
      const postosAtivos: any[] = [];
      postos.data.map((posto: any) => {
        if (posto.active === true) {
          postosAtivos.push(posto);
        }
      });
      Swal.fire({
        title:
          postosAtivos.length === 0
            ? 'Tem certeza que deseja deletar?'
            : `Tem certeza que deseja deletar? Este cliente ainda possui ${postosAtivos.length
            } ${postosAtivos.length === 1 ? 'posto ativo' : 'postos ativos'
            }.`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = { id: idCat };
          const atualizarPostos: any[] = [];
          postosAtivos.map((posto: any) => {
            // eslint-disable-next-line no-param-reassign
            posto.active = false;
            atualizarPostos.push(postos);
          });
          api.put('/postos', ...atualizarPostos).catch((err) => {
            console.log('ERRR', err.message);
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
          });
          api
            .delete('/clientes', { data: obj })
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Operação realizada com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              setTimeout(() => {
                setCadastrar(false);
                go(0);
              }, 1000);
            })
            .catch((err) => {
              console.log('ERRR', err.message);
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
            });
        }
      });
      //
    },
    [colors.error, colors.success, go],
  );

  const handleSubmit = async (): Promise<void> => {
    try {
      const clienteEnv: Clientes = {
        name: nomeCliente,
        telefone: phone.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''),
        empresaId: parseInt(filialCliente, 10),
        documento: cnpj,
        type: 'PJ',
        origem: 'cad',
      };

      const TratmentCentroCusto = (idClient: number): Postos[] => {
        centroCusto.map((element) => {
          // eslint-disable-next-line no-param-reassign
          element.empresaId = parseInt(filialCliente, 10);
          // eslint-disable-next-line no-param-reassign
          element.clienteId = idClient;
          return element;
        });

        return centroCusto;
      };

      if (clienteEnv.name === '') {
        Swal.fire({
          icon: 'info',
          title: 'Campo(s) vazio(s)!',
          text: 'Favor preecher os campos "Nome do cliente".',
        });
      } else if (clienteEnv.telefone === '') {
        Swal.fire({
          icon: 'info',
          title: 'Campo(s) vazio(s)!',
          text: 'Favor preecher os campos "Telefone".',
        });
      } else if (clienteEnv.empresaId === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Campo(s) vazio(s)!',
          text: 'Favor preecher os campos "Filial".',
        });
      } else if (clienteEnv.documento === '') {
        Swal.fire({
          icon: 'info',
          title: 'Campo(s) vazio(s)!',
          text: 'Favor preecher os campos "CNPJ".',
        });
      } else if (idCurrentClient === 0) {
        setLoading(true);
        api
          .post('/clientes', clienteEnv)
          .then(async (res) => {
            const dataEnv = TratmentCentroCusto(parseInt(res.data.id, 10));
            api
              .post('/postos', dataEnv)
              .then(() => {
                setLoading(false);
                Swal.fire({
                  icon: 'success',
                  title: 'Cliente cadastrado com sucesso!',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
                setTimeout(() => {
                  setCadastrar(false);
                  history.go(0);
                }, 1000);
              })
              .catch(() => {
                setLoading(false);
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao cadastrar o(s) Posto(s)!',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
              });
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro cadastrar um Cliente!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
          });
      } else {
        setLoading(true);
        centroCusto.forEach((e) => {
          e.id = Number(e.id);
          e.clienteId = Number(idCurrentClient);
          e.empresaId = Number(filialCliente);
        });
        clienteEnv.id = idCurrentClient;
        clienteEnv.id = idCurrentClient;
        const apis = [api.put('/clientes', clienteEnv)];
        if (centroCusto.filter((e) => e.id !== 0).length > 0) {
          apis.push(
            api.put(
              '/postos',
              centroCusto.filter((e) => e.id !== 0),
            ),
          );
        }
        if (centroCusto.filter((e) => e.id === 0).length > 0) {
          apis.push(
            api.post(
              '/postos',
              centroCusto.filter((e) => e.id === 0),
            ),
          );
        }
        Promise.all(apis)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Cliente e postos editados com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });

            setTimeout(() => {
              setCadastrar(false);
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao editar Cliente/Posto!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
          });
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro! Por favor, tente novamente mais tarde!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        showConfirmButton: false,
      });
    }
  };

  const getItems = useCallback(async () => {
    const clientesGet = await api.get(
      `/clientes/empresa?empresaId=${empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );

    const empresasGet = await api.get(
      `/empresas/${empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );
    setFiliais(empresasGet.data);
    const clients: Clientes[] = clientesGet.data;
    setClientes(
      clients.map((i) => ({
        documento: i.documento || '',
        name: i.name || '',
        origem: i.origem || '',
        telefone: i.telefone || '',
        type: i.type || '',
        empresaId: i.empresaId,
        id: i.id,
        importId: i.importId,
        bairro: i.bairro || '',
        cep: i.cep || '',
        endereco: i.endereco || '',
        estado: i.estado || '',
        municipio: i.municipio || '',
        active: i.active || false,
      })),
    );
    setClientesFiltered(clientesGet.data);
  }, [empresaPrincipal]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  // const convetTarefasString = (tarefas: ItemReduce): string => {
  //   const ReturnTarefasString = tarefas.reduce((prV: string[], cuV: string) => {
  //     prV.push(cuV.name);
  //     return prV;
  //   }, []);

  //   return ReturnTarefasString.toString();
  // };

  const convetNameWeekString = (typePeriod: string): string => {
    if (typePeriod === 'dir') {
      return 'Diária';
    }
    if (typePeriod === 'sem') {
      return 'Semanal';
    }
    if (typePeriod === 'men') {
      return 'Mensal';
    }
    if (typePeriod === 'anu') {
      return 'Anual';
    }
    if (typePeriod === 'nad') {
      return 'Sem periodicidade';
    }
    return '';
  };

  const convetPeriodicidade = (typePeriod: string, freq: string): string => {
    const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    // const weekMonth = [
    //   'Jan',
    //   'Fev',
    //   'Mar',
    //   'Abr',
    //   'Mai',
    //   'Jun',
    //   'Jul',
    //   'Ago',
    //   'Set',
    //   'Out',
    //   'Nov',
    //   'Dez',
    // ];

    if (typePeriod === 'dir' || typePeriod === 'sem') {
      const array = freq !== null ? freq.split('-') : [''];
      const nameWeek = array.map((e) => {
        return weekDays[parseInt(e, 10)];
      });
      return nameWeek.toString();
    }
    if (typePeriod === 'men') {
      return freq;
    }
    return '';
  };

  const handleSubmitEdit = async (idClientEdit: number): Promise<void> => {
    const idStringConverted = idClientEdit.toString();
    setIdCurrentClient(idClientEdit);
    console.log(idStringConverted)
    const clientCurrent = clientes.find(({ id }) => id == idStringConverted);
    console.log(clientCurrent)

    const centrosCustosCurrentClient = await api.get(
      `/postos/cliente?clienteId=${idClientEdit}`,
    );
    // console.log(`/postos/cliente?clienteId=${idClientEdit}`);
    // console.log(centrosCustosCurrentClient.data);
    setCentroCusto(centrosCustosCurrentClient.data);
    setFilialCliente(clientCurrent!.empresaId!.toString());
    setPhone(clientCurrent!.telefone || '');
    setNomeCliente(clientCurrent!.name || '');
    setCnpj(clientCurrent!.documento || '');
    setCadastrar(true);
  };

  const handleCancel = (): void => {
    setCadastrar(false);
    setCentroCusto([]);
    setFilialCliente('0');
    setPhone('');
    setNomeCliente('');
    setCnpj('');
  };

  const listPostos = useMemo(() => {
    let list = centroCusto;
    if (filter.endereco) {
      list = list.filter((i) =>
        i.endereco
          .toLocaleLowerCase()
          .includes(filter.endereco.toLocaleLowerCase()),
      );
    }
    if (filter.name) {
      list = list.filter((i) =>
        i.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase()),
      );
    }
    if (filter.type) {
      list = list.filter(
        (i) =>
          i.typePeriodicidade !== null &&
          i.typePeriodicidade
            .toLocaleLowerCase()
            .includes(filter.type.toLocaleLowerCase()),
      );
    }
    if (filter.turno) {
      list = list.filter(
        (i) =>
          i.turno !== null &&
          i.turno
            .toLocaleLowerCase()
            .includes(filter.turno.toLocaleLowerCase()),
      );
    }
    return list;
  }, [centroCusto, filter.endereco, filter.name, filter.turno, filter.type]);

  const handleFileSelect = (file: File | null) => {
    if (file) {
      console.log("Arquivo selecionado:", file);
    }
  };

  const onHandleSavePostos = async (rotas: Array<PostoJson>) => {
    const postos = await api.post(
      `/postos`, rotas
    ).then(() => {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description:
          'Postos criados com sucesso!',
      });
    }).catch(() => {
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          'Erro ao cadastrar postos...',
      });
    });

  }

  return (
    <>
      <ModalConexao />
      <Container>
        <NewModal isVisible={visibilityModalCentroCusto}>
          <BodyAddPosto
            posto={centroCusto}
            setAddPosto={(postosValues: Postos[]) => {
              setCentroCusto(postosValues);
              setCurrentCentroCusto(postosValues[0]);
            }}
            setVisible={setVisibilityModalCentroCusto}
            currentCentroCustoEdit={currentCentroCusto}
            indexCurrentCustoEdit={indexCurrentCusto}
          />
        </NewModal>
        <ModalFilterPosto
          filterPrin={filter}
          isVisible={showFilter}
          onClose={() => setShowFilter(false)}
          setFilterPrin={setFilter}
        />
        <TitlePage title="Clientes" />
        <ContentFilter>
          <FileInput onFileSelect={handleFileSelect} onHandleSavePostos={onHandleSavePostos} />
        </ContentFilter>
        <br />
        <ListRegistButton
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
          object={{ list: 'Listar', regist: 'Cadastrar' }}
        />
        {!cadastrar && (
          <>
            <NewSearch
              arraySetState={setClientesFiltered}
              arrayState={clientes}
              selectFilters={headersFilter}
            />
            <Table
              tableId="tableClientes"
              // body={clientesFiltered.filter((e) => e.id.toString() === '226')}
              body={clientesFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headers}
              filiais={filiais}
              setEditId={(id) => {
                handleSubmitEdit(id);
              }}
              setRemoveId={(id) => handleSubmitDelete(id)}
            />
            <PaginationComponent
              arrayStateFiltered={clientesFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <NewImportExel fileName="Clientes" tableId="tableClientes" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <AnimationContainer>
              <HeaderForm>Cadastro de clientes</HeaderForm>
              <ContainerInputForm>
                <LabelInputForm>Filial</LabelInputForm>

                <select
                  name="empresaId"
                  id="empresaId"
                  onChange={(e) => {
                    setFilialCliente(e.target.value);
                  }}
                  style={{ marginBottom: '41px' }}
                  value={filialCliente}
                >
                  <option value="0">Selecionar filial:</option>
                  {filiais.map((e) => {
                    return <option value={e.ID}>{e.NOME.toUpperCase()}</option>;
                  })}
                </select>

                <LabelInputForm>Nome do cliente</LabelInputForm>

                <NewInput
                  name="name"
                  setStateValue={setNomeCliente}
                  stateValue={nomeCliente}
                  placeholder="Digite aqui"
                  mask="text"
                />

                <LabelInputForm>CNPJ</LabelInputForm>
                <NewInput
                  name="cnpj"
                  setStateValue={setCnpj}
                  stateValue={cnpj}
                  placeholder="Digite aqui"
                  mask="cnpj"
                />

                <LabelInputForm>Telefone</LabelInputForm>

                <NewInput
                  name="phone"
                  setStateValue={setPhone}
                  stateValue={phone}
                  placeholder="Digite aqui"
                  mask="phone"
                />
              </ContainerInputForm>
              <div style={{ display: 'flex', columnGap: '1rem' }}>
                <SubHeaderEvidence>Cadastro de postos</SubHeaderEvidence>
                <div style={{ marginTop: 37 }}>
                  <Tooltip
                    title="Postos já cadastrados podem somente ser desativados e postos recem criados podem ser excluídos"
                    className="default"
                  >
                    <FiHelpCircle style={{ height: 20, width: 20 }} />
                  </Tooltip>
                </div>
              </div>
              <ContentFilter>
                <ButtonAddPost
                  type="button"
                  onClick={() => {
                    setCurrentCentroCusto(undefined);
                    setIndexCurrentCusto(-1);
                    setVisibilityModalCentroCusto(true);
                  }}
                >
                  Adicionar posto +
                </ButtonAddPost>
                <ButtonIcon type="button" onClick={() => setShowFilter(true)}>
                  <FiFilter />
                </ButtonIcon>
              </ContentFilter>

              <DivLineBottom />
            </AnimationContainer>
            <ContainerTablePost>
              <table id="postoTable">
                <thead>
                  <td>
                    <h3>Nome</h3>
                  </td>
                  <td>
                    <h3>Endereço</h3>
                  </td>
                  <td>
                    <h3>Periodicidade</h3>
                  </td>
                  <td>
                    <h3>Turno</h3>
                  </td>
                  <td>
                    <h3>Editar</h3>
                  </td>
                  <td>
                    <h3>Status / Excluir</h3>
                  </td>
                </thead>
                <tbody>
                  {listPostos.map((item, index) => {
                    return (
                      <tr style={{ backgroundColor: item.Visitas?.length == 1 ? 'rgb(189, 194, 255)' : '' }}>
                        <td>{item.name}</td>
                        <td title={item.endereco}>{item.endereco}</td>
                        <td>
                          {convetPeriodicidade(
                            item.typePeriodicidade,
                            item.freqAno,
                          )}
                        </td>
                        <td>{item.turno}</td>
                        <td>
                          <ButtonTable
                            type="button"
                            onClick={() => {
                              setCurrentCentroCusto(item);
                              setIndexCurrentCusto(index);
                              setVisibilityModalCentroCusto(true);
                            }}
                          >
                            <FiEdit />
                          </ButtonTable>
                        </td>
                        <td>
                          {item.new ? (
                            <ButtonTable
                              type="button"
                              onClick={() => {
                                setCentroCusto(
                                  centroCusto.filter((_, i) => index !== i),
                                );
                              }}
                            >
                              <FiTrash />
                            </ButtonTable>
                          ) : (
                            <SliderButtonDefault
                              activeStatus={item.active}
                              onUpdate={() => {
                                setCentroCusto(
                                  centroCusto.map((i) =>
                                    item.id === i.id
                                      ? { ...i, active: !i.active }
                                      : i,
                                  ),
                                );
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ContainerTablePost>
            <Legend>
              <strong>Legenda:</strong>
              <Containerl>
                <Circle />
                <Text>A rota é apenas para um dia</Text>
              </Containerl>
            </Legend>
            <NewImportExel fileName="Postos" tableId="postoTable" />
            <ContainerButtonForm>
              <ButtonForm background="#FFFFFF" onClick={handleCancel}>
                Cancelar
              </ButtonForm>
              <ButtonForm
                disabled={loading}
                background="#393B41"
                onClick={handleSubmit}
              >
                Salvar
              </ButtonForm>
            </ContainerButtonForm>
          </ContainerCadastro>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default CadastroCliente;
