import styled from 'styled-components';
import { shade } from 'polished';

export const Credits = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => shade(0.4, props.theme.colors.white)};
  font-size: 12px;
  position: fixed;
  bottom: 8px;
  right: 8px;

  svg {
    margin-right: 5px;
  }
`;
