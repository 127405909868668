export const returnNameVistoria = (
  stringIndex: string | undefined,
  vistorias: any[] | undefined,
): string | undefined => {
  if (vistorias?.length !== 0) {
    const nameFilial = vistorias?.find((e?) => e.id === stringIndex);
    return nameFilial?.name;
  }
  return `${stringIndex}-`;
};
