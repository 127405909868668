import styled from 'styled-components';

interface ContainerProps {
  selected: boolean;
}

export const Aba = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
  }
`;
export const ContainerAba = styled.div<ContainerProps>`
  display: flex;

  button {
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black2};
    min-width: 200px;

    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .list {
    background-color: black;
    border-bottom: ${(props) =>
      !props.selected ? `1px solid ${props.theme.colors.black2}` : 'none'};
  }

  .regist {
    background-color: black;
    border-bottom: ${(props) =>
      !props.selected ? 'none' : `1px solid ${props.theme.colors.black2}`};
  }
`;
