import React, { useRef, useCallback, useState } from 'react';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/GestaoRonda-logo-semFundo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import CreditsSpan from '../../components/Credits';
import { Content, AnimationContainer, Background } from './styles';
import {
  ContainerBoxGrey,
  ContainerBoxWhite,
  Container,
  LogoApp,
  LogoAppSeg,
  ContainerLogoApp,
  Line,
  TextButton,
  HeaderInput,
  LinkForgotPass,
} from '../SignIn/styles';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Recuperar sua senha.
        await api
          .post(
            '/password/forgot',
            {
              email: data.email,
            },
            {
              onUploadProgress(progress) {
                // setPorcentagem((progress.loaded * 100) / progress.total);
              },
            },
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'E-mail de recuperação enviado!',
              description:
                'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de entrada.',
            });
          })
          .catch(() => {
            // console.log(e.response.data.message);
            addToast({
              type: 'error',
              title: 'Falha ao recuperar E-mail!',
              description: 'E-mail não cadastrado!',
            });
          });

        // history.push('/dashboard');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha!',
          description:
            'Ocorreu um ao tentar realizar a recuperação de senha, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <ContainerBoxGrey>
          <ContainerLogoApp>
            <LogoApp>Gestão de</LogoApp>
            <LogoAppSeg>Segurança</LogoAppSeg>
          </ContainerLogoApp>
        </ContainerBoxGrey>
        <ContainerBoxWhite>
          <Content>
            <AnimationContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Recuperar senha</h1>
                <HeaderInput>E-mail</HeaderInput>
                <Input name="email" placeholder="E-mail" />

                <Button
                  loading={loading}
                  type="submit"
                  style={{
                    marginTop: '32px',
                  }}
                >
                  <TextButton>Recuperar</TextButton>
                </Button>
                <Line />
                <Link to="/">
                  <LinkForgotPass>Voltar ao início</LinkForgotPass>
                </Link>
              </Form>

              {/* <Link to="/">
                <FiLogIn />
                Voltar ao login
              </Link> */}
            </AnimationContainer>
            <CreditsSpan />
          </Content>
          <Background />
        </ContainerBoxWhite>
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default ForgotPassword;
