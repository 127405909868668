import React, { createContext, useCallback, useState, useContext } from 'react';
import moment from 'moment-timezone';
import api from '../services/api';

interface Acesso {
  ID: number;
  Nome: string;
  CPF: number;
  EMAIL: string;
  SetorID: number;
  Setor: string;
  acesso: number;
  idUsuario: number;
  Nome_acesso: string;
}

interface User {
  ativo: number;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  dataNascimento: string;
  email: string;
  id: string;
  nome: string;
  telefone: string;
  tipo: string;
  supervisor: boolean;
  motoboy: boolean;
}

interface UserMaster {
  ID: number;
  NOME: string;
  CPF: string;
  EMAIL: string;
  DATA_NASCIMENTO: string;
  TELEFONE: string;
  Id_Empresas: number;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
}

export interface UserEmpresaPrincipal {
  ATIVO: boolean;
  BAIRRO: string;
  CIDADE: string;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

interface AuthState {
  token: string;
  dataHoraToken: string;
  user: User;
  cpfUserMaster: UserMaster[];
  empresaPrincipal: UserEmpresaPrincipal[];
  acesso: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}
interface Props {
  children:React.ReactNode
}
interface AuthContextData {
  logeded: boolean;
  dataHoraToken: string;
  user: User;
  cpfUserMaster: UserMaster[];
  empresaPrincipal: UserEmpresaPrincipal[];
  acesso: number;
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [logeded, setLogeded] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@QrcodeSeguranca:token');
    const dataHoraToken = localStorage.getItem(
      '@QrcodeSeguranca:dataHoraToken',
    );
    const user = localStorage.getItem('@QrcodeSeguranca:user');
    const cpfUserMaster = localStorage.getItem(
      '@QrcodeSeguranca:cpfUserMaster',
    );
    const empresaPrincipal = localStorage.getItem(
      '@QrcodeSeguranca:empresaPrincipal',
    );
    const acesso = localStorage.getItem('@QrcodeSeguranca:acesso');

    if (
      token &&
      dataHoraToken &&
      user &&
      cpfUserMaster &&
      empresaPrincipal &&
      acesso
    ) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        dataHoraToken: JSON.parse(dataHoraToken),
        user: JSON.parse(user),
        cpfUserMaster: JSON.parse(cpfUserMaster),
        empresaPrincipal: JSON.parse(empresaPrincipal),
        acesso,
      };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    setLogeded(false);
    const token = localStorage.removeItem('@QrcodeSeguranca:token'); // eslint-disable-line
    const dataHoraToken = localStorage.removeItem('@QrcodeSeguranca:dataHoraToken');// eslint-disable-line
    const user = localStorage.removeItem('@QrcodeSeguranca:user');// eslint-disable-line
    const cpfUserMaster = localStorage.removeItem('@QrcodeSeguranca:cpfUserMaster');// eslint-disable-line
    const empresaPrincipal = localStorage.removeItem('@QrcodeSeguranca:empresaPrincipal');// eslint-disable-line
    const acesso = localStorage.removeItem('@QrcodeSeguranca:acesso');// eslint-disable-line

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(
    async ({ email, password }:SignInCredentials): Promise<boolean> => {
      const m = moment.tz('America/Fortaleza');
      const dataAtual = m.utc().format();
      setLogeded(true);

      const response = await api.post('sessions', {
        email,
        password,
      });

      const {
        token,
        dataHoraToken,
        user,
        cpfUserMaster,
        empresaPrincipal,
        acesso,
      } = response.data;

      localStorage.setItem('@QrcodeSeguranca:token', token);
      localStorage.setItem(
        '@QrcodeSeguranca:dataHoraToken',
        JSON.stringify(dataAtual),
      );
      localStorage.setItem('@QrcodeSeguranca:user', JSON.stringify(user));

      localStorage.setItem(
        '@QrcodeSeguranca:cpfUserMaster',
        JSON.stringify(cpfUserMaster),
      );
      localStorage.setItem(
        '@QrcodeSeguranca:empresaPrincipal',
        JSON.stringify(empresaPrincipal),
      );
      localStorage.setItem('@QrcodeSeguranca:acesso', JSON.stringify(acesso));

      api.defaults.headers.authorization = `Bearer ${token}`;

      if (response.data.user.acesso !== '1') {
        signOut();
        return false;
      }

      setData({
        token,
        dataHoraToken,
        user,
        cpfUserMaster,
        empresaPrincipal,
        acesso,
      });
      return true;
    },
    [signOut],
  );

  const updateUser = useCallback(
    async (user: User) => {
      localStorage.setItem('@QrcodeSeguranca:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      } as AuthState);
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        logeded,
        dataHoraToken: data.dataHoraToken,
        user: data.user,
        cpfUserMaster: data.cpfUserMaster,
        empresaPrincipal: data.empresaPrincipal,
        acesso: parseInt(data.acesso, 10),
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
