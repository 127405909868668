import React, { useCallback, useContext } from 'react';

import { ThemeContext } from 'styled-components';

import avatar2 from '../../assets/avatar2.png';
import { Card, Line, ImagePerfil, Info, DivTrio, DivTrioPhoto } from './styles';
import { GetRota2User } from '../../pages/MapsDashboardNew';

interface Props {
  item: GetRota2User;
}

export const CardRotaMap: React.FC<Props> = ({ item }): JSX.Element => {
  const { colors } = useContext(ThemeContext);

  const handleColor = useCallback(
    (
      val:
        | 'Aprovada'
        | 'A Aprovar'
        | 'Reprovada'
        | 'Rejeitada'
        | 'Concluido'
        | 'Concluido com pendencias'
        | 'Não realizado'
        | 'Não realizada[Automática]'
        | 'Andamento'
        | '',
    ) => {
      switch (val) {
        case 'Aprovada':
        case '':
          return colors.warning;
        case 'Andamento':
          return colors.info;
        case 'Concluido':
          return colors.success;
        case 'Concluido com pendencias':
          return colors.successShade;
        case 'Não realizado':
          return colors.error;
        case 'Não realizada[Automática]':
          return colors.error;
        default:
          return colors.gray9;
      }
    },
    [
      colors.warning,
      colors.info,
      colors.success,
      colors.successShade,
      colors.error,
      colors.gray9,
    ],
  );

  return (
    <Card>
      <Line color={handleColor(item.status)} />
      <Info>
        <DivTrioPhoto>
          <DivTrio>
            <div>
              <b>Rota:</b>
              {`${item.rotaId}`}
            </div>
            <div>
              <b>Supervisor:</b>
              {`${item.supervisorId} - ${item.username}`}
            </div>
            <div>
              <b>Cliente:</b>
              {`${item.Visita.Posto.name}`}
            </div>
          </DivTrio>
          <ImagePerfil src={avatar2} />
        </DivTrioPhoto>
        <div>
          <b>Endereço:</b>
          {`${item.Visita.Posto.endereco}`}
        </div>
      </Info>
    </Card>
  );
};
