import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import {
  FiUser,
  FiCalendar,
  FiCheckCircle,
  FiEdit,
  FiMapPin,
  FiSun,
  FiMoon,
} from 'react-icons/fi';
import { ThemeContext } from 'styled-components';
import { Visita } from '../../models/Visita';
import { ModalEditRota } from '../../pages/DashboardAgendamentoRota/ModalEdit';
// import { AgendaRota } from '../../pages/DashboardAgendamentoRota/interface';
import { Card, Line, Tool } from './styles';

interface Props {
  nameSupervisor?: string;
  idRota?: string;
  item: Visita;
  option?: boolean;
  onAprovar(idVisita: number): void;
  onRejeitar(idVisita: number): void;
}

const CardAgendamentoVisita: React.FC<Props> = ({
  item,
  option,
  onAprovar,
  onRejeitar,
  nameSupervisor,
}) => {
  const { colors } = useContext(ThemeContext);
  const [colorButtonAprov, setColorButtonAprov] = useState(false);
  const [colorButtonRemov, setColorButtonRemov] = useState(false);
  const [displayCard, setDisplayCard] = useState('flex');
  const [isShowModal, setIsShowModal] = useState(false);

  const useColorLine = useCallback(
    (status: string) => {
      switch (status) {
        case 'Aprovada':
          return colors.warning;
        case 'A Aprovar':
          return colors.info;
        case 'A iniciar':
          return colors.warning;
        case 'Cancelado':
          return colors.error;
        case 'Não realizada[Automática]':
          return colors.error;
        case 'Não Finalizado':
          return colors.error;
        case 'Concluido':
          return colors.success;
        case 'Andamento':
          return colors.info;
        case 'Concluido com pendencias':
          return colors.primary;
        default:
          return colors.gray5;
      }
    },
    [
      colors.error,
      colors.gray5,
      colors.info,
      colors.primary,
      colors.success,
      colors.warning,
    ],
  );

  const getMes = useCallback((date: string) => {
    const numberMes = moment(date).get('month');
    switch (numberMes) {
      case 0:
        return 'JAN';
      case 1:
        return 'FEV';
      case 2:
        return 'MAR';
      case 3:
        return 'ABR';
      case 4:
        return 'MAI';
      case 5:
        return 'JUN';
      case 6:
        return 'JUL';
      case 7:
        return 'AGO';
      case 8:
        return 'SET';
      case 9:
        return 'OUT';
      case 10:
        return 'NOV';
      case 11:
        return 'DEZ';
      default:
        return '';
    }
  }, []);

  const getDayWeek = useCallback((date: string) => {
    const numberDayWeek = moment(date).get('day');
    switch (numberDayWeek) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Segunda-Feira';
      case 2:
        return 'Terça-Feira';
      case 3:
        return 'Quarta-Feira';
      case 4:
        return 'Quinta-Feira';
      case 5:
        return 'Sexta-Feira';
      case 6:
        return 'Sábado';
      default:
        return '';
    }
  }, []);

  return (
    <Card
      animate={!option}
      style={{ display: displayCard }}
      colorButtonAprov={colorButtonAprov}
      colorButtonRemov={colorButtonRemov}
    >
      <ModalEditRota
        displayCardHide={() => {
          setDisplayCard('none');
        }}
        returnId={(idVisitTranfer) => {
          onRejeitar(idVisitTranfer);
        }}
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
        item={item}
      />
      <button className="card" type="button">
        <Line color={useColorLine(item.status || 'A iniciar')} />
        <div className="date">
          <p>{item.data.slice(8, 10)}</p>
          <span>{getMes(item.data)}</span>
        </div>
        <div className="info">
          <p>{`${item.Posto.name}`}</p>
          <div className="statusInfo">
            <div>
              <FiUser />
              <span>{nameSupervisor}</span>
            </div>
            <div>
              <FiCalendar />
              <span>{getDayWeek(item.data)}</span>
            </div>
            <div>
              <FiCheckCircle />
              <span>{item.status || 'A iniciar'}</span>
            </div>
            {item.Posto.turno ? (
              <div>
                {item.Posto.turno === 'Noturno' ? <FiMoon /> : <FiSun />}
                <span>{item.Posto.turno}</span>
              </div>
            ) : (
              <div>
                {item.turno === 'Noturno' ? <FiMoon /> : <FiSun />}
                <span>{item.turno}</span>
              </div>
            )}
          </div>
          <div className="local">
            <FiMapPin />
            <span>{`${item.Posto.endereco}`}</span>
          </div>
        </div>
        <div className="option">
          <button
            id="BtnEdit"
            type="button"
            onClick={() => {
              setIsShowModal(true);
              // if (onModal) {
              //   onModal();
              // }
            }}
          >
            <FiEdit color={colors.primary} />
          </button>
        </div>
        {option ? (
          <div className="option">
            <button
              id="BtnAprov"
              type="button"
              onClick={() => {
                onAprovar(item.id);
                setColorButtonAprov(true);
                setColorButtonRemov(false);
              }}
            >
              Aprovar
            </button>
            <button
              id="BtnRegect"
              type="button"
              onClick={(e) => {
                onRejeitar(item.id);
                setColorButtonAprov(false);
                setColorButtonRemov(true);
              }}
            >
              Rejeitar
            </button>
          </div>
        ) : (
          <div className="bool">
            <div className="can">
              <Tool title="Cancelado">
                <div />
              </Tool>
            </div>
            <div className="pen">
              <Tool title="A iniciar">
                <div />
              </Tool>
            </div>
            <div className="and">
              <Tool title="Andamento">
                <div />
              </Tool>
            </div>
            <div className="fin">
              <Tool title="Finalizado">
                <div />
              </Tool>
            </div>
            <div className="ina">
              <Tool title="Inacabado">
                <div />
              </Tool>
            </div>
          </div>
        )}
      </button>
    </Card>
  );
};

export default CardAgendamentoVisita;
