import styled from 'styled-components';

interface PropsContainerBackground {
  isVisible: boolean;
}

export const ContainerBackground = styled.div<PropsContainerBackground>`
  display: ${(props) => (!props.isVisible ? 'none' : 'flex')};
  background-color: grey;
  width: 100%;
  height: 100%;

  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;

  opacity: 0.5;
`;
export const ContainerModal = styled.div<PropsContainerBackground>`
  display: ${(props) => (!props.isVisible ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99;
  opacity: 1;
`;

export const BodyModal = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 90%;
  max-width: 800px;
  min-height: 250px;
  max-height: 80%;
  overflow: auto;

  margin: auto;

  border-radius: 8px;
`;
