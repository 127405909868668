import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { FiCalendar, FiPlus, FiUser, FiTrash } from 'react-icons/fi';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Tooltip, Zoom } from '@material-ui/core';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { ThemeContext } from 'styled-components';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import { SelectCustom } from '../../../components/SelectCustom';
import { Loading } from '../../../components/Loading';
import Button from '../../../components/Button';
import { ResponseGetEmpresas, ResponseGetUsuario } from '../index';
import {
  AnimationContainer,
  Container,
  CalendarButton,
  DotCalendar,
  ButtonPlus,
  ValSelectedButton,
  ButtonAdd,
  ButtonRem,
  CheckboxLabel,
  SpanError,
  CalendarDiv,
  CalendarMensal,
  DivRevision,
} from './styles';
import { InputOverview } from '../../../components/InputOverview';
import { Ronda } from '../../../models/Rondas';
import { AgendaRonda } from '../../../models/AgendaRonda';

// interface SignUpFormData {
//   empresa: string;
//   empresaLote: string;
//   usuarioAsg: string;
//   endTimefim: string;
//   rondas: string;
//   startTime: string;
// }

export interface ResponsePost {
  Id_Users: number[] | number;
  Id_Environments: number[];
  Id_Periodicidade: number[];
  Id_EmpresasFilial: number;
}

interface CadastroProps {
  dadosEmpresas: ResponseGetEmpresas[];
  dadosUsuarios: ResponseGetUsuario[];
  dadosRondas: Ronda[];
}

export interface SelectInterface {
  value: string;
  label: string;
}

interface PostAgenda {
  Ronda: {
    id: number;
  };
  userId: number;
  userId2?: number | string | null;
  type: string; // ex: sem = semanal, men = ano, null = sem periodicidade
  freqSem: string;
  freqSem2?: string;
  freqMes: string;
  freqAno: string;
  vigIni: string;
  vigFim: string;
  horaIni: string;
  horaFim: string;
  companyId: number;
  active: boolean;
  Revisoes: {
    horaIni: string;
    horaFim: string;
  }[];
}

interface TimeRev {
  start: string;
  end: string;
}

interface TimeIndexError {
  index: number;
  erro: string;
}

const optionsTypePeriodicidade: SelectInterface[] = [
  { label: 'Semanal', value: 'sem' },
  { label: 'Semana Alternada', value: '12X' },
  { label: 'Mensal', value: 'men' },
  { label: 'Anual', value: 'ano' },
];

const optionSemana: SelectInterface[] = [
  { label: 'D', value: '0' },
  { label: 'S', value: '1' },
  { label: 'T', value: '2' },
  { label: 'Q', value: '3' },
  { label: 'Q', value: '4' },
  { label: 'S', value: '5' },
  { label: 'S', value: '6' },
];

const optionMesAno: SelectInterface[] = [
  { label: 'Janeiro', value: 'Janeiro' },
  { label: 'Fevereiro', value: 'Fevereiro' },
  { label: 'Março', value: 'Março' },
  { label: 'Abril', value: 'Abril' },
  { label: 'Maio', value: 'Maio' },
  { label: 'Junho', value: 'Junho' },
  { label: 'Julho', value: 'Julho' },
  { label: 'Agosto', value: 'Agosto' },
  { label: 'Setembro', value: 'Setembro' },
  { label: 'Outubro', value: 'Outubro' },
  { label: 'Novembro', value: 'Novembro' },
  { label: 'Dezembro', value: 'Dezembro' },
];

export const Cadastro: React.FC<CadastroProps> = ({
  dadosEmpresas,
  dadosUsuarios,
  dadosRondas,
}) => {
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const formRefIndividual = useRef<FormHandles>(null);

  const [companyId, setCompanyId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [userOption, setUserOption] = useState('');
  const [userId2, setUserId2] = useState<number>(0);
  const [userOption2, setUserOption2] = useState('');
  const [rondaId, setRondaId] = useState<number>(0);
  const [typePeriodicidade, setTypePeriodicidade] = useState<SelectInterface>({
    label: 'Semanal',
    value: 'sem',
  });
  const [selectedCalendar, setSelectedCalendar] = useState<string[]>([]);
  const [selectedCalendar2, setSelectedCalendar2] = useState<string[]>([]);
  const [diaCalendar, setDiaCalendar] = useState(1);
  const [selectedDias, setSelectedDias] = useState<string[]>([]);
  const [mesCalendar, setMesCalendar] = useState<SelectInterface>(
    optionMesAno[0],
  );
  const [selectedMeses, setSelectedMeses] = useState<string[]>([]);
  const [startTime, setStartTime] = useState('');
  const [finishTime, setFinishTime] = useState('');
  const [timeRevision, setTimeRevision] = useState<TimeRev[]>([]);
  const [dateVigStart, setDateVigStart] = useState('');
  const [dateVigEnd, setDateVigEnd] = useState('');
  const [isRevisaoViraNoite, setIsRevisaoViraNoite] = useState(false);
  const [listErrorRevision, setListErrorRevision] = useState<TimeIndexError[]>(
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const freqSem = selectedCalendar
        .sort((a, b) => {
          if (Number(a) < Number(b)) return 1;
          if (Number(a) === Number(b)) return 0;
          return -1;
        })
        .join(' - ');
      const freqSem2 = selectedCalendar2
        .sort((a, b) => {
          if (Number(a) < Number(b)) return 1;
          if (Number(a) === Number(b)) return 0;
          return -1;
        })
        .join(' - ');
      const dataSubmit = {
        active: true,
        companyId,
        freqAno: selectedMeses.join(' - '),
        freqMes: selectedDias.join(' - '),
        freqSem,
        freqSem2,
        horaFim: finishTime,
        horaIni: startTime,
        rondaId,
        type: typePeriodicidade.value,
        userId,
        userId2,
        vigFim: dateVigEnd,
        vigIni: dateVigStart,
      };
      formRefIndividual.current?.setErrors({});
      const schema = Yup.object().shape({
        companyId: Yup.string().required('Empresa é obrigatória'),
        rondaId: Yup.string().required('Ronda é obrigatória'),
        userId: Yup.string().required('Colaborador é obrigatório'),
        type: Yup.string().required('Tipo da Periodicidade é obrigatório'),
        vigFim: Yup.string().required('data final vigente é obrigatório'),
        vigIni: Yup.string().required('data inicial vigente é obrigatório'),
        horaFim: Yup.string().required('hora final é obrigatório'),
        horaIni: Yup.string().required('hora inicial é obrigatório'),
      });

      await schema.validate(dataSubmit, {
        abortEarly: false,
      });

      const horIni = Number(dataSubmit.horaIni.split(':').join(''));
      const horFim = Number(dataSubmit.horaFim.split(':').join(''));
      if (horIni > horFim && !isRevisaoViraNoite) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'A hora inicial é maior que a hora final.',
        });
        return;
      }

      if (userId === 0) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione o usuário.',
        });
        return;
      }

      if (userId2 === 0 && dataSubmit.type === '12X') {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione o usuário 2.',
        });
        return;
      }

      if (companyId === 0) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione a filial.',
        });
        return;
      }

      if (rondaId === 0) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione a ronda.',
        });
        return;
      }

      if (dataSubmit.type === 'sem' && dataSubmit.freqSem === '') {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione frequência semanal.',
        });
        return;
      }

      if (dataSubmit.type === 'ano' && dataSubmit.freqAno === '') {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione a frequência anual.',
        });
        return;
      }

      if (dataSubmit.type === 'men' && dataSubmit.freqMes === '') {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Selecione a frequência mensal.',
        });
        return;
      }

      if (moment(moment().format('YYYY-MM-DD')).isAfter(dataSubmit.vigIni)) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'A data vigente inicial é menor que a data atual.',
        });
        return;
      }
      if (moment(dataSubmit.vigIni).isAfter(dataSubmit.vigFim)) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'A data vigente inicial é maior que a data vigente final.',
        });
        return;
      }

      const obj: PostAgenda = {
        active: dataSubmit.active,
        companyId: dataSubmit.companyId,
        freqAno: dataSubmit.freqAno,
        freqMes: dataSubmit.freqMes,
        freqSem: dataSubmit.freqSem,
        freqSem2: dataSubmit.type === '12X' ? dataSubmit.freqSem2 : '',
        horaFim: dataSubmit.horaFim,
        horaIni: dataSubmit.horaIni,
        type: dataSubmit.type,
        userId: dataSubmit.userId,
        userId2: dataSubmit.type === '12X' ? dataSubmit.userId2 : null,
        vigFim: dataSubmit.vigFim,
        vigIni: dataSubmit.vigIni,
        Ronda: {
          id: dataSubmit.rondaId,
        },
        Revisoes: timeRevision.map((item) => ({
          horaIni: item.start,
          horaFim: item.end,
        })),
      };

      // console.log(obj, isRevisaoViraNoite);

      await api
        .post('/agendarondas', obj)
        .then(async (resp: AxiosResponse) => {
          const respAgenda: AgendaRonda[] = resp.data;
          // console.log(respAgenda);
          // console.log(`/agendarondas/gerar?viraNoite=${isRevisaoViraNoite}`);
          respAgenda.forEach((itemAg) => {
            api
              .post(
                `/agendarondas/gerar?agendaId=${itemAg.id}&viraNoite=${isRevisaoViraNoite}`,
              )
              .then(() => {
                // console.log(resp);
                // console.log(resp.data);
                setLoading(false);
                addToast({
                  type: 'success',
                  title: 'Sucesso',
                  description:
                    'O agendamento foi cadastrado com sucesso e já pode ser visualizado no calendário',
                });
                setTimeout(() => {
                  go(0);
                }, 1000);
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  addToast({
                    type: 'error',
                    title: 'Erro',
                    description: `${err.response.data.message}`,
                  });
                } else {
                  addToast({
                    type: 'error',
                    title: 'Erro',
                    description:
                      'Ocorreu um erro ao cadastrar o agendamento, tente novamente',
                  });
                }
              });
          });

          setLoading(false);
        })
        .catch((err) => {
          // console.log('err', err.message);
          // console.log('err', err.response.data);
          if (err.response.status === 400) {
            addToast({
              type: 'error',
              title: 'Erro',
              description: `${err.response.data.message}`,
            });
          } else {
            addToast({
              type: 'error',
              title: 'Erro',
              description:
                'Ocorreu um erro ao cadastrar o agendamento, tente novamente',
            });
          }

          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        setLoading(false);
        const errors = getValidationErrors(err);

        formRefIndividual.current?.setErrors(errors);
      }

      addToast({
        type: 'error',
        title: 'Campos em branco',
        description: 'Existem campos obrigatórios vazios, favor preencher',
      });
    }
  }, [
    addToast,
    companyId,
    dateVigEnd,
    dateVigStart,
    finishTime,
    go,
    isRevisaoViraNoite,
    rondaId,
    selectedCalendar,
    selectedCalendar2,
    selectedDias,
    selectedMeses,
    startTime,
    timeRevision,
    typePeriodicidade.value,
    userId,
    userId2,
  ]);

  const handleSemanCalendar = useCallback(
    (val: string) => {
      if (selectedCalendar.includes(val)) {
        const data = selectedCalendar;
        setSelectedCalendar(data.filter((i) => i !== val));
      } else {
        setSelectedCalendar([...selectedCalendar, val]);
        setSelectedCalendar2(selectedCalendar2.filter((i) => i !== val));
      }
    },
    [selectedCalendar, selectedCalendar2],
  );

  const handleSemanCalendar2 = useCallback(
    (val: string) => {
      if (selectedCalendar2.includes(val)) {
        const data = selectedCalendar2;
        setSelectedCalendar2(data.filter((i) => i !== val));
      } else {
        setSelectedCalendar2([...selectedCalendar2, val]);
        setSelectedCalendar(selectedCalendar.filter((i) => i !== val));
      }
    },
    [selectedCalendar, selectedCalendar2],
  );

  const handleAddDia = useCallback(() => {
    const diaString =
      diaCalendar <= 9 ? `0${diaCalendar}` : String(diaCalendar);
    if (selectedDias.length === 0) {
      setSelectedDias([diaString]);
    } else {
      const newSelectDias: string[] = [];
      let aux = false;
      selectedDias.forEach((i) => {
        if (i === diaString) {
          newSelectDias.push(i);
          aux = true;
        } else if (Number(i) < diaCalendar) {
          newSelectDias.push(i);
        } else {
          if (!aux) {
            newSelectDias.push(diaString);
            aux = true;
          }
          newSelectDias.push(i);
        }
      });
      if (!aux) {
        newSelectDias.push(diaString);
      }
      setSelectedDias(newSelectDias);
    }
    setDiaCalendar(1);
  }, [diaCalendar, selectedDias]);

  const handleRemoveDia = useCallback(
    (item: string) => {
      const filter = selectedDias.filter((i) => i !== item);
      setSelectedDias(filter);
    },
    [selectedDias],
  );

  const getNumberMes = useCallback((name: string): string => {
    switch (name) {
      case 'Janeiro':
        return '01';
      case 'Fevereiro':
        return '02';
      case 'Março':
        return '03';
      case 'Abril':
        return '04';
      case 'Maio':
        return '05';
      case 'Junho':
        return '06';
      case 'Julho':
        return '07';
      case 'Agosto':
        return '08';
      case 'Setembro':
        return '09';
      case 'Outubro':
        return '10';
      case 'Novembro':
        return '11';
      case 'Dezembro':
        return '12';
      default:
        return '';
    }
  }, []);

  const handleAddMes = useCallback(() => {
    const numberMes = getNumberMes(mesCalendar.value);
    if (numberMes !== '' && diaCalendar) {
      let auxDia = diaCalendar;
      if (auxDia > 28 && numberMes === '02') {
        auxDia = 28;
      }
      if (
        auxDia > 30 &&
        ['04', '06', '09', '10'].find((i) => i === numberMes)
      ) {
        auxDia = 30;
      }
      const diaString = auxDia < 10 ? `0${auxDia}` : `${auxDia}`;
      const dateString = `${diaString}/${numberMes}`;
      const newSelectedMeses: string[] = [];
      let aux = false;
      selectedMeses.forEach((i) => {
        const [dia, mes] = i.split('/');
        if (!aux) {
          if (
            Number(mes) > Number(numberMes) ||
            (Number(dia) > auxDia && Number(mes) === Number(numberMes))
          ) {
            newSelectedMeses.push(...[dateString, i]);
            aux = true;
          } else if (i === dateString) {
            newSelectedMeses.push(i);
            aux = true;
          } else {
            newSelectedMeses.push(i);
          }
        } else {
          newSelectedMeses.push(i);
        }
      });
      if (!aux) {
        newSelectedMeses.push(dateString);
        aux = true;
      }
      setSelectedMeses(newSelectedMeses);
    }
  }, [diaCalendar, getNumberMes, mesCalendar.value, selectedMeses]);

  const handleRemoveMes = useCallback(
    (val: string) => {
      const filter = selectedMeses.filter((i) => i !== val);
      setSelectedMeses(filter);
    },
    [selectedMeses],
  );

  const handleHourStartRevision = useCallback(
    (valor: string, valorAnterior: string, index: number) => {
      const val = parseInt(valor.split(':').join(''), 10);
      const prin = parseInt(valorAnterior.split(':').join(''), 10);
      // console.log(val, prin, val > prin);
      if (val > prin || isRevisaoViraNoite) {
        timeRevision[index].start = valor;
        timeRevision[index].end = '';
        setTimeRevision([...timeRevision]);
        setListErrorRevision(
          listErrorRevision.filter((i) => i.index !== index),
        );
      } else {
        setListErrorRevision([
          ...listErrorRevision,
          {
            index,
            erro:
              'Horário menor que o horário anterior ou passou da meia noite. Se passar da meia noite, marque o checkbox "Revisão vira a noite"',
          },
        ]);
      }
    },
    [isRevisaoViraNoite, listErrorRevision, timeRevision],
  );

  const handleHourEndRevision = useCallback(
    (valor: string, index: number) => {
      const valStart = parseInt(
        timeRevision[index].start.split(':').join(''),
        10,
      );
      const valEnd = parseInt(valor.split(':').join(''), 10);
      if (valStart < valEnd) {
        timeRevision[index].end = valor;
        setTimeRevision([...timeRevision]);
        setListErrorRevision(
          listErrorRevision.filter((i) => i.index !== index),
        );
      } else {
        setListErrorRevision([
          ...listErrorRevision,
          {
            index,
            erro: 'Horário menor que o horário inicial.',
          },
        ]);
      }
    },
    [listErrorRevision, timeRevision],
  );

  const dataUsersMemo: ResponseGetUsuario[] = useMemo(() => {
    return dadosUsuarios.filter(
      (usuario) =>
        usuario.companyId === companyId &&
        !usuario.motoboy &&
        !usuario.supervisor &&
        !usuario.coordenador,
    );
  }, [companyId, dadosUsuarios]);

  const dataRondaMemo: Ronda[] = useMemo(() => {
    return dadosRondas.filter(
      (rond) => rond.companyId === companyId && rond.active,
    );
  }, [companyId, dadosRondas]);

  const titleRevision = useMemo((): string => {
    if (timeRevision.length === 0) return 'Sem revisões';
    if (timeRevision.length === 1) return '1 revisão';
    return `${timeRevision.length} revisões`;
  }, [timeRevision.length]);

  return (
    <section>
      <AnimationContainer>
        <Form ref={formRefIndividual} onSubmit={handleSubmit}>
          <p>Empresa</p>
          <SelectCustom
            name="empresa"
            defaultValue="Empresa"
            optionsDataCompany={dadosEmpresas}
            onValue={(e) => {
              setUserId(0);
              setRondaId(0);
              setCompanyId(parseInt(e.split('-')[0], 10));
            }}
          />

          <p>Ronda</p>
          <SelectCustom
            name="rondas"
            defaultValue="Rondas"
            type="environments"
            optionsDataEnvironments={dataRondaMemo}
            onValue={(e) => setRondaId(parseInt(e.split('-')[0], 10))}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              columnGap: 10,
            }}
          >
            <p>Periodicidade</p>
            <Tooltip
              title="A opção SEMANAL é a escolha dos dias da semana de periodicidade. A opção ALTERNADA é a escolha de dois colaboradores que alternam entre as semanas, semana 1 para o primeiro vigilante, semana 2 para o segundo e depois alterna. MENSAL é a escolhe em dias do mês. E ANUAL é a escolha dos dias no ano."
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle color={colors.black2} size={20} />
              </span>
            </Tooltip>
          </div>
          <div style={{ color: colors.black2 }}>
            <Select
              name="selectedTypePeriodicidade"
              options={optionsTypePeriodicidade}
              // menuPortalTarget={document.querySelector('body')}
              placeholder="Selecione o tipo da Periodicade..."
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                // console.log(e);
                if (e) {
                  setTypePeriodicidade({
                    label: e.label,
                    value: e.value,
                  });
                }
              }}
            />
          </div>
          {typePeriodicidade.value === 'sem' && (
            <CalendarDiv>
              <CalendarButton type="button">
                <FiCalendar />
                <span>Calendário</span>
              </CalendarButton>
              <div className="sem">
                {optionSemana.map((item, index) => (
                  <DotCalendar
                    key={index.toString()}
                    selected={selectedCalendar.includes(item.value)}
                    type="button"
                    onClick={() => handleSemanCalendar(item.value)}
                  >
                    {item.label}
                  </DotCalendar>
                ))}
              </div>
            </CalendarDiv>
          )}
          {typePeriodicidade.value === '12X' && (
            <>
              <CalendarDiv>
                <CalendarButton type="button">
                  <FiUser />
                  <span>
                    {userId !== 0
                      ? dataUsersMemo.find((item) => item.id === userId)?.nome
                      : 'Colaborador 1'}
                  </span>
                </CalendarButton>
                <div className="sem">
                  {optionSemana.map((item, index) => (
                    <DotCalendar
                      key={index.toString()}
                      selected={selectedCalendar.includes(item.value)}
                      type="button"
                      onClick={() => handleSemanCalendar(item.value)}
                    >
                      {item.label}
                    </DotCalendar>
                  ))}
                </div>
              </CalendarDiv>
              <CalendarDiv>
                <CalendarButton type="button">
                  <FiUser />
                  <span>
                    {userId2 !== 0
                      ? dataUsersMemo.find((item) => item.id === userId2)?.nome
                      : 'Colaborador 2'}
                  </span>
                </CalendarButton>
                <div className="sem">
                  {optionSemana.map((item, index) => (
                    <DotCalendar
                      key={index.toString()}
                      selected={selectedCalendar2.includes(item.value)}
                      type="button"
                      onClick={() => handleSemanCalendar2(item.value)}
                    >
                      {item.label}
                    </DotCalendar>
                  ))}
                </div>
              </CalendarDiv>
            </>
          )}
          {typePeriodicidade.value === 'men' && (
            <CalendarMensal>
              <p>Selecione os dias</p>
              <div className="info">
                <div className="divButton">
                  <div className="input">
                    <input
                      name="diaCalendar"
                      type="number"
                      value={diaCalendar}
                      onChange={(e) => {
                        try {
                          const val = Number(e.target.value);
                          if (val > 0 && val <= 31) {
                            setDiaCalendar(val);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    />
                  </div>
                  <ButtonPlus type="button" onClick={handleAddDia}>
                    <FiPlus size={22} />
                  </ButtonPlus>
                </div>
                <ValSelectedButton>
                  {selectedDias.map((item, index) => {
                    if (selectedDias.length === index + 1) {
                      return (
                        <button
                          key={index.toString()}
                          type="button"
                          onClick={() => handleRemoveDia(item)}
                        >
                          <p>{item}</p>
                        </button>
                      );
                    }
                    return (
                      <div style={{ display: 'flex' }} key={index.toString()}>
                        <button
                          type="button"
                          onClick={() => handleRemoveDia(item)}
                        >
                          <p>{item}</p>
                        </button>
                        <p style={{ marginLeft: 10 }}>-</p>
                      </div>
                    );
                  })}
                </ValSelectedButton>
              </div>
            </CalendarMensal>
          )}
          {typePeriodicidade.value === 'ano' && (
            <CalendarMensal>
              <p>Adicione as datas</p>
              <div className="info">
                <div className="divButton">
                  <div className="input">
                    <input
                      name="diaCalendar"
                      type="number"
                      value={diaCalendar}
                      onChange={(e) => {
                        try {
                          const val = Number(e.target.value);
                          if (val > 0 && val <= 31) {
                            setDiaCalendar(val);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    />
                  </div>
                  <p
                    style={{
                      paddingRight: 10,
                      paddingLeft: 10,
                      margin: 0,
                      marginTop: 8,
                    }}
                  >
                    de
                  </p>
                  <div
                    style={{
                      flex: 2,
                      color: colors.black2,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <Select
                      options={optionMesAno}
                      value={mesCalendar}
                      onChange={(e) => {
                        if (e) {
                          setMesCalendar(e);
                        }
                      }}
                    />
                  </div>
                  <ButtonPlus type="button" onClick={handleAddMes}>
                    <FiPlus size={22} />
                  </ButtonPlus>
                </div>
                <div style={{ width: 80 }} />
                <div style={{ flex: 1 }}>
                  <ValSelectedButton>
                    {selectedMeses.map((item, index) => {
                      if (selectedMeses.length === index + 1) {
                        return (
                          <button
                            key={index.toString()}
                            type="button"
                            onClick={() => handleRemoveMes(item)}
                          >
                            <p>{item}</p>
                          </button>
                        );
                      }
                      return (
                        <div style={{ display: 'flex' }} key={index.toString()}>
                          <button
                            type="button"
                            onClick={() => handleRemoveMes(item)}
                          >
                            <p>{item}</p>
                          </button>
                          <p style={{ marginLeft: 10 }}>-</p>
                        </div>
                      );
                    })}
                  </ValSelectedButton>
                </div>
              </div>
            </CalendarMensal>
          )}

          <p style={{ marginTop: 20 }}>Colaborador</p>
          <SelectCustom
            name="usuarioAsg"
            defaultValue="Colaborador"
            type="users"
            value={userOption}
            optionsDataUsers={dataUsersMemo}
            onValue={(e) => {
              setUserOption(e);
              const value = parseInt(e.split('-')[0], 10);
              setUserId(value);
              if (value === userId2) {
                setUserId2(0);
                setUserOption2('');
              }
            }}
          />

          {typePeriodicidade.value === '12X' && (
            <>
              <p>Colaborador 2</p>
              <SelectCustom
                name="usuarioAsg2"
                defaultValue="Colaborador"
                type="users"
                value={userOption2}
                optionsDataUsers={dataUsersMemo}
                onValue={(e) => {
                  setUserOption2(e);
                  const value = parseInt(e.split('-')[0], 10);
                  setUserId2(value);
                  if (value === userId) {
                    setUserId(0);
                    setUserOption('');
                  }
                }}
              />
            </>
          )}

          <Container>
            <section>
              <p>Horario de Ronda</p>
              <aside
                style={{
                  paddingBottom: 0,
                  borderBottom: 0,
                  marginBottom: 0,
                }}
              >
                <article>
                  <h3>Horário inicial</h3>
                  <InputOverview
                    name="startTime"
                    type="time"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                </article>

                <article>
                  <h3>Horário final</h3>
                  <InputOverview
                    name="endTimefim"
                    type="time"
                    value={finishTime}
                    onChange={(e) => {
                      setFinishTime(e.target.value);
                    }}
                  />
                </article>
              </aside>
            </section>
            <section>
              <DivRevision>
                <div className="add">
                  <p style={{ marginBottom: 0 }}>Revisões - {titleRevision}</p>
                  <ButtonAdd
                    type="button"
                    onClick={() => {
                      setTimeRevision([
                        ...timeRevision,
                        { start: '', end: '' },
                      ]);
                    }}
                  >
                    <FiPlus />
                  </ButtonAdd>
                </div>
                <CheckboxLabel>
                  <div>
                    <input
                      type="checkbox"
                      checked={isRevisaoViraNoite}
                      onChange={() => {
                        setIsRevisaoViraNoite(!isRevisaoViraNoite);
                      }}
                    />
                    <span>Revisão vira a noite</span>
                  </div>
                </CheckboxLabel>
              </DivRevision>
              {timeRevision.length > 0 && (
                <aside
                  style={{
                    paddingBottom: 0,
                    borderBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  <h3>Horário inicial da revisão</h3>
                  <h3>Horário final da revisão</h3>
                </aside>
              )}
              {timeRevision.map((item, index) => {
                return (
                  <>
                    <aside
                      key={index.toString()}
                      style={{
                        paddingBottom: 0,
                        borderBottom: 0,
                        marginBottom: 10,
                        alignItems: 'center',
                      }}
                    >
                      <article>
                        <InputOverview
                          name={`startRevTime${index}`}
                          type="time"
                          value={item.start}
                          onChange={(e) => {
                            if (index === 0) {
                              handleHourStartRevision(
                                e.target.value,
                                finishTime,
                                0,
                              );
                            } else {
                              handleHourStartRevision(
                                e.target.value,
                                timeRevision[index - 1].end,
                                index,
                              );
                            }
                          }}
                        />
                      </article>

                      <article>
                        <InputOverview
                          name={`endRevTime${index}`}
                          type="time"
                          value={item.end}
                          onChange={(e) => {
                            handleHourEndRevision(e.target.value, index);
                          }}
                        />
                      </article>
                      <ButtonRem
                        type="button"
                        onClick={() => {
                          setTimeRevision(
                            timeRevision.filter((_, i) => i !== index),
                          );
                          setListErrorRevision(
                            listErrorRevision
                              .filter((i) => i.index !== index)
                              .map((i) => {
                                if (i.index < index) return i;
                                return {
                                  index: i.index - 1,
                                  erro: i.erro,
                                };
                              }),
                          );
                        }}
                      >
                        <FiTrash />
                      </ButtonRem>
                    </aside>
                    {listErrorRevision.find((i) => i.index === index) && (
                      <SpanError>
                        <span>
                          {
                            listErrorRevision.find((i) => i.index === index)
                              ?.erro
                          }
                        </span>
                      </SpanError>
                    )}
                  </>
                );
              })}
            </section>
          </Container>
          <Container>
            <section>
              <p>Data Vigente</p>
              <aside
                style={{
                  paddingBottom: 0,
                  borderBottom: 0,
                  marginBottom: 0,
                }}
              >
                <article>
                  <h3>Data inicial</h3>
                  <InputOverview
                    name="startTime"
                    type="date"
                    value={dateVigStart}
                    onChange={(e) => {
                      setDateVigStart(e.target.value);
                    }}
                  />
                </article>

                <article>
                  <h3>Data final</h3>
                  <InputOverview
                    name="endTimefim"
                    type="date"
                    value={dateVigEnd}
                    onChange={(e) => {
                      setDateVigEnd(e.target.value);
                    }}
                  />
                </article>
              </aside>
            </section>
          </Container>
          <Button type="submit">Salvar</Button>
        </Form>
      </AnimationContainer>
      {loading && <Loading />}
    </section>
  );
};
