import React, { Dispatch, SetStateAction } from 'react';
import { Container } from './styled';

interface Props {
  periodicidade: string[];
  setPeriodicidade: Dispatch<SetStateAction<string[]>>;
}

const daysWeek = [
  { name: 'D', value: 0 },
  { name: 'S', value: 1 },
  { name: 'T', value: 2 },
  { name: 'Q', value: 3 },
  { name: 'Q', value: 4 },
  { name: 'S', value: 5 },
  { name: 'S', value: 6 },
];

const DaysWeek: React.FC<Props> = ({ setPeriodicidade, periodicidade }) => {
  // console.log(periodicidade);
  // const [disableButtons, setDisableButtons] = useState(false);

  const handleSelectButtom = (value: string): void => {
    const valueInt = value;

    if (periodicidade.indexOf(valueInt) === -1) {
      const add = [...periodicidade, valueInt];
      setPeriodicidade(add);
    } else {
      const rem = periodicidade.filter((e) => e !== valueInt);
      setPeriodicidade(rem.sort());
    }
  };
  // useEffect(() => {
  //   // restringeSelect(periodicidadeOption);
  // }, [periodicidadeOption]);

  return (
    <Container>
      <div className="daysWeek">
        {daysWeek.map((element, index) => {
          return (
            <button
              key={index.toString()}
              id={index.toString()}
              type="button"
              value={element.value}
              onClick={({ currentTarget }) => {
                handleSelectButtom(currentTarget.value);
              }}
              // disabled={disableButtons}
              className={
                periodicidade.indexOf(index.toString()) !== -1 ? 'selected' : ''
              }
            >
              <h3>{element.name}</h3>
            </button>
          );
        })}
      </div>
    </Container>
  );
};

export default DaysWeek;
