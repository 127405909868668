import { useState } from 'react';
import { UsuarioBD } from '../../models/User';
import api from '../../services/api';

export const searchSupervisor = (
  dado: number,
  responseSupervisores: UsuarioBD[],
): string => {
  let result = '-';
  responseSupervisores.forEach((element: { id: number; nome: string }) => {
    if (element.id === dado) {
      result = element.nome;
    }
  });

  return result;
};
