import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { InputOverview } from '../InputOverview';
import MapGoogleMaps from '../MapGoogleMaps';
import { Loading } from '../Loading';

import getValidationErrors from '../../utils/getValidationErrors';
import {
  ButtonForm,
  Container,
  ContainerButtonForm,
  ContainerModal,
  LabelInputForm,
  Title,
} from './styles';

export interface LocalIzation {
  endereco: string;
  cepAddress: string;
  countyAddress: string;
  districtAddress: string;
  latitudeAddress: string;
  longitudeAddress: string;
  numberAddress: string;
  stateAddress: string;
  streetAddress: string;
}

interface SubmitForm {
  name: string;
  obs: string;
  motivo: string;
}

interface PostIntercorrencia {
  rotaId: string;
  supervisorId: number;
  empresaFilialId: number;
  justificativa: string;
  observacao: string;
  visita: {
    status: 'Aprovada' | 'Rejeitada';
    turno: 'Diurno' | 'Noturno';
    posto: {
      name: string;
      endereco: string;
      latitude: string;
      longitude: string;
    };
  };
}

interface Props {
  onClose(): void;
  rotaId: string;
  empresaFilialId: number;
  supervisorID: number;
  turno: 'Diurno' | 'Noturno';
}

export const ModalIntecorrencia: React.FC<Props> = ({
  onClose,
  rotaId,
  empresaFilialId,
  supervisorID,
  turno,
}) => {
  const { addToast } = useToast();
  const { go } = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [responseLocalization, setResponseLocalization] = useState<
    LocalIzation
  >();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (dados: SubmitForm) => {
      try {
        setLoading(true);

        if (responseLocalization) {
          const obj = {
            name: dados.name,
            endereco: responseLocalization.endereco,
            numero:
              responseLocalization.numberAddress === 'NE'
                ? ''
                : responseLocalization.numberAddress,
            motivo: dados.motivo,
          };

          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório !'),
            endereco: Yup.string().required('Endereço obrigatório !'),
            numero: Yup.string().required(
              'Número precisa ser informado no endereço',
            ),
            motivo: Yup.string().required('Motivo obrigatório !'),
          });

          await schema.validate(obj, {
            abortEarly: false,
          });

          const objPost: PostIntercorrencia = {
            empresaFilialId,
            justificativa: obj.motivo,
            observacao: dados.obs,
            rotaId,
            supervisorId: supervisorID,
            visita: {
              posto: {
                endereco: `${responseLocalization.streetAddress} N°${responseLocalization.numberAddress}, ${responseLocalization.districtAddress}, ${responseLocalization.countyAddress} - ${responseLocalization.stateAddress}`,
                latitude: responseLocalization.latitudeAddress,
                longitude: responseLocalization.longitudeAddress,
                name: dados.name,
              },
              status: 'Aprovada',
              turno,
            },
          };

          await api
            .post('/rotas/intercorrencia', objPost)
            .then(() => {
              setLoading(false);
              addToast({
                type: 'success',
                title: 'Cadastro realizado!',
                description: 'A intercorrência foi adicionada na rota',
              });

              setTimeout(() => {
                go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: 'Erro ao cadastrar!',
                description: 'Verifique os dados!',
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro no Cadastro',
            description: 'Ocorreu um erro ao escolher o endereço do local.',
          });
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro no Cadastro',
            description: err.message,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro no Cadastro',
            description:
              'Ocorreu um erro ao fazer o Cadastro, Por favor verifique os campos.',
          });
        }
      }
    },
    [
      addToast,
      empresaFilialId,
      go,
      responseLocalization,
      rotaId,
      supervisorID,
      turno,
    ],
  );

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <>
      <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
        <Container>
          <Title>Cadastrar intercorrência</Title>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <LabelInputForm>Nome do Posto</LabelInputForm>
            <InputOverview
              type="text"
              id="name"
              placeholder="Digite aqui"
              name="name"
            />

            <LabelInputForm>Localização</LabelInputForm>
            <MapGoogleMaps
              setAdreesValue={setResponseLocalization}
              adreesValue={responseLocalization}
            />

            <LabelInputForm>Motivo</LabelInputForm>
            <InputOverview
              type="text"
              id="motivo"
              placeholder="Digite aqui"
              name="motivo"
            />

            <LabelInputForm>Observação</LabelInputForm>
            <InputOverview
              type="text"
              id="obs"
              placeholder="Digite aqui"
              name="obs"
            />

            <ContainerButtonForm>
              <ButtonForm background="#FFFFFF" type="button" onClick={onClose}>
                Cancelar
              </ButtonForm>
              <ButtonForm background="#393B41" type="submit">
                Salvar
              </ButtonForm>
            </ContainerButtonForm>
          </Form>
        </Container>
      </ContainerModal>
      {loading && <Loading />}
    </>
  );
};
