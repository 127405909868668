import React from 'react';
import logoImg from '../../assets/logo.png';
import {
  Container,
  ContainerImg,
  ContainerBody,
  ContainerTexto,
} from './styles';

const TermoConsentimento: React.FC = () => {
  return (
    <Container>
      <ContainerImg>
        <img src={logoImg} alt="Logo Servnac" />
      </ContainerImg>
      <h2>Termo de Consentimento</h2>
      <ContainerBody>
        <ContainerTexto>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
          <p>
            Configurando Android Studio A primeira janela a ser apresentada deve
            ser perguntando sobre a importação de configurações de outro Android
            Selecione a opção Do not import settings e clique em OK. Em seguida,
            o Android Studio começará a carregar.
          </p>
          <p>
            Em algum ponto do carregamento, será apresentada uma janela sobre
            compartilhamento de dados anônimos com a Google. Essa opção é
            pessoal, escolha o que preferir.
          </p>
          <p>
            Após o carregamento terminar, deve aparecer uma página de Welcome.
          </p>
          <p>
            Clique em sequência, será pedido o tipo de instalação. Escolha a
            opção Custom e clique em Next. Nesse momento, será pedido para
            escolher a localização do pacote JDK instalado. Clique em ⬇ e
            escolha a opção JAVA_HOME. Verifique se ela está apontando para a
            JDK 8. Deve ser a mesma da JAVA_HOME configurada na seção
            Preparativos para o Android Studio (ex.:
          </p>
        </ContainerTexto>
      </ContainerBody>
    </Container>
  );
};

export default TermoConsentimento;
