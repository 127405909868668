export const returnNameMotivos = (
  stringIndex: string | undefined,
  motivos: any[] | undefined,
): string | undefined => {
  if (motivos?.length !== 0) {
    const nameMotivos = motivos?.find((e?) => e.id === stringIndex);
    return nameMotivos?.ABN_DESC;
  }
  return `${stringIndex}-`;
};
