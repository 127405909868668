import React, {
  FormEvent,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
// import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import * as Yup from 'yup';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
// import { ThemeContext } from 'styled-components';
import { FiCalendar, FiPlus, FiTrash, FiUser } from 'react-icons/fi';
import moment from 'moment';
import { FormHandles } from '@unform/core';
import { ThemeContext } from 'styled-components';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
// import { maskCpf } from '../../../components/InputOverview/mask';
import Button from '../../../components/Button';
// import { ResponseGet } from '../index';
import { SelectInterface } from '../Cadastro';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  ButtonClose,
  Content,
  DivInput,
  ButtonAdd,
  ButtonRem,
  CalendarDiv,
} from './styles';
import { Ronda } from '../../../models/Rondas';
import { AgendaRonda, Revision } from '../../../models/AgendaRonda';
import NewModal from '../../../components/NewModal';
// import { SelectCustom } from '../../../components/SelectCustom';
import {
  ButtonPlus,
  CalendarButton,
  CalendarMensal,
  DotCalendar,
  ValSelectedButton,
} from '../Cadastro/styles';

const optionsTypePeriodicidade: SelectInterface[] = [
  { label: 'Semanal', value: 'sem' },
  { label: 'Mensal', value: 'men' },
  { label: 'Anual', value: 'ano' },
];

const optionsTypePeriodicidade12X: SelectInterface[] = [
  { label: 'Semana Alternada', value: '12X' },
];

const optionMesAno: SelectInterface[] = [
  { label: 'Janeiro', value: 'Janeiro' },
  { label: 'Fevereiro', value: 'Fevereiro' },
  { label: 'Março', value: 'Março' },
  { label: 'Abril', value: 'Abril' },
  { label: 'Maio', value: 'Maio' },
  { label: 'Junho', value: 'Junho' },
  { label: 'Julho', value: 'Julho' },
  { label: 'Agosto', value: 'Agosto' },
  { label: 'Setembro', value: 'Setembro' },
  { label: 'Outubro', value: 'Outubro' },
  { label: 'Novembro', value: 'Novembro' },
  { label: 'Dezembro', value: 'Dezembro' },
];

const optionSemana: SelectInterface[] = [
  { label: 'D', value: '0' },
  { label: 'S', value: '1' },
  { label: 'T', value: '2' },
  { label: 'Q', value: '3' },
  { label: 'Q', value: '4' },
  { label: 'S', value: '5' },
  { label: 'S', value: '6' },
];

interface ModalProps {
  isOpen: boolean;
  // data: AgendaRonda[];
  agenda: AgendaRonda;
  dadosSemana: string;
  dadosRondas: Ronda[];
  // dadosPeriodicidades: ResponseGetPeriodicidade[];
  onRequestClose: () => void;
}

export function ModalEditScheduling({
  isOpen,
  // data,
  dadosSemana,
  agenda,
  dadosRondas,
  // dadosPeriodicidades,
  onRequestClose,
}: ModalProps): JSX.Element {
  // console.log('SDA', data);
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);
  const formRefIndividual = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [rondaSel, setRondaSel] = useState<SelectInterface>({
    label: '',
    value: '',
  });
  const [typePeriodicidade, setTypePeriodicidade] = useState<SelectInterface>({
    label: '',
    value: '',
  });
  const [selectedCalendar, setSelectedCalendar] = useState<string[]>([]);
  const [selectedCalendar2, setSelectedCalendar2] = useState<string[]>([]);
  const [diaCalendar, setDiaCalendar] = useState(1);
  const [selectedDias, setSelectedDias] = useState<string[]>([]);
  const [mesCalendar, setMesCalendar] = useState<SelectInterface>(
    optionMesAno[0],
  );
  const [selectedMeses, setSelectedMeses] = useState<string[]>([]);
  const [startTime, setStartTime] = useState(agenda.horaIni);
  const [finishTime, setFinishTime] = useState(agenda.horaFim);
  const [dateVigStart, setDateVigStart] = useState(agenda.vigIni);
  const [dateVigEnd, setDateVigEnd] = useState(agenda.vigFim);
  const [timeRevision, setTimeRevision] = useState<Revision[]>([]);

  useEffect(() => {
    setSelectedCalendar(agenda.freqSem.split(' - '));
    setRondaSel({
      label: agenda.Ronda.name,
      value: String(agenda.Ronda.id),
    });
    if (agenda.freqMes) {
      setSelectedDias(agenda.freqMes.split(' - '));
    }
    if (agenda.freqAno) {
      setSelectedMeses(agenda.freqAno.split(' - '));
    }
    if (agenda.freqSem2) {
      setSelectedCalendar2(agenda.freqSem2.split(' - '));
    }
    if (agenda.type === '12X') {
      setTypePeriodicidade({ label: 'Semana Alternada', value: '12X' });
    } else {
      const find = optionsTypePeriodicidade.find(
        (item) => item.value === agenda.type,
      );
      if (find) {
        setTypePeriodicidade(find);
      } else {
        setTypePeriodicidade({ label: 'Semanal', value: 'sem' });
      }
    }
    setTimeRevision(agenda.Revisoes ? agenda.Revisoes : []);
  }, [
    agenda.Revisoes,
    agenda.Ronda.id,
    agenda.Ronda.name,
    agenda.freqAno,
    agenda.freqMes,
    agenda.freqSem,
    agenda.freqSem2,
    agenda.type,
  ]);

  const handleSemanCalendar = useCallback(
    (val: string) => {
      if (selectedCalendar.includes(val)) {
        const days = selectedCalendar;
        setSelectedCalendar(days.filter((i) => i !== val));
      } else {
        setSelectedCalendar([...selectedCalendar, val]);
        setSelectedCalendar2(selectedCalendar2.filter((i) => i !== val));
      }
    },
    [selectedCalendar, selectedCalendar2],
  );

  const handleSemanCalendar2 = useCallback(
    (val: string) => {
      if (selectedCalendar2.includes(val)) {
        const days = selectedCalendar2;
        setSelectedCalendar2(days.filter((i) => i !== val));
      } else {
        setSelectedCalendar2([...selectedCalendar2, val]);
        setSelectedCalendar(selectedCalendar.filter((i) => i !== val));
      }
    },
    [selectedCalendar, selectedCalendar2],
  );

  const handleAddDia = useCallback(() => {
    const diaString =
      diaCalendar <= 9 ? `0${diaCalendar}` : String(diaCalendar);
    if (selectedDias.length === 0) {
      setSelectedDias([diaString]);
    } else {
      const newSelectDias: string[] = [];
      let aux = false;
      selectedDias.forEach((i) => {
        if (i === diaString) {
          newSelectDias.push(i);
          aux = true;
        } else if (Number(i) < diaCalendar) {
          newSelectDias.push(i);
        } else {
          if (!aux) {
            newSelectDias.push(diaString);
            aux = true;
          }
          newSelectDias.push(i);
        }
      });
      if (!aux) {
        newSelectDias.push(diaString);
      }
      setSelectedDias(newSelectDias);
    }
    setDiaCalendar(1);
  }, [diaCalendar, selectedDias]);

  const handleRemoveDia = useCallback(
    (item: string) => {
      const filter = selectedDias.filter((i) => i !== item);
      setSelectedDias(filter);
    },
    [selectedDias],
  );

  const getNumberMes = useCallback((name: string): string => {
    switch (name) {
      case 'Janeiro':
        return '01';
      case 'Fevereiro':
        return '02';
      case 'Março':
        return '03';
      case 'Abril':
        return '04';
      case 'Maio':
        return '05';
      case 'Junho':
        return '06';
      case 'Julho':
        return '07';
      case 'Agosto':
        return '08';
      case 'Setembro':
        return '09';
      case 'Outubro':
        return '10';
      case 'Novembro':
        return '11';
      case 'Dezembro':
        return '12';
      default:
        return '';
    }
  }, []);

  const handleAddMes = useCallback(() => {
    const numberMes = getNumberMes(mesCalendar.value);
    if (numberMes !== '' && diaCalendar) {
      let auxDia = diaCalendar;
      if (auxDia > 28 && numberMes === '02') {
        auxDia = 28;
      }
      if (
        auxDia > 30 &&
        ['04', '06', '09', '10'].find((i) => i === numberMes)
      ) {
        auxDia = 30;
      }
      const diaString = auxDia < 10 ? `0${auxDia}` : `${auxDia}`;
      const dateString = `${diaString}/${numberMes}`;
      const newSelectedMeses: string[] = [];
      let aux = false;
      selectedMeses.forEach((i) => {
        const [dia, mes] = i.split('/');
        if (!aux) {
          if (
            Number(mes) > Number(numberMes) ||
            (Number(dia) > auxDia && Number(mes) === Number(numberMes))
          ) {
            newSelectedMeses.push(...[dateString, i]);
            aux = true;
          } else if (i === dateString) {
            aux = true;
            newSelectedMeses.push(i);
          } else {
            newSelectedMeses.push(i);
          }
        } else {
          newSelectedMeses.push(i);
        }
      });
      if (!aux) {
        newSelectedMeses.push(dateString);
        aux = true;
      }
      setSelectedMeses(newSelectedMeses);
    }
  }, [diaCalendar, getNumberMes, mesCalendar.value, selectedMeses]);

  const handleRemoveMes = useCallback(
    (val: string) => {
      const filter = selectedMeses.filter((i) => i !== val);
      setSelectedMeses(filter);
    },
    [selectedMeses],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const freqSem = selectedCalendar
          .sort((a, b) => {
            if (Number(a) < Number(b)) return 1;
            if (Number(a) === Number(b)) return 0;
            return -1;
          })
          .join(' - ');

        const freqSem2 = selectedCalendar2
          .sort((a, b) => {
            if (Number(a) < Number(b)) return 1;
            if (Number(a) === Number(b)) return 0;
            return -1;
          })
          .join(' - ');

        const dataSubmit = {
          id: agenda.id,
          rondaId: Number(rondaSel.value),
          active: true,
          companyId: agenda.companyId,
          freqAno: selectedMeses.join(' - '),
          freqMes: selectedDias.join(' - '),
          freqSem,
          freqSem2: typePeriodicidade.value === '12X' ? freqSem2 : '',
          horaFim: finishTime,
          horaIni: startTime,
          type: typePeriodicidade.value,
          userId: agenda.userId,
          userId2: agenda.userId2,
          vigFim: dateVigEnd,
          vigIni: dateVigStart,
          Revisoes: timeRevision,
        };

        const schema = Yup.object().shape({
          rondaId: Yup.string().required('Ronda é obrigatória'),
          type: Yup.string().required('Tipo da Periodicidade é obrigatório'),
          vigFim: Yup.string().required('data final vigente é obrigatório'),
          vigIni: Yup.string().required('data inicial vigente é obrigatório'),
          horaFim: Yup.string().required('hora final é obrigatório'),
          horaIni: Yup.string().required('hora inicial é obrigatório'),
        });
        formRefIndividual.current?.setErrors({});
        await schema.validate(dataSubmit, {
          abortEarly: false,
        });

        const horIni = Number(dataSubmit.horaIni.split(':').join(''));
        const horFim = Number(dataSubmit.horaFim.split(':').join(''));
        if (horIni > horFim) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'A hora inicial é maior que a hora final.',
          });
          return;
        }

        if (dataSubmit.rondaId === 0) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione a ronda.',
          });
          return;
        }

        if (dataSubmit.type === 'sem' && dataSubmit.freqSem === '') {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione frequência semanal.',
          });
          return;
        }

        if (dataSubmit.type === 'ano' && dataSubmit.freqAno === '') {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione a frequência anual.',
          });
          return;
        }

        if (dataSubmit.type === 'men' && dataSubmit.freqMes === '') {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione a frequência mensal.',
          });
          return;
        }

        if (
          moment(moment(agenda.vigIni).format('YYYY-MM-DD')).isAfter(
            dataSubmit.vigIni,
          )
        ) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'A data vigente inicial é menor que a data anterior.',
          });
          return;
        }
        if (moment(dataSubmit.vigIni).isAfter(dataSubmit.vigFim)) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description:
              'A data vigente inicial é maior que a data vigente final.',
          });
          return;
        }
        setLoading(true);

        await api
          .put('/agendarondas', dataSubmit)
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Sucesso',
              description:
                'O agendamento foi cadastrado com sucesso e já pode ser visualizado no calendário',
            });
            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response.data);

            if (err.response.status === 400) {
              addToast({
                type: 'error',
                title: 'Erro',
                description: `${err.response.data.message}`,
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro',
                description:
                  'Ocorreu um erro ao cadastrar o agendamento, tente novamente',
              });
            }
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Campos em branco',
          description: 'Existem campos obrigatórios vazios, favor preencher',
        });
      }
    },
    [
      addToast,
      agenda.companyId,
      agenda.id,
      agenda.userId,
      agenda.userId2,
      agenda.vigIni,
      dateVigEnd,
      dateVigStart,
      finishTime,
      go,
      rondaSel.value,
      selectedCalendar,
      selectedCalendar2,
      selectedDias,
      selectedMeses,
      startTime,
      timeRevision,
      typePeriodicidade.value,
    ],
  );

  const handleSubmitDelete = useCallback(
    (idAgend: number): void => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = { id: idAgend };
          api
            .delete('/agendarondas ', { data: obj })
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Agenda deleteda com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              // console.log('RESP', resp.data);
              setTimeout(() => {
                go(0);
              }, 1000);
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro ao deletar Agenda!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              console.log('ERRR', err.message);
            });
        }
      });
      //
    },
    [colors.error, colors.success, go],
  );

  const rondaMemo = useMemo(() => {
    return dadosRondas.filter((item) => item.companyId === agenda.companyId);
  }, [agenda.companyId, dadosRondas]);

  const titleRevision = useMemo((): string => {
    if (timeRevision.length === 0) return 'Sem revisões';
    if (timeRevision.length === 1) return '1 revisão';
    return `${timeRevision.length} revisões`;
  }, [timeRevision.length]);

  return (
    <NewModal isVisible={isOpen}>
      <ButtonClose>
        <button type="button" onClick={() => onRequestClose()}>
          <AiOutlineClose size={22} />
        </button>
      </ButtonClose>
      <Container onSubmit={handleSubmit}>
        <header>
          <h1>
            {agenda.id}. Agenda - {dadosSemana}
          </h1>
        </header>

        <p>Ronda</p>
        <div style={{ color: colors.black2 }}>
          <Select
            name="selectedRonda"
            options={rondaMemo.map((i) => {
              const sel: SelectInterface = {
                label: i.name,
                value: String(i.id),
              };
              return sel;
            })}
            placeholder="Selecione o tipo da Periodicade..."
            className="basic-multi-select"
            classNamePrefix="select"
            value={rondaSel}
            onChange={(e) => {
              if (e) {
                setRondaSel({
                  label: e.label,
                  value: e.value,
                });
              }
            }}
          />
        </div>

        <p>Periodicidade</p>
        <div style={{ color: colors.black2 }}>
          <Select
            name="selectedTypePeriodicidade"
            options={
              agenda.type === '12X'
                ? optionsTypePeriodicidade12X
                : optionsTypePeriodicidade
            }
            placeholder="Selecione o tipo da Periodicade..."
            className="basic-multi-select"
            classNamePrefix="select"
            value={typePeriodicidade}
            onChange={(e) => {
              if (e) {
                setTypePeriodicidade({
                  label: e.label,
                  value: e.value,
                });
              }
            }}
          />
        </div>
        {typePeriodicidade.value === 'sem' && (
          <CalendarDiv>
            <CalendarButton type="button">
              <FiCalendar />
              <span>Calendário</span>
            </CalendarButton>
            <div className="sem">
              {optionSemana.map((item, index) => (
                <DotCalendar
                  key={index.toString()}
                  selected={selectedCalendar.includes(item.value)}
                  type="button"
                  onClick={() => handleSemanCalendar(item.value)}
                >
                  {item.label}
                </DotCalendar>
              ))}
            </div>
          </CalendarDiv>
        )}
        {typePeriodicidade.value === '12X' && (
          <>
            <CalendarDiv>
              <CalendarButton type="button">
                <FiUser />
                <span>Colaborador 1</span>
              </CalendarButton>
              <div className="sem">
                {optionSemana.map((item, index) => (
                  <DotCalendar
                    key={index.toString()}
                    selected={selectedCalendar.includes(item.value)}
                    type="button"
                    onClick={() => handleSemanCalendar(item.value)}
                  >
                    {item.label}
                  </DotCalendar>
                ))}
              </div>
            </CalendarDiv>
            <CalendarDiv>
              <CalendarButton type="button">
                <FiUser />
                <span>Colaborador 2</span>
              </CalendarButton>
              <div className="sem">
                {optionSemana.map((item, index) => (
                  <DotCalendar
                    key={index.toString()}
                    selected={selectedCalendar2.includes(item.value)}
                    type="button"
                    onClick={() => handleSemanCalendar2(item.value)}
                  >
                    {item.label}
                  </DotCalendar>
                ))}
              </div>
            </CalendarDiv>
          </>
        )}
        {typePeriodicidade.value === 'men' && (
          <CalendarMensal>
            <p>Selecione os dias</p>
            <div className="info">
              <div className="divButton">
                <div className="input">
                  <input
                    name="diaCalendar"
                    type="number"
                    value={diaCalendar}
                    onChange={(e) => {
                      try {
                        const val = Number(e.target.value);
                        if (val > 0 && val <= 31) {
                          setDiaCalendar(val);
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  />
                </div>
                <ButtonPlus type="button" onClick={handleAddDia}>
                  <FiPlus size={22} />
                </ButtonPlus>
              </div>
              <ValSelectedButton>
                {selectedDias.map((item, index) => {
                  if (selectedDias.length === index + 1) {
                    return (
                      <button
                        key={index.toString()}
                        type="button"
                        onClick={() => handleRemoveDia(item)}
                      >
                        <p>{item}</p>
                      </button>
                    );
                  }
                  return (
                    <div style={{ display: 'flex' }} key={index.toString()}>
                      <button
                        type="button"
                        onClick={() => handleRemoveDia(item)}
                      >
                        <p>{item}</p>
                      </button>
                      <p style={{ margin: 0, marginLeft: 10 }}>-</p>
                    </div>
                  );
                })}
              </ValSelectedButton>
            </div>
          </CalendarMensal>
        )}
        {typePeriodicidade.value === 'ano' && (
          <CalendarMensal>
            <p>Adicione as datas</p>
            <div className="info">
              <div className="divButton">
                <div className="input">
                  <input
                    name="diaCalendar"
                    type="number"
                    value={diaCalendar}
                    onChange={(e) => {
                      try {
                        const val = Number(e.target.value);
                        if (val > 0 && val <= 31) {
                          setDiaCalendar(val);
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  />
                </div>
                <p
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    margin: 0,
                    marginTop: 8,
                  }}
                >
                  de
                </p>
                <div
                  style={{
                    flex: 2,
                    color: colors.black2,
                    fontFamily: 'Montserrat',
                  }}
                >
                  <Select
                    options={optionMesAno}
                    value={mesCalendar}
                    onChange={(e) => {
                      if (e) {
                        setMesCalendar(e);
                      }
                    }}
                  />
                </div>
                <ButtonPlus type="button" onClick={handleAddMes}>
                  <FiPlus size={22} />
                </ButtonPlus>
              </div>
              <div style={{ width: 80 }} />
              <div style={{ flex: 1 }}>
                <ValSelectedButton>
                  {selectedMeses.map((item, index) => {
                    if (selectedMeses.length === index + 1) {
                      return (
                        <button
                          key={index.toString()}
                          type="button"
                          onClick={() => handleRemoveMes(item)}
                        >
                          <p>{item}</p>
                        </button>
                      );
                    }
                    return (
                      <div style={{ display: 'flex' }} key={index.toString()}>
                        <button
                          type="button"
                          onClick={() => handleRemoveMes(item)}
                        >
                          <p>{item}</p>
                        </button>
                        <p style={{ margin: 0, marginLeft: 10 }}>-</p>
                      </div>
                    );
                  })}
                </ValSelectedButton>
              </div>
            </div>
          </CalendarMensal>
        )}

        <Content>
          <section>
            <p>Horario de Ronda</p>
            <DivInput>
              <article>
                <h3>Horário inicial</h3>
                <div>
                  <input
                    value={startTime}
                    type="time"
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                </div>
              </article>
              {/* <div style={{ width: 40 }} /> */}
              <article>
                <h3>Horário final</h3>
                <div>
                  <input
                    type="time"
                    value={finishTime}
                    onChange={(e) => {
                      setFinishTime(e.target.value);
                    }}
                  />
                </div>
              </article>
            </DivInput>
          </section>
          <section>
            <div
              style={{
                flexDirection: 'row',
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p style={{ marginBottom: 0 }}>Revisões - {titleRevision}</p>
              <ButtonAdd
                type="button"
                onClick={() => {
                  setTimeRevision([
                    ...timeRevision,
                    { horaIni: '', horaFim: '' },
                  ]);
                }}
              >
                <FiPlus />
              </ButtonAdd>
            </div>
            {timeRevision.length > 0 && (
              <aside
                style={{
                  paddingBottom: 0,
                  borderBottom: 0,
                  marginBottom: 0,
                  display: 'flex',
                }}
              >
                <h3 style={{ flex: 1 }}>Horário inicial da revisão</h3>
                <h3 style={{ flex: 1 }}>Horário final da revisão</h3>
                <div style={{ marginLeft: 20, height: 20, width: 20 }} />
              </aside>
            )}
            {timeRevision.map((item, index) => {
              return (
                <DivInput style={{ alignItems: 'center', marginBottom: 20 }}>
                  <article>
                    <div>
                      <input
                        name={`startRevTime${index}`}
                        type="time"
                        value={item.horaIni}
                        onChange={(e) => {
                          timeRevision[index].horaIni = e.target.value;
                          setTimeRevision([...timeRevision]);
                        }}
                      />
                    </div>
                  </article>
                  {/* <div style={{ width: 40 }} /> */}
                  <article>
                    <div>
                      <input
                        name={`endRevTime${index}`}
                        type="time"
                        value={item.horaFim}
                        onChange={(e) => {
                          timeRevision[index].horaFim = e.target.value;
                          setTimeRevision([...timeRevision]);
                        }}
                      />
                    </div>
                  </article>
                  <ButtonRem
                    type="button"
                    onClick={() => {
                      setTimeRevision(
                        timeRevision.filter((_, i) => i !== index),
                      );
                    }}
                  >
                    <FiTrash />
                  </ButtonRem>
                </DivInput>
              );
            })}
          </section>
        </Content>
        <Content>
          <section>
            <p>Data Vigente</p>
            <DivInput>
              <article>
                <h3>Data inicial</h3>
                <div>
                  <input
                    type="date"
                    value={dateVigStart}
                    onChange={(e) => {
                      setDateVigStart(e.target.value);
                    }}
                  />
                </div>
              </article>
              {/* <div style={{ width: 40 }} /> */}
              <article>
                <h3>Data final</h3>
                <div>
                  <input
                    type="date"
                    value={dateVigEnd}
                    onChange={(e) => {
                      setDateVigEnd(e.target.value);
                    }}
                  />
                </div>
              </article>
            </DivInput>
          </section>
        </Content>

        <Button type="submit">Salvar</Button>
        <Button
          className="rem"
          type="button"
          onClick={() => {
            handleSubmitDelete(agenda.id);
          }}
        >
          Deletar
        </Button>
      </Container>

      {loading && <Loading />}
    </NewModal>
  );
}
