import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px 40px;
  background: #ffffff;
  max-width: 800px;

  h1 {
    color: #232323;
    text-align: center;
  }

  h3 {
    margin-top: 1rem;
    color: #232323;
    font-weight: 600;
  }
  p {
    color: #232323;
  }
  span {
    color: #333333;
  }

  @media screen and (max-width: 1152px) {
    padding: 10px 20px;
  }
`;
