import React, { useState } from 'react';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { CircleButtonSlider, Slider } from './styles';

interface IsliderButton {
  idElement: number;
  typeRequisicao?: 'Cliente' | 'Filial' | 'Rondas';
  activeStatus?: boolean;
}

const SliderButton: React.FC<IsliderButton> = ({
  idElement,
  typeRequisicao,
  activeStatus,
}) => {
  const [active, setActive] = useState(activeStatus!);

  const successMessage = (): void => {
    Swal.fire({
      icon: 'success',
      title: 'Modificado com sucesso!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      showConfirmButton: false,
    });
  };

  const errMessage = (): void => {
    Swal.fire({
      icon: 'error',
      title: `Erro na comunicação com servidor, cerifique sua conexão com a rede!`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      showConfirmButton: false,
    });
  };

  const modStatus = (): void => {
    if (typeRequisicao === 'Cliente') {
      api
        .put('/clientes', {
          id: idElement,
          active: !active,
        })
        .then(() => {
          successMessage();
          setActive(!active);
        })
        .catch(() => {
          errMessage();
          setActive(active);
        });
    } else if (typeRequisicao === 'Filial') {
      api
        .put('/empresas/updateEmpresaFilial', {
          ID: idElement,
          ATIVO: !active,
        })
        .then(() => {
          successMessage();
          setActive(!active);
        })
        .catch(() => {
          errMessage();
          setActive(active);
        });
    } else if (typeRequisicao === 'Rondas') {
      api
        .put('/rondas', {
          id: idElement,
          active: !active,
        })
        .then(() => {
          successMessage();
          setActive(!active);
        })
        .catch(() => {
          errMessage();
          setActive(active);
        });
    }
  };

  return (
    <div>
      <Slider active={active}>
        <CircleButtonSlider
          style={{ border: 'none' }}
          active={active}
          type="button"
          onClick={() => {
            modStatus();
          }}
        />
      </Slider>
    </div>
  );
};
export default SliderButton;
