import React, { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import NewModal from '../NewModal';
import { ButtonClose, ContainerBody } from './styles';
import {
  DivLineBottom,
  HeaderForm,
} from '../../pages/DashboardOcorrencias/styles';
import {
  ButtonForm,
  ContainerButtonForm,
} from '../../pages/CadastroCliente copy/styles';

interface Props {
  isOpen: boolean;
  onClose(): void;
  confirmarGrupos(): void;
}

export const ModalConfirmGrupos: React.FC<Props> = ({
  isOpen,
  onClose,
  confirmarGrupos
}) => {

  return (
    <NewModal isVisible={isOpen}>
      <ButtonClose>
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <FiX size={22} />
        </button>
      </ButtonClose>
      <ContainerBody>
        <HeaderForm>Deseja confirmar criação de grupos de rotas?</HeaderForm>

        <ContainerButtonForm>
          <ButtonForm
            type="button"
            background="#FFFFFF"
            onClick={() => {
              onClose();
            }}
          >
            Não
          </ButtonForm>
          <ButtonForm
            type="button"
            background="#393B41"
            onClick={() => {
              confirmarGrupos();
            }}
          >
            Sim
          </ButtonForm>
        </ContainerButtonForm>
      </ContainerBody>
    </NewModal>
  );
};
