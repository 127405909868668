import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useHistory, useLocation } from 'react-router-dom';
// import Leaflet from 'leaflet';
// import MapboxGL from 'mapbox-gl';
import ReactMapboxGl, {
  Layer,
  Marker as MarkerGL,
  Source,
  // eslint-disable-next-line import/no-unresolved
} from 'react-mapbox-gl';

import { ThemeContext } from 'styled-components';
// import MapboxDirections from '@mapbox/mapbox-gl-directions';
import 'mapbox-gl/dist/mapbox-gl.css';

import logoPoint from '../../assets/VectorPoint.svg';

// import './styles.css';
import { Container, Line, ItemMap } from './styles';
import { Loading } from '../../components/Loading';
import {
  GetDirectionsGoogleMaps,
  tokenGoogleApi,
  tokenMapBox,
} from './interface';
import { Rota } from '../../models/Rota';

interface Params {
  rota: Rota;
}

interface PostoOfClient {
  id: number;
  name: string;
  endereco: string;
  latitude: number;
  longitude: number;
}

interface Coord {
  latitude: number;
  longitude: number;
}

interface Coordenates {
  coords: Coord[];
  distance: number;
  time: number;
}

// export interface Local {
//   id: number;
//   name: string;
//   local: string;
//   horarioInicial: string;
//   horarioFinal: string;
//   funcionarios: User[];
//   latitude: number;
//   longitude: number;
//   type: string;
//   avatarUrl: string;
// }

// const dataPosto: PostoOfClient[] = [
//   {
//     endereco:
//       'Rua Desembargador Leite Albuquerque Torre Norte - Aldeota, Fortaleza - CE, 60170-002',
//     id: 0,
//     latitude: -3.7371362,
//     longitude: -38.4993369,
//     name: 'GreenDot Hub LTDA',
//   },
//   {
//     endereco:
//       'R. Paulo Esteferson Bezerra, 174 - Jangurussu, Fortaleza - CE, 60870-520',
//     id: 1,
//     latitude: -3.8414166,
//     longitude: -38.5155225,
//     name: 'Grupo Servnac',
//   },
//   {
//     endereco: 'Av. Nevaldo Rocha, 7854 - Tirol, Natal - RN, 59015-265',
//     id: 2,
//     name: 'Arena Castelão',
//     latitude: -3.7693333,
//     longitude: -38.5378921,
//   },
//   {
//     endereco:
//       'Av. Washington Soares, 85 - Edson Queiroz, Fortaleza - CE, 60811-340',
//     id: 3,
//     name: 'Shopping Iguatemi Fortaleza',
//     latitude: -3.7584073,
//     longitude: -38.5361586,
//   },
// ];

// const mapPoint = Leaflet.icon({
//   iconUrl: logoPoint,
//   iconSize: [30, 30],
//   iconAnchor: [15, 30],
// });

export const DetailsRota: React.FC = () => {
  const history = useHistory();
  const { rota } = useLocation().state as Params;
  const { colors } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  // const [viewport, setViewport] = useState({
  //   longitude: -38.6634685,
  //   latitude: -3.7353647,
  //   zoom: 12,
  //   bearing: 0,
  //   pitch: 0,
  //   dragPan: true,
  //   width: '100%',
  //   height: '100%',
  // });
  const [rotasAll, setRotasAll] = useState<PostoOfClient[]>([]);
  const [visibleMelhorRota, setVisibleMelhorRota] = useState(false);
  // const [melhorRotaCoord, setMelhorRotaCoord] = useState<PostoOfClient[]>([]);
  const [initPosition, setInitPosition] = useState<Coord>({
    latitude: -3.7371362,
    longitude: -38.4993369,
  });
  const [coordsMelhor, setCoordsMelhor] = useState<Coordenates>({
    coords: [],
    distance: 0,
    time: 0,
  });

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // const MarkerRender = useCallback((mark: PostoOfClient, index: number) => {
  //   return (
  //     <div key={index.toString()}>
  //       <Marker
  //         icon={mapPoint}
  //         position={[mark.latitude, mark.longitude]}
  //         keyboard
  //       >
  //         <Popup>
  //           <NameMap>{mark.name}</NameMap>
  //         </Popup>
  //       </Marker>
  //     </div>
  //   );
  // }, []);

  const Map = ReactMapboxGl({
    accessToken: tokenMapBox,
  });

  // const MapTest = new MapBoxGL.Map({
  //   container: 'map',
  //   style: 'mapbox://styles/mapbox/streets-v9',
  // });

  // const directions = MapboxDirections({
  //   accessToken: tokenMapBox,
  //   unit: 'metric',
  //   profile: 'mapbox/driving',
  // });

  // MapTest.addControl(directions, 'bottom-left');

  // const MyMapComponent = withGoogleMap(() => (
  //   <GoogleMap defaultZoom={8} defaultCenter={{ lat: -3.735, lng: -38.663 }}>
  //     {dataPosto.map((item, index) => (
  //       <MarkerGo
  //         key={index.toString()}
  //         position={{ lat: item.latitude, lng: item.longitude }}
  //       />
  //     ))}
  //   </GoogleMap>
  // ));

  const getCoords = useCallback(async (coords: PostoOfClient[]): Promise<
    Coordenates
  > => {
    // console.log('EU TENHO ', coords.length, ' ELEMENTOS');
    if (coords.length <= 1) {
      // console.log('Condicao de Parada');
      return {
        coords: [],
        distance: 0,
        time: 0,
      };
    }
    if (coords.length <= 5) {
      // console.log('Condicao de Parada 2');
      const origin = `${coords[0].latitude},${coords[0].longitude}`;
      const auxElem = coords.length - 1;
      let waypoint = '';
      for (let i = 1; i < auxElem; i += 1) {
        if (i === 0) {
          waypoint = `${coords[i].latitude},${coords[i].longitude}`;
        } else {
          waypoint = `${waypoint}|${coords[i].latitude},${coords[i].longitude}`;
        }
      }
      const destination = `${coords[auxElem].latitude},${coords[auxElem].longitude}`;

      // console.log(
      //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      // );

      const response: GetDirectionsGoogleMaps = await fetch(
        `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      ).then((Response: any) => {
        return Response.json();
      });

      // console.log(response);

      // Axios.defaults.headers = {
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Headers': 'Content-Type',
      // };
      // // Axios.defaults.headers.get['Content-Type'] =
      // //   'application/x-www-form-urlencoded';

      // const response = await Axios.get(
      //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      // );
      // console.log(response.data);
      const getData: GetDirectionsGoogleMaps = response;
      const coordFinal: Coordenates = {
        coords: [],
        distance: 0,
        time: 0,
      };
      getData.routes[0].legs.forEach((item) => {
        const coordenadas = item.steps.map((itemStep) => {
          return [
            {
              latitude: itemStep.start_location.lat,
              longitude: itemStep.start_location.lng,
            },
            {
              latitude: itemStep.end_location.lat,
              longitude: itemStep.end_location.lng,
            },
          ];
        });
        // console.log('CAminho', {
        //   coordenadas,
        //   distance: item.distance.value,
        //   time: item.duration.value,
        // });
        coordFinal.coords = coordFinal.coords.concat(...coordenadas);
        coordFinal.distance += item.distance.value;
        coordFinal.time += item.duration.value;
      });
      // console.log('FINAL', coordFinal);

      return coordFinal;
    }
    // console.log('Condicao Sem parada');
    const auxPost = coords.slice(0, 5);
    const auxRest = coords.slice(4);
    const finalCoords = await getCoords(auxPost);
    const newCoords = await getCoords(auxRest);
    return {
      coords: finalCoords.coords.concat(newCoords.coords),
      distance: finalCoords.distance + newCoords.distance,
      time: finalCoords.time + newCoords.time,
    };
  }, []);

  const MelhorRotaCall = useCallback(
    async (showMelhorRota: boolean) => {
      function distaciaPontos(
        coord1: PostoOfClient,
        coord2: PostoOfClient,
      ): number {
        function calculo(x1: number, x2: number): number {
          const x3 = (x2 - x1) ** 2;
          return x3;
        }
        const resul =
          calculo(coord1.latitude, coord2.latitude) +
          calculo(coord1.longitude, coord2.longitude);

        return Math.sqrt(resul);
      }

      function MelhorRota(
        coord1: PostoOfClient,
        list: PostoOfClient[],
      ): { distancia: number; resul: PostoOfClient[] } {
        // console.log('Minha LIst', list.length);
        if (list.length === 0) {
          // console.log('CONDIÇÂO DE PARADA 1', {
          //   distancia: 0,
          //   resul: [coord1],
          // });
          return { distancia: 0, resul: [coord1] };
        }
        if (list.length === 1) {
          // console.log('CONDIÇÂO DE PARADA 2');
          const coord2 = list[0];
          const dist = distaciaPontos(coord1, coord2);
          const result = MelhorRota(coord2, []);
          // console.log('TES', {
          //   distancia: dist + result.distancia,
          //   resul: [coord1, ...result.resul],
          // });
          return {
            distancia: dist + result.distancia,
            resul: [coord1, ...result.resul],
          };
        }
        // console.log('SEM PARADA');
        let menorDist = 999999999999999999999;
        let menorResult: PostoOfClient[] = [];
        for (let i = 0; i < list.length; i += 1) {
          const newCoord = list[i];
          const dist = distaciaPontos(coord1, newCoord);
          const newList = list.filter((e, index) => index !== i);
          // console.log('GET VAL -----------', coord1, newCoord);
          // console.log('VALOR', dist, newList);
          const result = MelhorRota(newCoord, newList);
          // console.log(
          //   'COMPARACAO -----------',
          //   result.distancia + dist < menorDist,
          // );
          if (result.distancia + dist < menorDist) {
            menorDist = result.distancia + dist;
            menorResult = result.resul;
          }
        }

        return {
          distancia: menorDist,
          resul: [coord1, ...menorResult],
        };
      }

      setLoading(true);

      if (!showMelhorRota) {
        setLoading(false);
        // console.log('1');
        // setMelhorRotaCoord(dataPosto);
      } else if (rotasAll.length > 1) {
        const result = MelhorRota(
          {
            endereco: '',
            id: -1,
            longitude: initPosition.longitude,
            latitude: initPosition.latitude,
            name: '',
          },
          rotasAll,
        );
        // console.log('2');
        // console.log(result);
        const coordsPosto = await getCoords(result.resul);
        // console.log('FEZ O GETCOORDS');
        setLoading(false);
        // setMelhorRotaCoord(result.resul.filter((i, index) => index !== 0));
        setCoordsMelhor(coordsPosto);
      } else {
        setLoading(false);
        // console.log('3');
        // setMelhorRotaCoord(dataPosto);
      }
    },
    [getCoords, initPosition.latitude, initPosition.longitude, rotasAll],
  );

  const getLoading = useCallback(() => {
    const post: PostoOfClient[] = [];
    rota.visitas.forEach((item) => {
      post.push({
        endereco: item.Visita.Posto.endereco,
        id: item.visitaId,
        latitude: item.Visita.Posto.latitude,
        longitude: item.Visita.Posto.longitude,
        name: item.Visita.Posto.name,
      });
    });
    setLoading(true);
    setRotasAll(post);
    setLoading(false);
  }, [rota.visitas]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        // console.log(position);
        setInitPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
    getLoading();
  }, [getLoading]);

  // const dataOne = {
  //   type: 'Feature',
  //   properties: {},
  //   geometry: {
  //     type: 'LineString',
  //     coordinates: [
  //       [-38.6634685, -3.7353647],
  //       [-38.4993369, -3.7371362],
  //     ],
  //   },
  // };

  // const dataTEste = {
  //   type: 'Feature',
  //   properties: {
  //     place: 'Big Bear Cafe',
  //     login: '',
  //     lat: '38.91275',
  //     lon: '-77.01239',
  //   },
  //   geometry: {
  //     type: 'Point',
  //     coordinates: [-77.01239, 38.91275],
  //   },
  // };

  // const geojson = {
  //   type: 'FeatureCollection',
  //   features: [
  //     {
  //       type: 'Feature',
  //       geometry: {
  //         type: 'LineString',
  //         properties: {
  //           place: 'CAUCAIA',
  //           login: '',
  //           lat: '-3.7693333',
  //           lon: '-38.5378921',
  //         },
  //         coordinates: [
  //           [-77.0366048812866, 38.89873175227713],
  //           [-77.03364372253417, 38.89876515143842],
  //           [-77.03364372253417, 38.89549195896866],
  //           [-77.02982425689697, 38.89549195896866],
  //           [-77.02400922775269, 38.89387200688839],
  //           [-77.01519012451172, 38.891416957534204],
  //           [-77.01521158218382, 38.892068305429156],
  //           [-77.00813055038452, 38.892051604275686],
  //           [-77.00832366943358, 38.89143365883688],
  //           [-77.00818419456482, 38.89082405874451],
  //           [-77.00815200805664, 38.88989712255097],
  //           [-38.5378921, -3.7693333],
  //         ],
  //       },
  //     },
  //   ],
  // };

  // const symbolLayout: MapboxGL.SymbolLayout = {
  //   'text-field': '{place}',
  //   'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  //   'text-offset': [0, 0.6],
  //   'text-anchor': 'top',
  // };
  // const symbolPaint: MapboxGL.SymbolPaint = {
  //   'text-color': '#282828',
  // };

  // const circleLayout: MapboxGL.CircleLayout = { visibility: 'visible' };
  // const circlePaint: MapboxGL.CirclePaint = {
  //   'circle-color': '#393B41',
  // };

  // const GEOJSON_SOURCE_OPTIONS = {
  //   type: 'geojson',
  //   data: {
  //     type: 'Feature',
  //     geometry: {
  //       type: 'LineString',
  //       coordinates: [
  //         [-38.6634685, -3.7353647],
  //         [-38.4993369, -3.7371362],
  //       ],
  //     },
  //     properties: {
  //       title: 'Mapbox DC',
  //       'marker-symbol': 'monument',
  //     },
  //   },
  // };

  // const POSITION_CIRCLE_PAINT = {
  //   'circle-stroke-width': 4,
  //   'circle-radius': 10,
  //   'circle-blur': 0.15,
  //   'circle-color': '#393B41',
  //   'circle-stroke-color': 'red',
  // };

  return (
    <Container>
      <div className="navigate">
        <button type="button" onClick={handleBack}>
          <FiArrowLeft />
        </button>
        <p>Detalhes da Rota</p>
      </div>
      <form>
        <h2>Detalhes da Rota</h2>
        <Line />
        <div className="content">
          <div>
            <p>Rota</p>
            <input disabled value={rota.name} />
          </div>
          <p style={{ width: 14 }} />
          <div>
            <p>Colaborador</p>
            <input disabled value={rota.supervisor.name} />
          </div>
        </div>
        <div className="content">
          {/* <div>
            <p>Tipo</p>
            <input disabled value={rota.type} />
          </div>
          <p style={{ width: 14 }} /> */}
          <div>
            <p>Data</p>
            <input type="date" disabled value={rota.date} />
          </div>
        </div>
        <div className="content">
          <div>
            <p>Apontamentos</p>
            <input disabled value={rota.visitas.pop()?.status} />
          </div>
          <p style={{ width: 14 }} />
          <div />
        </div>
      </form>
      {/* <div className="map">
        <span>Mapa</span>
        <ReactMapGL
          mapboxApiAccessToken={tokenMapBox}
          {...viewport}
          onViewportChange={setViewport}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          {visibleMelhorRota && (
            <SourceMAPGL
              id="polylineLayer"
              type="geojson"
              data={{
                type: 'Feature',
                geometry: {
                  coordinates: coordsMelhor.coords.map((item) => [
                    item.longitude,
                    item.latitude,
                  ]),
                  type: 'LineString',
                },
                properties: {},
              }}
            >
              <LayerMAPGL
                id="lineLayer"
                type="line"
                source="my-data"
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': 'rgba(237,56,55,0.9)',
                  'line-width': 5,
                }}
              />
            </SourceMAPGL>
          )}
          {dataPosto.map((item, index) => (
            <MarkerMAPGL
              key={index.toString()}
              latitude={item.latitude}
              longitude={item.longitude}
            >
              <ItemMap>
                <img src={logoPoint} alt={item.name} />
                <span>{item.name}</span>
              </ItemMap>
            </MarkerMAPGL>
          ))}
        </ReactMapGL>
      </div> */}
      {/* <div style={{ height: 50 }} /> */}
      <div className="map">
        <span>Mapa</span>
        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/streets-v11"
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          center={[initPosition.longitude, initPosition.latitude]}
          zoom={[12]}
          movingMethod="flyTo"
        >
          <div>
            <MarkerGL
              key="voceaqui"
              coordinates={[initPosition.longitude, initPosition.latitude]}
              anchor="bottom"
            >
              <ItemMap>
                <img src={logoPoint} alt="Voce esta aqui" />
                <span>Você está aqui!</span>
              </ItemMap>
            </MarkerGL>
            {rotasAll.map((item, index) => (
              <MarkerGL
                key={`${item.id}-${index.toString()}`}
                coordinates={[item.longitude, item.latitude]}
                anchor="bottom"
              >
                <ItemMap>
                  <img src={logoPoint} alt={item.name} />
                  <span>{item.name}</span>
                </ItemMap>
              </MarkerGL>
            ))}
            {/* <Source
              type="geojson"
              geoJsonSource={{
                type: 'geojson',
                data: {
                  type: 'Feature',
                  properties: {
                    place: 'CAUCAIA',
                    login: '',
                    lat: '38.91275',
                    lon: '-77.01239',
                  },
                  geometry: {
                    type: 'LineString',
                    coordinates: coordsMelhor.coords.map((item) => [
                      item.longitude,
                      item.latitude,
                    ]),
                  },
                },
              }}
            >
              <Layer
                id="LineString"
                type="line"
                source="LineString"
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': 'rgba(237,56,55,0.9)', //  'rgba(0, 175, 228, 1)',
                  'line-width': 25,
                }}
              />
            </Source> */}
            {visibleMelhorRota && (
              <div>
                <Source
                  id="example_id"
                  geoJsonSource={{
                    type: 'geojson',
                    data: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: coordsMelhor.coords.map((item) => [
                          item.longitude,
                          item.latitude,
                        ]),
                      },
                      properties: {
                        title: 'Mapbox DC',
                        'marker-symbol': 'monument',
                      },
                    },
                  }}
                />
                <Layer
                  // type="circle"
                  type="line"
                  id="example_id_marker"
                  paint={{
                    'line-color': colors.primary,
                    'line-width': 5,
                  }}
                  // paint={POSITION_CIRCLE_PAINT}
                  sourceId="example_id"
                />
              </div>
            )}
            {/* <GeoJSONLayer
              data={{
                type: 'Feature',
                properties: {
                  place: 'CAUCAIA',
                  login: '',
                  lat: '38.91275',
                  lon: '-77.01239',
                },
                geometry: {
                  type: 'LineString',
                  coordinates: coordsMelhor.coords.map((item) => [
                    item.longitude,
                    item.latitude,
                  ]),
                },
              }}
              circleLayout={circleLayout}
              circlePaint={circlePaint}
              // circleOnClick={this.onClickCircle}
              symbolLayout={symbolLayout}
              symbolPaint={symbolPaint}
            /> */}
          </div>
        </Map>
      </div>
      <div className="resul">
        {!visibleMelhorRota ? (
          <button
            type="button"
            onClick={() => {
              setVisibleMelhorRota(true);
              MelhorRotaCall(true);
            }}
          >
            Visualizar Melhor Rota
          </button>
        ) : (
          <div>
            <p>{rota.name}</p>
            <span>
              Distância: {(coordsMelhor.distance / 1000).toFixed(2)}Km
            </span>
            |<span>Duração: {Math.floor(coordsMelhor.time / 60)}min</span>
          </div>
        )}
      </div>
      {/* <div style={{ height: 50 }} />
      <div className="map">
        <span>Mapa</span>
        <div ref={mapContainer} style={{ width: "100%", height: "100%" }} />
      </div> */}
      <div style={{ height: 50 }} />
      {loading && <Loading />}
    </Container>
  );
};
