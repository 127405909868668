import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';

import {
  HeaderForm,
  DivLineBottom,
  LabelInputForm,
  InputForm,
  ButtonForm,
} from '../../../pages/CadastroCliente copy/styles';
import InputAddDate from '../../InputAddDate';
// import ButtonCalendar from '../../ButtonCalendar';
import DaysWeek from '../../DaysWeek';
import { Container, ContainerCheckLabel, ContainerButtonForm } from './styled';
import CheckListAddDate from '../../CheckListAddDate';
import MapGoogleMaps from '../../MapGoogleMaps';
import { Postos } from '../../../models/Postos';

interface Props {
  component?: React.FC;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setAddPosto: React.SetStateAction<any>;
  posto: any[];
  currentCentroCustoEdit?: Postos;
  indexCurrentCustoEdit?: number;
}

export interface LocalIzation {
  endereco: string;
  cepAddress: string;
  countyAddress: string;
  districtAddress: string;
  latitudeAddress: string;
  longitudeAddress: string;
  numberAddress: string;
  stateAddress: string;
  streetAddress: string;
}

const BodyAddPosto: React.FC<Props> = ({
  setVisible,
  setAddPosto,
  posto,
  currentCentroCustoEdit,
  indexCurrentCustoEdit,
}) => {
  // const [postoCurrent, setPostoCurrent] = useState();
  const [idCurrent, setIdCurrent] = useState(0);
  const [nameCentroCusto, setNameCentroCusto] = useState('');
  const [tipoPeriodicidade, setTipoPeriodicidade] = useState('');
  const [vigInicial, setVigInicial] = useState(moment().add(1, 'day').toDate());
  const [vigFinal, setFinal] = useState(moment().add(1, 'day').toDate());
  const [periodicidade, setPeriodicidade] = useState<string[]>([]);
  const [checkList, setCheckList] = useState<any>([]);
  const [responseLocalization, setResponseLocalization] = useState<
    LocalIzation
  >();
  const [isPostoDiurno, setIsPostoDiurno] = useState(true);
  // const [isPostoNoturno, setIsPostoNoturno] = useState(false);
  const [rotaHoje, setRotaHoje] = useState(false);
  const addPosto = useCallback((): void => {
    const current: Postos = {
      id: 0,
      clienteId: 0,
      impClienteId: '',
      importId: '',
      name: nameCentroCusto,
      empresaId: 0,
      endereco: responseLocalization?.endereco || 'NA',
      bairro: responseLocalization?.districtAddress || 'NA',
      cep: responseLocalization?.cepAddress || 'NA',
      municipio: responseLocalization?.countyAddress || 'NA',
      estado: responseLocalization?.stateAddress || 'NA',
      latitude: responseLocalization?.latitudeAddress || 'NA',
      longitude: responseLocalization?.longitudeAddress || 'NA',
      origem: 'cad',
      typePeriodicidade:
        tipoPeriodicidade === 'dir' ? 'sem' : tipoPeriodicidade,
      freqSem: periodicidade.join(' - '),
      freqMes: periodicidade.join(' - '),
      freqAno: periodicidade.join(' - '),
      horaIni: '',
      horaFim: '',
      vigIni: vigInicial,
      vigFim: vigFinal,
      Tarefas: checkList,
      turno: isPostoDiurno ? 'Diurno' : 'Noturno',
      active: true,
      new: indexCurrentCustoEdit === -1,
      maxAgend: null,
      rotaHoje: rotaHoje
    };

    // console.log(checkList);
    // console.log(current);

    if (current.name === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher o campo "Nome do cliente".',
      });
    } else if (current.endereco === 'NA') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher um endereço válido.',
      });
    } else if (current.typePeriodicidade === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher uma períodicidade válida.',
      });
    } else if (current.freqSem === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher uma sequência de períodicidade válida.',
      });
    } else if (checkList.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher ao menos uma tarefa, no campo "Checklist".',
      });
    } else if (current.vigIni.toString() === 'Invalid Date') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher o "Vigência Inicial".',
      });
    } else if (current.vigFim.toString() === 'Invalid Date') {
      Swal.fire({
        icon: 'info',
        title: 'Campo vazio!',
        text: 'Favor preecher o "Vigência final".',
      });
    } else if (idCurrent === 0) {
      if (indexCurrentCustoEdit === -1) {
        setAddPosto([...posto, current]);
        setVisible?.(false);
      } else {
        const post = posto.map((pos, index) =>
          index === indexCurrentCustoEdit ? current : pos,
        );
        setAddPosto([...post]);
        setVisible?.(false);
      }
    } else {
      const index = posto.findIndex((e) => e.id === idCurrent);
      // eslint-disable-next-line no-param-reassign
      posto[index] = current;
      // eslint-disable-next-line no-param-reassign
      posto[index].id = idCurrent;
      setVisible?.(false);
    }
  }, [
    checkList,
    idCurrent,
    indexCurrentCustoEdit,
    isPostoDiurno,
    nameCentroCusto,
    periodicidade,
    posto,
    responseLocalization,
    setAddPosto,
    setVisible,
    tipoPeriodicidade,
    vigFinal,
    vigInicial,
  ]);

  const teste66: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const nameInputVigencia = event.currentTarget.name;
    const valueInputVigencia = moment(event.currentTarget.value).toDate();
    const today = new Date();

    if (nameInputVigencia === 'vigIni') {
      if (valueInputVigencia.getTime() < today.getTime()) {
        Swal.fire({
          icon: 'error',
          title: `A vigência inicial precisa ser posterior a hoje!`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });

        setVigInicial(moment().add(1, 'day').toDate());
      }
    } else if (nameInputVigencia === 'vigFim') {
      const dateToOneYear = new Date(vigInicial);
      dateToOneYear.setFullYear(vigInicial.getFullYear() + 1);

      if (valueInputVigencia.getTime() < vigInicial.getTime()) {
        Swal.fire({
          icon: 'error',
          title: `A vigência final precisa ser igual ou posterior a vigência inicial!`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });
        setFinal(moment().add(1, 'day').toDate());
      }
      if (valueInputVigencia.getTime() > dateToOneYear.getTime()) {
        Swal.fire({
          icon: 'error',
          title: `A vigência final precisa ser, no máximo um ano a mais que a vigência inicial!`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });
        setFinal(dateToOneYear);
      }
    }
  };

  const getPeriod = useCallback((p: string) => {
    const array = [];
    if (p.includes('0')) {
      array.push('0');
    }
    if (p.includes('1')) {
      array.push('1');
    }
    if (p.includes('2')) {
      array.push('2');
    }
    if (p.includes('3')) {
      array.push('3');
    }
    if (p.includes('4')) {
      array.push('4');
    }
    if (p.includes('5')) {
      array.push('5');
    }
    if (p.includes('6')) {
      array.push('6');
    }
    return array;
  }, []);

  const getItems = useCallback(async (): Promise<void> => {
    if (currentCentroCustoEdit !== undefined) {
      setIdCurrent(currentCentroCustoEdit.id);
      setNameCentroCusto(currentCentroCustoEdit.name);
      setTipoPeriodicidade(currentCentroCustoEdit.typePeriodicidade);
      setVigInicial((new Date((new Date(currentCentroCustoEdit.vigIni)).getTime() + 3 * 60 * 60 * 1000)));
      setFinal((new Date((new Date(currentCentroCustoEdit.vigFim)).getTime() + 3 * 60 * 60 * 1000)));
      setPeriodicidade(getPeriod(currentCentroCustoEdit.freqAno || ''));
      setCheckList(currentCentroCustoEdit.Tarefas);
      setResponseLocalization({
        endereco: currentCentroCustoEdit.endereco,
        cepAddress: currentCentroCustoEdit.cep,
        countyAddress: currentCentroCustoEdit.municipio || 'NE',
        districtAddress: currentCentroCustoEdit.bairro || 'NE',
        latitudeAddress: currentCentroCustoEdit.latitude,
        longitudeAddress: currentCentroCustoEdit.longitude,
        numberAddress: 'NE',
        stateAddress: 'NE',
        streetAddress: 'NE',
      });
    } else {
      // console.log('NEW');
      setIdCurrent(0);
      setNameCentroCusto('');
      setTipoPeriodicidade('');
      setVigInicial(new Date());
      setFinal(new Date());
      setPeriodicidade([]);
      setCheckList([]);
      setResponseLocalization({
        endereco: '',
        cepAddress: '',
        countyAddress: 'NE',
        districtAddress: 'NE',
        latitudeAddress: '',
        longitudeAddress: '',
        numberAddress: 'NE',
        stateAddress: 'NE',
        streetAddress: 'NE',
      });
    }
  }, [currentCentroCustoEdit, getPeriod]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleRotaHoje = () => {
    if (!rotaHoje) {
      setTipoPeriodicidade('sem')
      setPeriodicidade([(new Date()).getDay().toString()]);
    } else {
      setTipoPeriodicidade('')
      setPeriodicidade([]);
    }

    setRotaHoje(!rotaHoje);
    setVigInicial(moment().toDate())
    setFinal(moment().toDate())

  }

  return (
    <Container>
      <HeaderForm>Cadastro de postos</HeaderForm>
      <DivLineBottom />

      <LabelInputForm>Nome</LabelInputForm>
      <InputForm
        name="name"
        placeholder="Digite aqui"
        onChange={(e) => {
          setNameCentroCusto(e.target.value);
        }}
        value={nameCentroCusto}
      />
      <LabelInputForm>Localização</LabelInputForm>
      <MapGoogleMaps
        setAdreesValue={setResponseLocalization}
        adreesValue={responseLocalization}
      />
      <LabelInputForm>Periodicidade</LabelInputForm>

      <ContainerButtonForm>
        <select
          name="typePeriodicidade"
          id="typePeriodicidade"
          disabled={rotaHoje}
          onChange={(e) => {
            setTipoPeriodicidade(e.target.value);
            if (e.target.value === 'dir') {
              setPeriodicidade(['0', '1', '2', '3', '4', '5', '6']);
            } else if (e.target.value === 'sem') {
              setPeriodicidade(['1', '2', '3', '4', '5']);
            } else {
              setPeriodicidade([]);
            }
          }}
          value={tipoPeriodicidade}
        >
          <option value="">Tipo de periodicidade:</option>
          <option value="dir">Diária</option>
          <option value="sem">Semanal</option>
          <option value="men">Mensal</option>
          <option value="anu">Anual</option>
          <option value="">Dias alternados (Beta)</option>
          <option value="nad">Sem periodicidade</option>
        </select>
      </ContainerButtonForm>
      {tipoPeriodicidade === 'dir' && (
        <DaysWeek
          setPeriodicidade={setPeriodicidade}
          periodicidade={periodicidade}
        />
      )}
      {tipoPeriodicidade === 'sem' && (
        <DaysWeek
          setPeriodicidade={setPeriodicidade}
          periodicidade={periodicidade}
        />
      )}
      {tipoPeriodicidade === 'men' && (
        <ContainerButtonForm>
          <InputAddDate
            key={955}
            periodicidade={periodicidade}
            setPeriodicidade={setPeriodicidade}
            type="men"
          />
        </ContainerButtonForm>
      )}
      {tipoPeriodicidade === 'anu' && (
        <ContainerButtonForm>
          <InputAddDate
            key={4813}
            periodicidade={periodicidade}
            setPeriodicidade={setPeriodicidade}
            type="anu"
          />
        </ContainerButtonForm>
      )}

      <ContainerButtonForm>
        <CheckListAddDate setTarefas={setCheckList} tarefas={checkList} />
      </ContainerButtonForm>
      {/* <div className="containerHourDate">
        <div>
          <LabelInputForm>Delimitador Diurno</LabelInputForm>

          <InputForm type="number" name="delimDiun" defaultValue={1} min={1} />
        </div>
        <div>
          <LabelInputForm>Delimitador Noturno</LabelInputForm>

          <InputForm type="number" name="delimNotur" defaultValue={1} min={1} />
        </div>
      </div> */}
      <div className="containerHourDate">
        <div>
          <LabelInputForm>Vigência Inicial</LabelInputForm>

          <InputForm
            type="date"
            name="vigIni"
            disabled={rotaHoje}
            onBlur={teste66}
            onChange={(e) => {
              setVigInicial(moment(e.currentTarget.value).toDate());
            }}
            value={moment(vigInicial).format('YYYY-MM-DD')}
          />
        </div>
        <div>
          <LabelInputForm>Vigência final</LabelInputForm>

          <InputForm
            type="date"
            disabled={rotaHoje}
            name="vigFim"
            onBlur={teste66}
            onChange={(e) => {
              setFinal(moment(e.currentTarget.value).toDate());
            }}
            value={moment(vigFinal).format('YYYY-MM-DD')}
          />
        </div>

      </div>
      <div style={{marginBottom: '20px', marginTop: '10px'}}>
        <input
          type="checkbox"
          id="rotaHoje"
          checked={rotaHoje}
          onChange={() => {
            handleRotaHoje();
          }}
        />
        <span>Criar rota para hoje?</span>
      </div>

      <ContainerCheckLabel>
        <LabelInputForm>Turno(s) do Posto</LabelInputForm>
        <div>
          <input
            type="checkbox"
            id="diurno"
            checked={isPostoDiurno}
            onChange={() => {
              setIsPostoDiurno(true);
            }}
          />
          <span>DIURNO</span>
        </div>
        <div>
          <input
            type="checkbox"
            id="noturno"
            checked={!isPostoDiurno}
            onChange={() => {
              setIsPostoDiurno(false);
            }}
          />
          <span>NOTURNO</span>
        </div>
      </ContainerCheckLabel>
      <ContainerButtonForm>
        <ButtonForm background="#FFFFFF" onClick={() => setVisible?.(false)}>
          Cancelar
        </ButtonForm>
        <ButtonForm
          background="#393B41"
          onClick={() => {
            addPosto();
          }}
        >
          Salvar
        </ButtonForm>
      </ContainerButtonForm>
    </Container>
  );
};

export default BodyAddPosto;
