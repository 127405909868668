import React, {
  InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { Input } from './styled';
import {
  maskCpf,
  maskTel,
  maskCnpj,
  maskCep,
  maskDateOfBirth,
  maskDateYear,
  isCpf,
  isDate,
} from './mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  mask?: string;
  valor?: string;
  onValue?: (arg: string) => void;
  setStateValue: React.Dispatch<React.SetStateAction<string>>;
  stateValue: string;
}

const NewInput: React.FC<InputProps> = ({
  name,
  mask,
  onValue,
  setStateValue,
  stateValue,
  ...rest
}) => {
  const addValueState: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ): void => {
    if (mask === 'cpf') {
      setStateValue(maskCpf(e.target.value));
    } else if (mask === 'cnpj') {
      setStateValue(maskCnpj(e.target.value));
    } else if (mask === 'phone') {
      setStateValue(maskTel(e.target.value));
    } else if (mask === 'cep') {
      setStateValue(maskCep(e.target.value));
    } else {
      setStateValue(e.target.value);
      // console.log('asdsad');
    }
  };

  return (
    <Input
      type="text"
      name={name}
      placeholder={rest.placeholder}
      onChange={addValueState}
      value={stateValue}
    />
  );
};

export default NewInput;
