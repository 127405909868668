import React, {
  useState,
  HTMLAttributes,
  useCallback,
  // useContext,
} from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
// import { ThemeContext } from 'styled-components';

import Moment from 'moment';
import Qr from '../../assets/qr-code.png';
import { Filiais } from '../../models/Filiais';
import { Riscos } from '../../models/Riscos';
import { Vistorias } from '../../models/Vistorias';
import { maskTel } from '../NewInput/mask';
import NewModal from '../NewModal';
import BodyQrCode from '../NewModal/BodyQrCode';
import { returnNameFilial } from '../UltilitiesFunctions/returnNameFilial';
import { returnNameRiscos } from '../UltilitiesFunctions/returnNameRiscos';
import { Container } from './styled';
import { returnNameVistoria } from '../UltilitiesFunctions/returnNameVistoria';
import { returnNameAcess } from '../UltilitiesFunctions/returnNameAcess';
import { Clientes } from '../../models/Clientes';
import { returnNameClient } from '../UltilitiesFunctions/returnNameClient';
import { maskCpf } from '../Input/mask';
import SliderButton from '../SliderButton';
import { SliderButtonDefault } from '../SliderButtonDefault';
// import { ItemMap } from '../../pages/DetailsRota/styles';

interface Props {
  body: any[];
  header: Header[];
  tableId: string;
  filiais?: Filiais[];
  riscos?: Riscos[];
  vistorias?: Vistorias[];
  clientes?: Clientes[];
  setEditId?(id: number): void;
  setRemoveId?(id: number): void;
  setCheck?(id: number): void;
  setUpdateSlide?(id: number, bool: boolean): void;
}

interface Header {
  name: string;
  field: string;
  sortable: boolean;
}

interface ButtonHTMLAttributes<T> extends HTMLAttributes<T> {
  autoFocus?: boolean;
  disabled?: boolean;
  form?: string;
  formAction?: string;
  formEncType?: string;
  formMethod?: string;
  formNoValidate?: boolean;
  formTarget?: string;
  name?: string;
  type?: 'submit' | 'reset' | 'button';
  value?: string | string[] | number;
}

const Table: React.FC<Props> = ({
  body,
  header,
  tableId,
  filiais,
  riscos,
  vistorias,
  clientes,
  setEditId,
  setRemoveId,
  setCheck,
  setUpdateSlide,
}) => {
  const [visibleModalQr, setVisibleModalQr] = useState(false);
  // const [active, setActive] = useState(true);
  const [image, setImage] = useState('');
  const [qrCode, setQrCode] = useState({ name: '', id: -1 });
  // const { colors } = useContext(ThemeContext);

  const returnQr = useCallback((id: number, name: string) => {
    const linkQrImage = `https://chart.apis.google.com/chart?cht=qr&chl=${id}&chs=400x400`;
    setImage(linkQrImage);
    setQrCode({ id, name });
    setVisibleModalQr(true);
  }, []);

  return (
    <Container>
      <table id={tableId}>
        <thead>
          <tr>
            {header.map((item) => {
              return (
                <th key={Math.random() - 1}>
                  <h3 key={Math.random()}>{item.name}</h3>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {body.map((itemTr) => {
            return (
              <tr key={Math.random() + 1}>
                {header.map((element) => {
                  if (element.field === 'checked') {
                    return (
                      <td key={Math.random() - 2}>
                        <input
                          className="checkbox"
                          type="checkbox"
                          checked={itemTr.checked}
                          onClick={() => {
                            const ID = !itemTr.id ? -1 : Number(itemTr.id);
                            const id =
                              ID === -1 || !itemTr.ID ? ID : Number(itemTr.ID);
                            if (id !== -1 && setCheck) {
                              setCheck(id);
                            }
                          }}
                        />
                      </td>
                    );
                  }
                  if (element.field === 'telefone') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : maskTel(itemTr[element.field])}
                      </td>
                    );
                  }
                  if (element.field === 'editOption') {
                    return (
                      <td key={Math.random() - 2}>
                        <button
                          className="BtnEdit"
                          type="button"
                          onClick={() => {
                            setEditId!(itemTr.id);
                          }}
                        >
                          <FiEdit size={18} />
                        </button>
                      </td>
                    );
                  }
                  if (element.field === 'editOptionFilial') {
                    return (
                      <td key={Math.random() - 2}>
                        <button
                          className="BtnEdit"
                          type="button"
                          onClick={() => {
                            setEditId!(itemTr.ID);
                          }}
                        >
                          <FiEdit size={18} />
                        </button>
                      </td>
                    );
                  }
                  if (element.field === 'deleteOption') {
                    return (
                      <td key={Math.random() - 2}>
                        <button
                          className="BtnRemove"
                          type="button"
                          onClick={() => {
                            setRemoveId!(itemTr.id);
                          }}
                        >
                          <FiTrash size={18} />
                        </button>
                      </td>
                    );
                  }
                  if (
                    element.field === 'empresaId' ||
                    element.field === 'IdEmpresaFilial' ||
                    element.field === 'companyId'
                  ) {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : itemTr[element.field] +' - '+ returnNameFilial(itemTr[element.field], filiais)}
                      </td>
                    );
                  }
                  if (element.field === 'riscoId') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : returnNameRiscos(itemTr[element.field], riscos)}
                      </td>
                    );
                  }
                  if (element.field === 'qrCode') {
                    return (
                      <td key={Math.random() - 2}>
                        <button
                          id={itemTr.id}
                          type="button"
                          style={{
                            width: '40px',
                            margin: 'auto',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => returnQr(itemTr.id, itemTr.name)}
                        >
                          {/* qr-code-header-icon.png */}
                          <img src={Qr} alt="alt" style={{ width: '30px' }} />
                        </button>
                      </td>
                    );
                  }
                  if (element.field === 'Usuario') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field] ? '-' : itemTr.Usuario.nome}
                      </td>
                    );
                  }
                  if (element.field === 'createdAt') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : Moment(itemTr[element.field]).format('DD/MM/YYYY')}
                      </td>
                    );
                  }
                  if (element.field === 'createdAtHour') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.createdAt
                          ? '-'
                          : Moment(itemTr.createdAt).format('HH:mm:ss')}
                      </td>
                    );
                  }
                  if (
                    element.field === 'timeIni' ||
                    element.field === 'timeFim'
                  ) {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : Moment(itemTr[element.field]).format('HH:mm:ss')}
                      </td>
                    );
                  }
                  if (element.field === 'Ronda') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field] ? '-' : itemTr.Ronda.name}
                      </td>
                    );
                  }
                  if (element.field === 'Setor') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Ronda.Setor.name
                          ? '-'
                          : itemTr.Ronda.Setor.name}
                      </td>
                    );
                  }
                  if (element.field === 'Categoria') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Ronda.Categoria.name
                          ? '-'
                          : itemTr.Ronda.Categoria.name}
                      </td>
                    );
                  }
                  if (element.field === 'Posto.name') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Posto.name ? '-' : itemTr.Posto.name}
                      </td>
                    );
                  }
                  if (element.field === 'Colaborador.name') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Colaborador.name
                          ? '-'
                          : itemTr.Colaborador.name}
                      </td>
                    );
                  }
                  if (element.field === 'companyId') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.companyId
                          ? '-'
                          : returnNameFilial(itemTr.companyId, filiais)}
                      </td>
                    );
                  }
                  if (element.field === 'Categoria.name') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Categoria ? '-' : itemTr.Categoria.name}
                      </td>
                    );
                  }
                  if (element.field === 'Setor.name') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Setor.name ? '-' : itemTr.Setor.name}
                      </td>
                    );
                  }
                  if (element.field === 'Colaborador.acesso') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Colaborador.acesso
                          ? '-'
                          : returnNameAcess(itemTr.Colaborador.acesso)}
                      </td>
                    );
                  }
                  if (element.field === 'clienteId') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.Colaborador.acesso
                          ? '-'
                          : returnNameClient(
                              itemTr.Colaborador.acesso,
                              clientes,
                            )}
                      </td>
                    );
                  }
                  if (element.field === 'ATIVO') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.ATIVO ? '-' : itemTr.ATIVO.toString()}
                      </td>
                    );
                  }
                  if (element.field === 'ativo') {
                    const status = itemTr.ativo === 1 ? 'true' : 'false';
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.ativo ? '-' : status}
                      </td>
                    );
                  }
                  if (element.field === 'cpf') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr.cpf ? '-' : maskCpf(itemTr.cpf)}
                      </td>
                    );
                  }
                  if (element.field === 'activated') {
                    return (
                      <td key={Math.random() - 2}>
                        <SliderButton
                          idElement={itemTr.id}
                          typeRequisicao="Cliente"
                          activeStatus={itemTr.active}
                        />
                      </td>
                    );
                  }
                  if (element.field === 'activeSlider') {
                    return (
                      <td key={Math.random() - 2}>
                        <SliderButtonDefault
                          onUpdate={() => {
                            if (setUpdateSlide) {
                              const id =
                                itemTr.id === undefined || itemTr.id === null
                                  ? -1
                                  : itemTr.id;
                              setUpdateSlide(
                                id === -1 ? itemTr.ID : id,
                                !itemTr.activeSlider,
                              );
                            }
                          }}
                          activeStatus={itemTr.activeSlider}
                        />
                      </td>
                    );
                  }
                  if (element.field === 'ATIVO_FILIAL') {
                    return (
                      <td key={Math.random() - 2}>
                        <SliderButton
                          idElement={itemTr.ID}
                          typeRequisicao="Filial"
                          activeStatus={itemTr.ATIVO}
                        />
                      </td>
                    );
                  }
                  if (element.field === 'deleteOptionFilial') {
                    return (
                      <td key={Math.random() - 2}>
                        <SliderButton
                          idElement={itemTr.id}
                          typeRequisicao="Rondas"
                          activeStatus={itemTr.active}
                        />
                      </td>
                    );
                  }
                  if (element.field === 'vistoriaId') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field]
                          ? '-'
                          : returnNameVistoria(
                              itemTr[element.field],
                              vistorias,
                            )}
                      </td>
                    );
                  }

                  if (element.field === 'imagem') {
                    return (
                      <td key={Math.random() - 2}>
                        {!itemTr[element.field] ? (
                          ' - '
                        ) : (
                          <a
                            href={itemTr[element.field]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemTr[element.field]}
                          </a>
                        )}
                      </td>
                    );
                  }

                  return (
                    <td key={Math.random() - 2}>
                      {!itemTr[element.field] ? '-' : itemTr[element.field]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <NewModal isVisible={visibleModalQr}>
        <BodyQrCode
          setVisible={setVisibleModalQr}
          imageQrString={image}
          name={qrCode.name}
          id={qrCode.id}
        />
      </NewModal>
    </Container>
  );
};

export default Table;
