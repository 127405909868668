import styled from 'styled-components';
import { shade } from 'polished';

export const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.white};
  height: 67px;
`;

export const ContainerTh = styled.th`
  width: 100px;
  background: red;
  
  button {
    border: none;
    color: #fff;//${shade(0.7, '#439133')};
    margin: 0;
    justify-content: center;
    align-items: center;
    
    svg {
      margin-left: 5px;
      margin-bottom: -4px;
    }
  }
`;
