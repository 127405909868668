import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { FiUser, FiCalendar, FiMapPin, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { IreturnGroupRotas } from '../../pages/DashboardRotas';
import { ModalEditRotaComplete } from '../ModalEdit';
import { Card } from './styles';

interface Props {
  item: IreturnGroupRotas;
}

const CardAgendamentoRota: React.FC<Props> = ({ item }) => {
  const history = useHistory();
  const { colors } = useContext(ThemeContext);
  const [viewModal, setViewModal] = useState(false);

  const getMes = useCallback((date: string) => {
    const numberMes = moment(date).get('month');
    switch (numberMes) {
      case 0:
        return 'JAN';
      case 1:
        return 'FEV';
      case 2:
        return 'MAR';
      case 3:
        return 'ABR';
      case 4:
        return 'MAI';
      case 5:
        return 'JUN';
      case 6:
        return 'JUL';
      case 7:
        return 'AGO';
      case 8:
        return 'SET';
      case 9:
        return 'OUT';
      case 10:
        return 'NOV';
      case 11:
        return 'DEZ';
      default:
        return '';
    }
  }, []);

  const getDayWeek = useCallback((date: string) => {
    const numberDayWeek = moment(date).get('day');
    switch (numberDayWeek) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Segunda-Feira';
      case 2:
        return 'Terça-Feira';
      case 3:
        return 'Quarta-Feira';
      case 4:
        return 'Quinta-Feira';
      case 5:
        return 'Sexta-Feira';
      case 6:
        return 'Sábado';
      default:
        return '';
    }
  }, []);

  const handleViewRota = useCallback(() => {
    history.push({
      pathname: 'detalhes-rota-aprov',
      state: { rota: item },
    });
  }, [history, item]);

  return (
    <Card animate>
      <ModalEditRotaComplete
        item={item}
        isOpen={viewModal}
        onClose={() => {
          setViewModal(false);
        }}
      />
      <div className="card">
        <div className="date">
          <p>{item.data.slice(8, 10)}</p>
          <span>{getMes(item.data)}</span>
        </div>
        <div className="info">
          <p>
            {`${item.rotaId}-${item.Visita[0].Posto.endereco.split(',')[1]}`}
          </p>
          <div>
            <div>
              <FiUser />
              <span>{item.supervisorName}</span>
            </div>
            <div>
              <FiMapPin />
              <span>{`${item.Visita.length} visitas associadas a esta rota`}</span>
            </div>
            <div>
              <FiCalendar />
              <span>{getDayWeek(item.data)}</span>
            </div>
          </div>
        </div>
        <div className="option">
          <button
            id="BtnEdit"
            type="button"
            onClick={() => {
              setViewModal(true);
            }}
          >
            <FiEdit color={colors.primary} />
          </button>

          <button
            type="button"
            style={{
              background: colors.primary,
              color: colors.secondary,
            }}
            onClick={handleViewRota}
          >
            Detalhamento
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CardAgendamentoRota;
