import React, { useCallback, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { GetRota2 } from '../../models/Rota';
import { VisitaType } from '../../models/Visita';
import { UsuarioBD } from '../../models/User';
import CardAgendamentoRota from '../../components/CardAgendamentoRota';
import { searchSupervisor } from '../../components/UltilitiesFunctions/searchSupervisor';
import { InputTextFilter } from '../../components/NewSearch/styled';
import ModalConexao from '../../components/ModalConexao';
import { ModalAprovAutomatic } from '../../components/ModalAprovAutomatic';
import { Loading } from '../../components/Loading';
import api from '../../services/api';

import { ReactComponent as SearchIcon } from '../../assets/search_black_24dp.svg';
import {
  DivHead,
  Container,
  ContainerInputDate,
  DivFilter,
  Title,
} from './styles';

export interface IreturnGroupRotas {
  id?: any;
  Visita: VisitaType[];
  companyId: number;
  data: string;
  justificativa: string;
  rotaId: string;
  status: string;
  supervisorId: number;
  supervisorName: string;
  visitaId: number;
  dataAprov: string;
  coordenadorName: string;
}

export interface ObjectGroup {
  init: IreturnGroupRotas[];
  aprov: IreturnGroupRotas[];
}

interface AprovAutomatic {
  supervisorId: number;
  dataI: string;
  dataF: string;
  companyId: number;
}

const DashboardRotas: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { go } = useHistory();
  const [dateInit, setDateInit] = useState<string>(
    moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
  );

  const [filter, setFilter] = useState('');
  const [routesAprov, setRoutesAprov] = useState<IreturnGroupRotas[]>([]);
  const [routesInit, setRoutesInit] = useState<IreturnGroupRotas[]>([]);
  const [routesAprovTeste, setRoutesAprovTeste] = useState<IreturnGroupRotas[]>(
    [],
  );
  const [routesInitTeste, setRoutesInitTeste] = useState<IreturnGroupRotas[]>(
    [],
  );
  const [isShowModal, setIsShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UsuarioBD[]>([]);
  // const [rotas, setRotas] = useState<IreturnGroupRotas[]>([]);
  // const [rotasFiltered, setRotasFiltered] = useState<IreturnGroupRotas[]>([]);

  const verifyDate = useCallback(() => {
    const datValidInit = moment(dateInit, 'YYYY-MM-DD', true).isValid();
    const datValidEnd = moment(dateEnd, 'YYYY-MM-DD', true).isValid();

    if (dateInit > dateEnd) {
      Swal.fire({
        icon: 'warning',
        title: 'A data inicial não pode ser maior que a final!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    } else if (datValidInit === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Data inicial inválida!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    } else if (datValidEnd === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Data final inválida!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setDateInit(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
      setDateEnd(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    }
  }, [dateEnd, dateInit]);

  const groupRotas = (
    rotasGroup: GetRota2[],
    sup: UsuarioBD[],
  ): IreturnGroupRotas[] => {
    const routeGroupReturn = rotasGroup.reduce((newRotas, itemAtual): any => {
      const guardaNewRotas: IreturnGroupRotas[] = newRotas || [];
      const indexElement = guardaNewRotas.findIndex(
        (element) =>
          element.supervisorId === itemAtual.supervisorId &&
          element.data === itemAtual.data,
      );

      if (
        indexElement !== -1 &&
        itemAtual.status === 'A Aprovar' &&
        guardaNewRotas[indexElement].status === 'A Aprovar'
      ) {
        guardaNewRotas[indexElement].Visita = [
          ...guardaNewRotas[indexElement].Visita,
          {
            id: itemAtual.Visita.id,
            postoId: itemAtual.Visita.postoId,
            data: itemAtual.Visita.data,
            companyId: itemAtual.Visita.companyId,
            status: itemAtual.status,
            Posto: itemAtual.Visita.Posto,
            turno: itemAtual.Visita.turno,
            type: itemAtual.type,
          },
        ];
      } else if (
        indexElement !== -1 &&
        itemAtual.status !== 'A Aprovar' &&
        guardaNewRotas[indexElement].status !== 'A Aprovar'
      ) {
        guardaNewRotas[indexElement].Visita = [
          ...guardaNewRotas[indexElement].Visita,
          {
            id: itemAtual.Visita.id,
            postoId: itemAtual.Visita.postoId,
            data: itemAtual.Visita.data,
            companyId: itemAtual.Visita.companyId,
            status: itemAtual.status,
            Posto: itemAtual.Visita.Posto,
            turno: itemAtual.Visita.turno,
            type: itemAtual.type,
          },
        ];
      } else if (indexElement === -1) {
        guardaNewRotas.push({
          companyId: itemAtual.companyId,
          data: itemAtual.data,
          justificativa: itemAtual.justificativa,
          rotaId: itemAtual.rotaId,
          status: itemAtual.status,
          supervisorId: itemAtual.supervisorId,
          supervisorName: searchSupervisor(itemAtual.supervisorId, sup),
          visitaId: itemAtual.visitaId,
          coordenadorName: searchSupervisor(
            itemAtual.approvedBy ? itemAtual.approvedBy : -1,
            sup,
          ),
          dataAprov: itemAtual.approvedDate
            ? itemAtual.approvedDate.split(' ')[0]
            : '',
          Visita: [
            {
              id: itemAtual.Visita.id,
              postoId: itemAtual.Visita.postoId,
              data: itemAtual.Visita.data,
              companyId: itemAtual.Visita.companyId,
              status: itemAtual.status,
              Posto: itemAtual.Visita.Posto,
              turno: itemAtual.Visita.turno,
              type: itemAtual.type,
            },
          ],
        });
      }
      return guardaNewRotas;
    }, []);

    return routeGroupReturn;
  };

  const getItens = useCallback(async () => {
    // const today = moment(new Date()).format('YYYY-MM-DD');
    // console.log('EMP', empresaPrincipal);
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : 1;
    const GetSupervisores = await api.get(`/allUsers/${companyId}`);
    const GetRota2s = await api.get(
      `/rotas?dataIni=${dateInit}&dataFim=${dateEnd}`,
    );

    const usersAll: UsuarioBD[] = GetSupervisores.data;
    const arrayRoutesGroup = groupRotas(GetRota2s.data, usersAll);

    setUsers(usersAll.filter((i) => i.acesso === '2'));
    setRoutesInit(arrayRoutesGroup.filter((e) => e.status !== 'A Aprovar'));
    setRoutesAprov(arrayRoutesGroup.filter((e) => e.status === 'A Aprovar'));
    setRoutesInitTeste(
      arrayRoutesGroup.filter((e) => e.status !== 'A Aprovar'),
    );
    setRoutesAprovTeste(
      arrayRoutesGroup.filter((e) => e.status === 'A Aprovar'),
    );
    // setRotas(arrayRoutesGroup);
    // setRotasFiltered(arrayRoutesGroup);
  }, [dateEnd, dateInit, empresaPrincipal]);

  const handleAprovAutomatic = useCallback(
    (dateIni: string, dateFim: string, supervisorId: number) => {
      setLoading(true);
      const find = users.find((i) => i.id === supervisorId);
      const companyPrinc = !find ? 0 : find.companyId;

      const obj: AprovAutomatic = {
        companyId: companyPrinc,
        dataF: dateFim,
        dataI: dateIni,
        supervisorId,
      };
      // console.log(obj);
      api
        .post('/rotas/agendaAprovacao', obj)
        .then(() => {
          // console.log(resp.data);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text:
              'Rotas criadas para esses dias serão aprovadas automáticamente.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch((err) => {
          console.log(err.message);
          console.log(err.response);
          setLoading(false);
          Swal.fire({
            icon: 'warning',
            title: 'Erro ao cadastrar a automatização das datas!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    },
    [go, users],
  );

  useEffect(() => {
    getItens();
  }, [getItens]);

  useMemo(() => {
    setRoutesInitTeste(
      routesInit.filter((e) => e.supervisorName?.indexOf(filter) > -1),
    );
    setRoutesAprovTeste(
      routesAprov.filter((e) => e.supervisorName?.indexOf(filter) > -1),
    );
  }, [filter, routesInit, routesAprov]);

  return (
    <>
      <ModalConexao />
      <ModalAprovAutomatic
        onClose={() => setIsShowModal(false)}
        visible={isShowModal}
        onAprov={handleAprovAutomatic}
        supervisores={users.map((i) => ({ id: i.id, nome: i.nome }))}
      />
      <Container>
        <DivHead>
          <Title>Acompanhamento de Rotas</Title>
          <button type="button" onClick={() => setIsShowModal(true)}>
            <p>Aprovação Automática</p>
          </button>
        </DivHead>
        <DivFilter>
          <ContainerInputDate>
            <InputTextFilter>
              <SearchIcon />
              <input
                type="date"
                value={dateInit}
                onChange={(e) => {
                  setDateInit(e.currentTarget.value);
                }}
                onBlur={() => {
                  verifyDate();
                }}
              />
            </InputTextFilter>
            <InputTextFilter>
              <SearchIcon />
              <input
                type="date"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e.currentTarget.value);
                }}
                onBlur={() => {
                  verifyDate();
                }}
              />
            </InputTextFilter>
          </ContainerInputDate>
          <ContainerInputDate style={{ flex: 2 }}>
            <InputTextFilter>
              <SearchIcon />
              <input
                type="text"
                value={filter}
                placeholder="Informe o supervisor"
                onChange={(e) => {
                  setFilter(e.currentTarget.value);
                }}
              />
            </InputTextFilter>
          </ContainerInputDate>
        </DivFilter>

        {routesAprov.length > 0 && <Title>Aprovação de Rotas</Title>}
        {routesAprovTeste.map((itemRota, index) => {
          return (
            <div key={`${itemRota.visitaId}-${index.toString()}`}>
              <CardAgendamentoRota item={itemRota} />
              <div style={{ height: 16 }} />
            </div>
          );
        })}
        {routesInit.length > 0 && <Title>Rotas</Title>}
        {routesInitTeste.map((itemRota, index) => {
          return (
            <div key={`${itemRota.visitaId}-${index.toString()}`}>
              <CardAgendamentoRota item={itemRota} />
              <div style={{ height: 16 }} />
            </div>
          );
        })}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default DashboardRotas;
