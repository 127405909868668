import 'antd/dist/antd.css';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

import { useAuth } from '../../hooks/auth';
import { useDrawer } from '../../hooks/drawer';

import Perfil from '../../assets/Perfil.png';
import Vector from '../../assets/Vector.png';
import './DashedBox.css';
import {
  Container,
  ContainerHeader,
  ContainerPerfil,
  LineVertical,
  ContainerUser,
  NameUser,
  UserOffice,
  VectorImg,
  ModalMenuUser,
  CardMenuLateral,
  TitleCard,
  TextCard,
  CardMenuLateralLogOff,
  ButtonNoneBorder,
  PhotoUser,
  LogoApp,
  LogoAppSeg,
} from './styles';

interface Props {
  toggleTheme(): void;
}

const DrawerAppHeader: React.FC<Props> = ({ toggleTheme }) => {
  const currentPage = useLocation();

  const { signOut, empresaPrincipal, user } = useAuth();
  const { updateDrawer, isActive } = useDrawer();
  const { colors } = useContext(ThemeContext);

  const [classMenuUser, setClassMenuUser] = useState('hidden');

  const handleValidateSignOut = useCallback(() => {
    Swal.fire({
      title: 'Tem certeza que deseja sair?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: colors.success,
      cancelButtonColor: colors.error,
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        signOut();
      }
    });
  }, [colors.error, colors.success, signOut]);

  const [showButton, setShowButton] = useState(false);

  const handleUpdateButton = useCallback(() => {
    updateDrawer(!isActive);
  }, [isActive, updateDrawer]);

  useEffect(() => {
    if (window.innerWidth < 1152) {
      setShowButton(true);
    }
  }, []);

  const mql = window.matchMedia('(max-width: 1152px)');

  mql.addEventListener('change', () => {
    if (window.innerWidth > 1152) {
      setShowButton(false);
      updateDrawer(false);
    } else if (window.innerWidth < 1152) {
      setShowButton(true);
    }
  });

  return (
    <>
      {currentPage.pathname !== '/' &&
        currentPage.pathname !== '/signup' &&
        currentPage.pathname !== '/forgot-password' &&
        currentPage.pathname !== '/politica-privacidade' && (
          <>
            <Container>
              <ContainerHeader>
                {showButton && (
                  <button
                    type="button"
                    className="drawer"
                    id="btnClose"
                    onClick={handleUpdateButton}
                  >
                    {isActive ? <AiOutlineClose /> : <AiOutlineMenu />}
                  </button>
                )}

                {!showButton && <LogoApp>Gestão de</LogoApp>}
                {!showButton && <LogoAppSeg>Segurança</LogoAppSeg>}

                <ContainerPerfil>
                  <LineVertical />
                  <ContainerUser>
                    <NameUser>
                      {user
                        ? `${user.nome.split(' ')[0]} ${
                            user.nome.split(' ')[1]
                          }`
                        : ''}
                    </NameUser>
                    <UserOffice>Coordenador</UserOffice>
                  </ContainerUser>
                  <ButtonNoneBorder
                    onClick={() => {
                      classMenuUser === 'hidden'
                        ? setClassMenuUser('visible')
                        : setClassMenuUser('hidden');
                    }}
                  >
                    <VectorImg
                      src={Vector}
                      alt="Arrow"
                      status={classMenuUser}
                    />
                  </ButtonNoneBorder>
                  <PhotoUser>
                    <VectorImg src={Perfil} alt="alt" />
                  </PhotoUser>
                </ContainerPerfil>
              </ContainerHeader>

              <ModalMenuUser
                nameClass={classMenuUser}
                className={classMenuUser}
              >
                <CardMenuLateral>
                  <TitleCard>Empresa:</TitleCard>
                  <TextCard>
                    {empresaPrincipal !== undefined
                      ? empresaPrincipal[0].NOME
                      : ''}
                  </TextCard>
                </CardMenuLateral>
                <CardMenuLateral>
                  <Link to="/profile">
                    <TextCard>Acessar meu perfil</TextCard>
                  </Link>
                </CardMenuLateral>
                <CardMenuLateral>
                  <TextCard>FAQ</TextCard>
                </CardMenuLateral>

                <CardMenuLateralLogOff>
                  <ButtonNoneBorder onClick={handleValidateSignOut}>
                    <TextCard>Sair</TextCard>
                  </ButtonNoneBorder>
                </CardMenuLateralLogOff>
              </ModalMenuUser>
            </Container>
          </>
        )}
    </>
  );
};

export default DrawerAppHeader;
