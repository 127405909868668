import styled from 'styled-components';

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* font-family: Montserrat; */
`;

export const ContainerIframe = styled.div`
  width: 100%;
  // height: 100vh;
  max-height: 100vh;
  margin-top: 26px;
  border-radius: 8px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const BtnAdd = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 90%;
  padding: 5px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  border: none;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  p {
    margin: 0px;
    padding: 0px;
    color: ${(props) => props.theme.colors.white};
    font-family: 'Montserrat', sans-serif;
  }
`;
