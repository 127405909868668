import { Reducer } from 'redux';
import { IStateClientResultFilter } from './types';

const INITIAL_STATE: IStateClientResultFilter = {
  resultFilter: {
    streetAddress: '',
    numberAddress: '',
    districtAddress: '',
    countyAddress: '',
    stateAddress: '',
    latitudeAddress: '',
    longitudeAddress: '',
    cepAddress: '',
  },
};

const clientRegister: Reducer<IStateClientResultFilter> = (
  state = INITIAL_STATE,
  action,
) => {
  // console.log('state');
  // console.log(state);
  // console.log('action');
  // console.log(action);

  switch (action.type) {
    case 'UPDATE_RESULT_FILTER': {
      // console.log('action.payload');
      // console.log(action.payload);
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
export default clientRegister;
