import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  
  &:hover {
    background-color: #0056b3;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const FileName = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-style: italic;
`;

export const ContainerM = styled.div`
  width: 90%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-height: 80vh; /* Define a altura máxima do modal */
`;


export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Fundo semitransparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Certifica-se de que o modal aparece acima de outros elementos */
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.white};
    height: 48px;
    width: 48px;
    border-start-end-radius: 8px;
    border-end-start-radius: 12px;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  svg {
    margin: 0px auto;
    height: 100px;
    width: 100px;
    color: ${(props) => props.theme.colors.gray8};
  }
`;

export const ContainerHeaderDados = styled.div`
  flex-direction: column; 
  display: flex;
  margin: 0px auto;
  justify-content: center;
  .titulo {
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
  }
  p {
    margin-top: 10px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  button {
    padding: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;
    border: none;
    min-width: 200px;
  }
`;

export const ModalContent = styled.div`
  padding: 16px;
  overflow-y: auto;
  flex: 1;
`;

export const ScrollableList = styled.div`
  max-height: 60vh; /* Define a altura máxima para a lista */
  overflow-y: auto; /* Permite rolagem */
  padding-right: 8px; /* Espaço para a barra de rolagem */
`;