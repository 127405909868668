import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FiRefreshCw } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { GetRotaComplete } from '../../models/Rota';
import { UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import ModalConexao from '../../components/ModalConexao';
import api from '../../services/api';

import {
  Container,
  TitlePage,
  Filter,
  Options,
  Actions,
  Animeted,
  ContainerInputDate,
} from './styles';

interface ItemTable {
  postoId: number;
  type: 'Intercorrência' | 'Visita';
  userId: number;
  userIdAprov: number;
  clientId: number;
  visitaId: string;
  postoName: string;
  userName: string;
  status: string;
  justificativa: string;
  dataMarcada: string;
  dateAprov: string;
  userNameAprov: string;
  timeIni: string;
  timeFim: string;
  clientName: string;
  timeLimpeza: string;
  endereco: string;
}

const headers = [
  { name: 'Id Visita', field: 'visitaId', sortable: false },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Cliente', field: 'clientName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Endereço', field: 'endereco', sortable: true },
  { name: 'Data de Aprovação', field: 'dateAprov', sortable: true },
  { name: 'Nome do supervisor', field: 'userName', sortable: true },
  { name: 'Data da Visita', field: 'dataMarcada', sortable: true },
  { name: 'Hora Inicial', field: 'timeIni', sortable: true },
  { name: 'Hora Final', field: 'timeFim', sortable: true },
  { name: 'Tempo de Execução', field: 'timeLimpeza', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Justificativa', field: 'justificativa', sortable: true },
];

export const RelatorioVisitas: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(false);
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [clienteIdSelect, setClienteIdSelect] = useState(-1);
  const [clientes, setClientes] = useState<Clientes[]>([]);
  const [userIdSelect, setUserIdSelect] = useState(-1);
  const [usersVig, setUsersVig] = useState<UsuarioBD[]>([]);
  const [usersAll, setUsersAll] = useState<UsuarioBD[]>([]);
  const [listHist, setListHist] = useState<ItemTable[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const tempoLimpeza = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return ' - ';
  }, []);

  useEffect(() => {
    if (dateInit === '' || dateEnd === '') return;
    setIsLoading(true);
    api
      .get(`/rotas?dataIni=${dateInit}&dataFim=${dateEnd}`)
      .then((resp) => {
        const rotasHist: GetRotaComplete[] = resp.data;
        const vistApr = rotasHist.filter(
          (it) =>
            it.status !== 'A Aprovar' &&
            it.status !== 'Rejeitada' &&
            it.status !== 'Reprovada',
        );

        const objItem: ItemTable[] = [];
        vistApr.forEach((item) => {
          const find = objItem.find((i) => i.visitaId === item.visitaId);
          if (!find) {
            const obj: ItemTable = {
              clientId: item.Visita.Posto.clienteId,
              clientName: '',
              timeFim:
                item.Historico.length > 0 ? item.Historico[0].timeFim : '',
              timeIni:
                item.Historico.length > 0 ? item.Historico[0].timeIni : '',
              type:
                item.type === 'intercorrencia' ? 'Intercorrência' : 'Visita',
              dataMarcada: moment(item.data).format('DD/MM/YYYY'),
              endereco: item.Visita.Posto.endereco,
              justificativa: item.justificativa,
              postoId: item.Visita.postoId,
              postoName: item.Visita.Posto.name,
              status: item.status === 'Aprovada' ? 'A Iniciar' : item.status,
              timeLimpeza:
                item.Historico.length > 0
                  ? tempoLimpeza(
                      !item.Historico[0].timeIni
                        ? item.Historico[0].createdAt
                        : item.Historico[0].timeIni,
                      !item.Historico[0].timeFim
                        ? item.Historico[0].updatedAt
                        : item.Historico[0].timeFim,
                    )
                  : '-',
              userId: item.supervisorId,
              userName: '',
              visitaId: item.visitaId,
              dateAprov: !item.approvedDate
                ? ''
                : moment(item.approvedDate).format('DD/MM/YYYY'),
              userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
              userNameAprov: '',
            };
            objItem.push(obj);
          }
        });
        setListHist(objItem);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dateEnd, dateInit, tempoLimpeza]);

  const getClients = useCallback(() => {
    api
      .get(
        `/clientes/empresa?empresaId=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '0'
        }`,
      )
      .then((response) => {
        const resp: Clientes[] = response.data;
        // console.log(resp);
        setClientes(
          resp.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }),
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [empresaPrincipal]);

  const getUsers = useCallback(async () => {
    const usersGet = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );
    const usersResp: UsuarioBD[] = usersGet.data;
    // console.log(usersAll);
    setUsersVig(
      usersResp
        .filter((item) => item.acesso === '2')
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        }),
    );
    setUsersAll(usersResp);
  }, [empresaPrincipal]);

  useEffect(() => {
    getUsers();
    getClients();
  }, [getClients, getUsers]);

  const listHistMemo: ItemTable[] = useMemo(() => {
    let list = listHist.map((item) => {
      const find = usersAll.find((it) => it.id === item.userId);
      const findAprov = usersAll.find((it) => it.id === item.userIdAprov);
      const findCli = clientes.find((it) => {
        return it.id === String(item.clientId);
      });
      const obj = item;
      if (find) {
        obj.userName = find.nome;
      }
      if (findAprov) {
        obj.userNameAprov = findAprov.nome;
      }
      if (findCli) {
        obj.clientName = findCli.name;
      }
      return obj;
    });
    if (userIdSelect !== -1) {
      list = list.filter((it) => it.userId === userIdSelect);
    }
    if (clienteIdSelect !== -1) {
      list = list.filter(
        (it) => String(it.clientId) === String(clienteIdSelect),
      );
    }
    return list.sort((a, b) => {
      if (a.dataMarcada > b.dataMarcada) return 1;
      if (b.dataMarcada > a.dataMarcada) return -1;
      return 0;
    });
  }, [listHist, userIdSelect, clienteIdSelect, usersAll, clientes]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Relatório de Visitas</TitlePage>

        <Options>
          <div>
            <button
              type="button"
              className={!isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(false);
                setUserIdSelect(-1);
              }}
            >
              Clientes
            </button>
          </div>
          <div>
            <button
              type="button"
              className={isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(true);
                setClienteIdSelect(-1);
              }}
            >
              Supervisores
            </button>
          </div>
        </Options>

        <Filter>
          <ContainerInputDate>
            <input
              type="date"
              value={dateInit}
              onChange={(event) => {
                // console.log(event.target.value);
                setDateInit(event.target.value);
              }}
            />
            <input
              type="date"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
            />
          </ContainerInputDate>
          {isSelectedSupervisor ? (
            <select
              value={userIdSelect}
              onChange={(event) => {
                setUserIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os supervisores</option>
              {usersVig.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.nome}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={clienteIdSelect}
              onChange={(event) => {
                setClienteIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os clientes</option>
              {clientes.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.name}
                </option>
              ))}
            </select>
          )}
        </Filter>
        <Table
          header={headers}
          body={listHistMemo.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg && !isLoading,
          )}
          tableId="TableHistoricRotas"
        />
        {isLoading && (
          <Animeted>
            <FiRefreshCw size={40} className="animate-spin" />
            <p>Carregando ...</p>
          </Animeted>
        )}

        <PaginationComponent
          arrayStateFiltered={listHistMemo}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />
        <Actions>
          <NewImportExel
            fileName="RelatorioSintetico"
            tableId="TableHistoricRotas"
          />
        </Actions>
      </Container>
    </>
  );
};
