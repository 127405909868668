import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding: 58px 72px 48px 49px;
  animation: ${appearFromRight} 1s;

  .widthTotal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    padding: 22px;
  }

  @media screen and (max-width: 550px) {
    .widthTotal {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: initial;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  div {
    display: flex;
    svg {
      margin-bottom: 2px;
    }
    border-bottom-left-radius: 10px;
    & + div {
      margin-left: 25px;
    }
  }

  .search1 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ContainerCadastro = styled.div`
  margin: 0px auto;
  border-radius: 10px;
  background: transparent;
  overflow-x: hidden;

  .divTable {
    border-radius: 10px;
    animation: ${appearFromRight} 1s;
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 45vh;
  overflow-x: auto;
  border-radius: 10px 10px 0 0;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.success};
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => shade(0.1, props.theme.colors.success)};
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.success};
    border-top: 0px;
    th {
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.white};
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
    }
  }
  tbody {
    background-color: ${(props) => props.theme.colors.white};
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;

      label.ant-checkbox-wrapper {
        position: relative !important;
        z-index: 0 !important;
      }

      button {
        background-color: transparent;
        color: ${(props) => props.theme.colors.white};
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.success};
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: ${(props) => props.theme.colors.white3};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.gray12};
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};
  border-radius: 0 0 10px 10px;
  height: auto;

  .divPaginacao {
    display: flex;
    align-items: center;
    width: 0;
    margin-left: auto;
  }

  .btn {
    margin-left: auto;
    margin-right: 15px;
    height: 40px;
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.white};
    border-width: 0px;
    padding: 0 10px;
    font-weight: bold;

    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.gray12};
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ButtonPDF = styled.button`
  width: 244px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  border: none;
  transition: 0.2s;
  cursor: pointer;

  color: ${(props) => props.theme.colors.white};
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
  }
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -9px;
  margin-bottom: 25px;
  div {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white4};
    input {
      width: 100%;
      border: none;
    }
  }
  button {
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
    border-radius: 4px;

    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ContentSearch = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 25px;
  div.input {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white4};
    svg {
      color: ${(props) => props.theme.colors.gray5};
      padding-right: 5px;
    }
    input {
      width: 100%;
      border: none;
      ::placeholder {
        font-size: 16px;
        color: ${(props) => props.theme.colors.gray4};
      }
    }
  }
  div.select {
    height: 48px;
    width: 200px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white4};
    h4 {
      margin: 0px;
      padding: 0px;
    }
    select {
      border: none;
      width: 100%;
      color: ${(props) => props.theme.colors.black2};
    }
  }
`;

export const ListCards = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 5px;
  margin-right: 5px;
  max-height: 50vh;
`;

export const Card = styled.button`
  display: flex;
  row-gap: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray1};
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;

  .avatar {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;

    img {
      border-radius: 30px;
      border: 1px solid ${(props) => props.theme.colors.gray1};
      height: 60px;
      width: 60px;
    }
  }
  .info {
    display: flex;
    flex: 1;
    column-gap: 10px;
    div {
      display: flex;
      flex-direction: column;
      p {
        color: ${(props) => props.theme.colors.black2};
        font-weight: 600;
        font-size: 14px;
        font-family: Montserrat, sans-serif;
        margin: 0px;
      }
      span {
        color: ${(props) => props.theme.colors.black4};
        font-size: 14px;
        font-family: Montserrat, sans-serif;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .info {
      flex-direction: column;
      column-gap: 10px;
    }
  }
`;
