import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};
    -webkit-font-smooothing: antialiased;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }

  border-style, input, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Montserrat','sans-serif';
    font-weight: 500;
    color: 393B41;
  }

  input, textarea {
    color: #000;
  } 

  table {
    text-align: center;
  }

  button {
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #595959;
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
`;
