import React, { useCallback, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from './store';
import DrawerAppHeader from './components/DrawerHeader';
import light from './styles/themes/light';
import dark from './styles/themes/dark';
import GlobalStyle from './styles/global';
import { Container, Content } from './styles';

import AppProvider from './hooks';

import Routes from './routes';
import MenuLateral from './components/DrawerMenu';

const App: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  // console.log("theme",theme)
  const toggleTheme = useCallback(() => {
    // setTheme(light);
    setTheme(theme === 'light' ? 'dark' : 'light');
  }, [theme]);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        <Router>
          <Provider store={store}>
            <AppProvider>
              <Container>
                <DrawerAppHeader toggleTheme={toggleTheme} />
                <Content>
                  <MenuLateral />
                  <Routes />
                </Content>
              </Container>
            </AppProvider>
          </Provider>
          <GlobalStyle />
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  );
};
export default App;
