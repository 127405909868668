import styled from 'styled-components';

export const Container = styled.div``;

export const Pline = styled.p`
  text-align: right;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: italic;
`;
