import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  height: 80%;
  width: 700px;
  overflow-x: auto;
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 10px;
`;

export const LabelInputForm = styled.h3`
  color: ${(props) => props.theme.colors.black2};
  margin-bottom: 0px;
  margin-top: 6px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

interface PropsButtonForm {
  background: string;
}

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 48%;
  height: 50px;
  background-color: ${(props) => props.background};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.black2
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 8px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.black4};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray1};
`;
