import React from 'react';

import { ContainerH1 } from './styled';

interface Props {
  title: string;
}

export const TitlePage: React.FC<Props> = ({ title }) => {
  return <ContainerH1>{title}</ContainerH1>;
};
