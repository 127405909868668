import React, { createContext, useContext, useState, useCallback } from 'react';

interface DrawerContextData {
  isActive: boolean;
  updateDrawer(state: boolean): void;
}

const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

const DrawerProvider: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const updateDrawer = useCallback((active: boolean) => {
    setIsActive(active);
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        isActive,
        updateDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

function useDrawer(): DrawerContextData {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useAuth must be used within an DrawerProvider');
  }

  return context;
}

export { DrawerProvider, useDrawer };
