import { ActionUpdateDrawer } from './types';

export function updateDrawerState(active: boolean): ActionUpdateDrawer {
  return {
    type: 'UPDATE_DRAWER_ACTIVE',
    payload: {
      active,
    },
  };
}
