import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

interface ContainerProps {
  cor: boolean;
}

export const ContainerCadastro = styled.div``;

export const AnimationContainer = styled.div`
  height: 70vh;
  border-radius: 4px;
  animation: ${appearFromRight} 1s;
  background: ${(props) => props.theme.colors.white};
  /* background:grey; */
  padding: 0 74px;
  overflow-y: auto;
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0px auto;
  margin-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  animation: ${appearFromRight} 1s;
`;

export const Content = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;

  button {
    border: none;
    background-color: transparent;
    color: #262626;
    min-width: 200px;

    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px !important;
    font-weight: 600 !important;

    div {
      background-color: black;
      border-bottom: ${(props) => (!props.cor ? 'none' : '1px solid #2f2f2f')};
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  .divFiltros {
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  div {
    display: flex;
    svg {
      margin-bottom: 2px;
    }
    border-bottom-left-radius: 10px;
    & + div {
      margin-left: 25px;
    }
  }

  .search1 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 45vh;
  overflow: auto;
  border-radius: 4px 4px 0 0;
  table {
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.success};
    border-top: 0px;
    th {
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${(props) => props.theme.colors.white};
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
      width: 290px !important;

    }
  }
  tbody {
    background-color: ${(props) => props.theme.colors.white};
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.black3};
      width: 290px !important;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        transition: transform 0.2s;

        svg {
          color: #fff;
        }

        & + button {
          margin-left: 20px;
        }

        &:hover {
          transform: scale(1.08);
        }
      }
    } 
      tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.success};
        /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
        &:nth-child(even) {
          background-color: ${(props) => props.theme.colors.white3};
        }
        &:hover {
            opacity: 0.9 ;
          };
        }
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px !important;

  button {
    & + button {
      background: #e67300;
      margin-left: 10px;
      line-height: 16px;

      svg {
        margin-right: 7px;
        margin-bottom: 5px;
      }

      &:hover {
        background-color: ${shade(0.2, '#e67300')} !important;
      }
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    border-radius: 4px;
    border: none;

    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};
  border-radius: 0 0 4px 4px;
  height: auto;

  .divPaginacao {
    display: flex;
    align-items: center;
    width: 0;
    margin-left: auto;
  }

  .btn {
    margin-left: auto;
    margin-right: 15px;
    height: 40px;
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.white};
    border-width: 0px;
    padding: 0 10px;
    font-weight: bold;

    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerFiltro = styled.div`
  width: 100%;
  margin: 0px auto;
  margin-top: 15px;
  background-color: #3e3946;
  border-radius: 4px;
`;

export const AbaMenu = styled.div`
  margin-left: 0 !important;

  div.geralAba {
    display: flex;
  }
  .dropbtn {
    background-color: ${(props) => props.theme.colors.success};
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  .dropdown {
    position: sticky !important;
    z-index: 2;
    display: inline-block;
    button {
      background-color: ${(props) => props.theme.colors.success};
      border: none;
      height: 40px;
      width: 220px;
      padding-left: 20px;
      padding-right: 10px;
      border-radius: 4px;
      color: #fff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .dropdown-content {
    display: none;
    position: absolute !important;
    background-color: transparent;
    width: auto;
    z-index: 2;
    margin-top: -3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    border-radius: 0px;
    width: 220px;
  }
  .dropdown-content button:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.success)};
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .dropbtn {
    background-color: ${(props) => props.theme.colors.success};
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 4px;
  padding: 10px;
  background-color: #28262e;
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ContainerCadastroEdit = styled.div<ContainerProps>`
  width: 100%;
  margin: 0px auto;
  border-radius: 4px;
  background: ${(props) => (props.cor ? ' #3d3946' : 'transparent')};

  #divGeralCadastro {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: initial;
    padding: 0 20px 0 20px;
  }

  .divTable {
    border-radius: 4px;
    animation: ${appearFromRight} 1s;
  }

  .divDropDown {
    width: 100%;
    div {
      width: 100%;
    }
    p {
      align-items: center;
      margin: 0 10px 4px 0;
      font-size: 18px;
      font-family: Roboto, sans-serif;
    }
    select {
      width: 100%;
      height: 60px;
      margin-left: 0px;
      background-color: #28262e;
      border-radius: 4px;
      padding: 0 10px 0 10px;
      border: none;
      border: 2px solid ${(props) => props.theme.colors.success};
    }
    & + .divDropDown {
      margin-top: 12px;
    }
  }
`;

export const AnimationContainerEdit = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 10px 0 10px 0;
  border-radius: 4px;
  animation: ${appearFromRight} 1s;
  background: #4a4554;

  header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 20px 10px 20px;
    width: 100%;
    height: 55px;
    border-radius: 4px 4px 0 0;
    background: #25222a;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-family: Roboto, sans-serif;
  }

  form {
    flex: 1;
    margin: 30px 0;
    margin-bottom: 5px;
    padding: 0 20px 0 20px;
    width: 100%;
    div {
      margin-top: 0px;
      & + div {
        margin-top: 10px;
      }
    }
    a {
      color: #f4ede8;
      display: block;
      margin-top: 14px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
`;

export const ContainerButtonEdit = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;

  div {
    display: flex;
    width: 100%;

    button {
      & + button {
        margin-left: 10px;
      }
    }
  }

  .divButtonCancelar {
    width: 100%;
    background-color: rgb(102, 0, 0);

    &:hover {
      background-color: ${shade(0.2, 'rgb(102, 0, 0)')};
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    border-radius: 4px;
    border: none;

    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const HeaderForm = styled.h3`
  margin-top: 35px;
  text-align: center;

  color: #2f2f2f;
  font-size: 16px;
  font-weight: 700;
`;

export const DivLineBottom = styled.div`
  width: 100%;
  height: 1px;
  margin: 25px auto 25px auto;

  background-color: #d9d9d9;
`;
export const ContainerInputForm = styled.div``;

export const LabelInputForm = styled.h3`
  color: #2f2f2f;
  margin-bottom: 10px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 41px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 16px;
`;

interface PropsButtonForm {
  background: string;
}

export const SubHeaderEvidence = styled.h3`
  color: #999999;
  margin-top: 32px;

  font-family: 'Montserrat', 'sans-serif';
  font-size: 20px;
  font-weight: 700;
`;

export const ButtonAddPost = styled.button`
  height: 20px;
  border: none;
  background-color: transparent;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 700;

  color: #2f80ed;
`;

export const ContainerTablePost = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 150px;
  overflow: auto;

  table {
    font-family: 'Montserrat', 'sans-serif';
    color: #2f2f2f;
    overflow: auto;
    white-space: nowrap;
    table-layout: fixed;
    text-align: left;
    font-weight: 500;
    font-size: 14px;

    thead {
      width: 9000px;
      td {
        padding-left: 48px;
        min-width: 200px;
        height: 40px;
      }
    }

    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.white3};
      height: 60px;
      td {
        padding-left: 48px;
        min-width: 200px;
      }
    }
    tr:nth-child(even) {
      background-color: transparent;
      height: 60px;
      td {
        padding-left: 48px;
        min-width: 200px;
      }
    }
  }
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 48%;
  height: 50px;
  background-color: ${(props) => props.background};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.black2
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 8px;
`;
