import React, { useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import NewModal from '../../../components/NewModal';
import Button from '../../../components/Button';

import { ButtonClose, Container, ButtonFiltro } from './styles';

export interface OptionSelect {
  value: number;
  label: string;
}

export interface FilterOBJ {
  name: string;
  endereco: string;
  type: string;
  turno: string;
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  filterPrin: FilterOBJ;
  setFilterPrin: (val: FilterOBJ) => void;
}

export const ModalFilterPosto: React.FC<Props> = ({
  isVisible,
  onClose,
  filterPrin,
  setFilterPrin,
}) => {
  const [name, setName] = useState('');
  const [endereco, setEndereco] = useState('');
  const [typePer, setTypePer] = useState('');
  const [turno, setTurno] = useState('');

  useEffect(() => {
    if (isVisible) {
      setEndereco(filterPrin.endereco);
      setName(filterPrin.name);
      setTypePer(filterPrin.type);
      setTurno(filterPrin.turno);
    }
  }, [
    filterPrin.endereco,
    filterPrin.name,
    filterPrin.turno,
    filterPrin.type,
    isVisible,
  ]);

  return (
    <NewModal isVisible={isVisible}>
      <ButtonClose>
        <button type="button" onClick={onClose}>
          <FiX size={22} />
        </button>
      </ButtonClose>
      <Container>
        <header>
          <h1>Filtros de Posto</h1>
        </header>
        <p>Nome do Posto</p>
        <div className="input">
          <FiSearch size={20} />
          <input
            value={name}
            placeholder="Buscar"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <p>Endereço</p>
        <div className="input">
          <FiSearch size={20} />
          <input
            value={endereco}
            placeholder="Buscar"
            onChange={(event) => {
              setEndereco(event.target.value);
            }}
          />
        </div>
        <div className="multi">
          <div className="one">
            <p>Tipo de periodicidade</p>
            <div className="select">
              <select
                value={typePer}
                onChange={(event) => setTypePer(event.target.value)}
              >
                <option value="">TODOS</option>
                <option value="dir">Diária</option>
                <option value="sem">Semanal</option>
                <option value="men">Mensal</option>
                <option value="anu">Anual</option>
                <option value="nad">Sem periodicidade</option>
              </select>
            </div>
          </div>
          <div className="one">
            <p>Turno</p>
            <div className="select">
              <select
                value={turno}
                onChange={(event) => setTurno(event.target.value)}
              >
                <option value="">TODOS</option>
                <option value="diurno">Diurno</option>
                <option value="noturno">Noturno</option>
              </select>
            </div>
          </div>
        </div>

        <br style={{ margin: '30px 0px' }} />

        <div className="action">
          <Button
            type="button"
            onClick={() => {
              setFilterPrin({
                endereco,
                name,
                turno,
                type: typePer,
              });
              onClose();
            }}
          >
            Filtrar
          </Button>
          <ButtonFiltro
            type="button"
            onClick={() => {
              setEndereco('');
              setName('');
              setTypePer('');
              setTurno('');
            }}
          >
            Limpar filtros
          </ButtonFiltro>
        </div>
      </Container>
    </NewModal>
  );
};
