import { shade } from 'polished';
import styled from 'styled-components';

interface SituationCard {
  situation: string;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 80px);
`;

export const Content = styled.div`
  min-width: 300px;
  max-width: 700px;
  width: calc(100vw - 400px);
  padding: 22px;
  position: fixed;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.white2};
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;

    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black4};
    }
    button {
      position: relative !important;
    }
  }
`;

export const ContentInfo = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: 10px;

  div.tl {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      height: 30px;
      padding: 10px;
      border-radius: 6px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;

      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;

      &:hover {
        background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
      }
    }
  }
`;

export const InputDate = styled.input`
  display: flex;
  padding: 12px;
  width: 100%;
  color: ${(props) => props.theme.colors.black4};
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white4};
  border-radius: 4px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white4};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.black4};
`;

// export const Fil = styled.div`
//   flex-direction: row;
//   display: flex;
// `;

// export const Fil2 = styled.div`
//   flex-direction: row;
//   display: flex;
//   justify-content: space-between;
//   margin-top: 32px;
// `;

export const Option = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
`;

export const Label = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
`;

interface Props {
  selected: boolean;
}

export const Bool = styled.div<Props>`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.success : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray1};
  margin-right: 6px;
`;

export const DivRotas = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
`;

export const ButtonVolt = styled.button`
  height: 30px;
  width: 30px;
  z-index: 2;
  background: ${(props) => props.theme.colors.white};
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  transition: 0.2s;

  svg {
    color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.white)};
  }
`;

export const ButtonVolt2 = styled.button`
  height: 48px;
  width: 48px;
  z-index: 2;
  top: 160px;
  background: ${(props) => props.theme.colors.primary};
  border-start-end-radius: 12px;
  border-end-end-radius: 12px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  transition: 0.2s;

  svg {
    color: ${(props) => props.theme.colors.white};
  }
  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
  }
`;

export const Line = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray3};
`;

export const ItemMap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
  }
`;

export const ContentRota = styled.div<Props>`
  width: ${(props) => (props.selected ? '300px' : '0px')};
  transition: 0.5s;
  padding: 22px;
  position: fixed;
  z-index: 2;
  right: 0px;
  background-color: ${(props) => props.theme.colors.white2};
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;

    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black4};
    }
    button {
      position: relative !important;
    }
  }

  .info {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black2};
      margin: 0px;
    }
    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      color: ${(props) => props.theme.colors.black2};
      display: flex;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black4};
      margin: 0px;
    }
    .card {
      display: flex;
      height: 80px;
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.gray1};
      border-radius: 4px;

      .line {
        background-color: ${(props) => props.theme.colors.success};
        height: 100%;
        width: 6px;
        margin-right: 10px;
      }
      .info {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          color: ${(props) => props.theme.colors.black2};
          margin: 0px;
        }
      }
      display: flex;
    }
  }
`;
