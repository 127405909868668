export const returnNameFilial = (
  stringIndex: string | undefined,
  filiais: any[] | undefined,
): string | undefined => {
  if (filiais?.length !== 0) {
    const nameFilial = filiais?.find((e?) => e.ID === stringIndex);
    return nameFilial?.NOME;
  }
  return `${stringIndex}-`;
};
