import { shade } from 'polished';
import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  font-family: 'Montserrat';
  padding: 0px 74px 30px 74px;
  color: ${(props) => props.theme.colors.black2};

  header {
    h1 {
      font-size: 20px;
      color: ${(props) => props.theme.colors.black};
      font-weight: 600;
    }
  }

  p {
    color: ${(props) => props.theme.colors.black2};
    margin: 0px;
    margin-top: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  .action {
    width: 100%;
    display: flex;
    column-gap: 20px;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      row-gap: 20px;
    }
    color: ${(props) => props.theme.colors.white};
  }

  .input {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white4};
    svg {
      color: ${(props) => props.theme.colors.gray5};
      padding-right: 5px;
    }
    input {
      width: 100%;
      border: none;
      ::placeholder {
        font-size: 14px;
        color: ${(props) => props.theme.colors.gray4};
      }
    }
  }

  div.multi {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }

  div.one {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.select {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.white4};
    select {
      border: none;
      width: 100%;
      color: ${(props) => props.theme.colors.black2};
    }
  }
`;

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ButtonFiltro = styled(Button)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  &:hover {
    background: ${(props) => shade(0.2, props.theme.colors.white)};
  }
`;
