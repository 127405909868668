import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RegisterUser } from '..';
import DaysWeek from '../../../components/DaysWeek';
import { maskTel } from '../../../components/Input/mask';
import { Loading } from '../../../components/Loading';
import NewModal from '../../../components/NewModal';
import api from '../../../services/api';
import { validationUser } from '../validation';
import {
  Container,
  HeaderForm,
  DivLineBottom,
  ButtonForm,
  BoxMiddle,
  BoxRowInput,
  ContainerCadastro,
  LabelInputForm,
  InputForm,
  ButtonClose,
} from './styles';

interface RegisterUpdateUser {
  name: string;
  acesso: number;
  cpf: string;
  email: string;
  telefone: string;
  postoId?: number;
  diasTrab: string;
  turnoIni: string;
  turnoFim: string;
  origem: string;
  turno: string;
  ID: number;
  dtAdmissao: string;
  alternada: boolean;
  IdEmpresaFilial: number;
  IdEmpresas: number;
}

interface Props {
  isVisible: boolean;
  onClose(): void;
  userPre: RegisterUser;
  idUser: number;
}

export const ModalEditPreCadastro: React.FC<Props> = ({
  isVisible,
  onClose,
  userPre,
  idUser,
}) => {
  const history = useHistory();

  const [access, setAccess] = useState(99);
  const [diasTrab, setDiasTrab] = useState<string[]>([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [turno, setTurno] = useState('Diurno');
  const [turnoFim, setTurnoFim] = useState('');
  const [turnoInit, setTurnoInit] = useState('');
  const [typeSeman, setTypeSeman] = useState('Dia');
  const [dtAdmissao, setDtAdmissao] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);

  const formatarTelefone = useCallback((str: string): string => {
    const tel: string = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    return tel;
  }, []);

  const handleSubmit = useCallback(() => {
    const obj: RegisterUpdateUser = {
      ID: idUser,
      cpf: userPre.cpf,
      acesso: access,
      diasTrab: diasTrab.join(' - '),
      email,
      name,
      origem: userPre.origem,
      telefone: formatarTelefone(telefone),
      turno,
      turnoFim,
      turnoIni: turnoInit,
      alternada: typeSeman === 'Alternada',
      dtAdmissao,
      IdEmpresaFilial: userPre.IdEmpresaFilial,
      IdEmpresas: userPre.IdEmpresas,
    };

    const validateUser = validationUser(obj);

    // if (validateUser) {
    //   console.log('VALIDADE');
    // } else {
    //   console.log('NOT VALIDATE');
    // }
    if (validateUser === true) {
      setLoading(true);
      api
        .put('/empresas/updateFuncionarios', obj)
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Funcionario editado com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          // console.log(e.message);
          // console.log(e.response.data);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao editar o funcionário!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
        });
    }
  }, [
    access,
    diasTrab,
    dtAdmissao,
    email,
    formatarTelefone,
    history,
    idUser,
    name,
    telefone,
    turno,
    turnoFim,
    turnoInit,
    typeSeman,
    userPre.IdEmpresaFilial,
    userPre.IdEmpresas,
    userPre.cpf,
    userPre.origem,
  ]);

  useEffect(() => {
    setAccess(userPre.acesso);
    setDiasTrab(
      userPre.diasTrab !== null
        ? userPre.diasTrab.split(' ').join('').split('-')
        : [],
    );
    setEmail(userPre.email);
    setName(userPre.name);
    setTelefone(userPre.telefone ? maskTel(userPre.telefone) : '');
    setTurno(userPre.turno);
    setTurnoFim(userPre.turnoFim);
    setTurnoInit(userPre.turnoIni);
    setTypeSeman(userPre.alternada ? 'Alternada' : 'Dia');
  }, [
    userPre.acesso,
    userPre.alternada,
    userPre.diasTrab,
    userPre.email,
    userPre.name,
    userPre.postoId,
    userPre.telefone,
    userPre.turno,
    userPre.turnoFim,
    userPre.turnoIni,
  ]);

  return (
    <NewModal isVisible={isVisible}>
      <Container>
        <div className="close">
          <ButtonClose onClick={onClose}>
            <FiX size={20} />
          </ButtonClose>
        </div>
        <HeaderForm>Editar Pré-cadastro</HeaderForm>
        <DivLineBottom />
        <ContainerCadastro>
          <BoxRowInput>
            <div className="containerInputAndLabel">
              <LabelInputForm>Tipo de acesso:</LabelInputForm>
              <select
                id="acesso"
                className="selectStyle"
                value={access}
                onChange={(event) => {
                  setAccess(Number(event.target.value));
                }}
              >
                <option value="99">Selecione o tipo</option>
                <option value="0">Colaborador</option>
                <option value="1">Coordenador</option>
                <option value="2">Supervisor</option>
                <option value="3">Vigilante</option>
              </select>
            </div>
          </BoxRowInput>
          <BoxRowInput>
            <div className="containerInputAndLabel">
              <LabelInputForm>Nome:</LabelInputForm>
              <InputForm
                placeholder="Digite aqui"
                value={name}
                id="name"
                onChange={(event) => setName(event.target.value)}
              />
            </div>

            <div className="containerInputAndLabel">
              <LabelInputForm>Turno de Trabalho</LabelInputForm>
              <select
                className="selectStyle"
                value={turno}
                onChange={(event) => {
                  setTurno(event.target.value);
                }}
              >
                <option value="Diurno">DIURNO</option>
                <option value="Noturno">NOTURNO</option>
              </select>
            </div>
          </BoxRowInput>
          {access === 0 && (
            <BoxRowInput>
              <div className="containerInputAndLabel">
                <LabelInputForm>E-mail:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="containerInputAndLabel">
                <LabelInputForm>Telefone:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  id="telefone"
                  value={telefone}
                  onChange={(event) => setTelefone(maskTel(event.target.value))}
                />
              </div>
            </BoxRowInput>
          )}
          <BoxRowInput>
            <div className="containerInputAndLabel">
              <LabelInputForm>Tipo de Escala</LabelInputForm>
              <select
                className="selectStyle"
                value={typeSeman}
                onChange={(event) => {
                  setTypeSeman(event.target.value);
                }}
              >
                <option value="Dia">Semanal</option>
                <option value="Alternada">Alternada</option>
              </select>
            </div>

            {typeSeman !== 'Alternada' ? (
              <div className="containerInputAndLabel">
                <LabelInputForm>Dias trabalhados:</LabelInputForm>
                <DaysWeek
                  setPeriodicidade={setDiasTrab}
                  periodicidade={diasTrab}
                />
              </div>
            ) : (
              <div className="containerInputAndLabel">
                <LabelInputForm>Data de admissão:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  id="dtAdmissao"
                  type="date"
                  value={dtAdmissao}
                  onChange={(event) => setDtAdmissao(event.target.value)}
                />
              </div>
            )}
          </BoxRowInput>
          {access === 3 && (
            <BoxRowInput>
              <div className="containerInputAndLabel">
                <LabelInputForm>Horário turno inicial:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  id="turnoIni"
                  value={turnoInit}
                  onChange={(event) => setTurnoInit(event.target.value)}
                  type="time"
                />
              </div>

              <div className="containerInputAndLabel">
                <LabelInputForm>Horário turno final:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  id="turnoFim"
                  value={turnoFim}
                  onChange={(event) => setTurnoFim(event.target.value)}
                  type="time"
                />
              </div>
            </BoxRowInput>
          )}
          <div className="containerButtons">
            <ButtonForm prin={false} onClick={onClose}>
              Cancelar
            </ButtonForm>
            <ButtonForm prin onClick={handleSubmit}>
              Cadastrar
            </ButtonForm>
          </div>
        </ContainerCadastro>
        {loading && <Loading />}
      </Container>
    </NewModal>
  );
};
