import React, { ChangeEvent, useState } from 'react';
import { Container, Label, HiddenInput, FileName, ContainerM, ContainerModal, ContainerButton, ContainerHeaderDados, ContainerButtonAtualizar, ModalContent, ScrollableList } from './style';
import * as xlsx from 'xlsx';
import { parse } from 'date-fns'
import { Loading } from '../Loading';
import { FiX } from 'react-icons/fi';
import { AddressComponent, GoogleGeocodeResult, PostoJson, Result, Tarefa } from '../../models/Postos';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface FileInputProps {
  onFileSelect: (file: File | null) => void;
  onHandleSavePostos: (rotas: Array<PostoJson>) => void;
}


async function getGeocodeData(address: string): Promise<GoogleGeocodeResult | null> {
  const response = await api.get<GoogleGeocodeResult>(`postos/maps-get-placeid?address=${address}`);
  return response.data;
}

async function getGeocodeDataComponents(place_id: string): Promise<Result | null> {
  const response = await api.get<Result>(`postos/maps-get-components?place_id=${place_id}`);
  return response.data;
}

function getAddressComponent(components: Array<AddressComponent>, type: string): string {
  const component = components.find(c => c.types.includes(type));
  return component ? component.long_name : '';
}

const formatDate = (dateString: string): string => {
  const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
  return parsedDate.toISOString();
};

const parseDateCell = (cellValue: any): string => {
  if (cellValue instanceof Date) {
    return cellValue.toISOString(); // Se já for Date, converte direto
  } else if (typeof cellValue === 'string') {
    return formatDate(cellValue); // Se for string, usa nosso formato
  }
  throw new Error("Formato de data inválido");
};

const FileInput = ({ onFileSelect, onHandleSavePostos }: FileInputProps) => {
  const [selectedFile, setSelectedFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rotas, setRotas] = useState<Array<PostoJson>>([]);
  const [rotasComErro, setRotasComErro] = useState<Array<PostoJson>>([]);
  const { addToast } = useToast();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const expectedHeader = ["clienteId", "name", "empresaId", "endereco", "typePeriodicidade", "freqSem", "vigIni", "vigFim", "Tarefas", "turno"];

      const file = event.target.files![0];

      const isValid = await validateXLSHeader(file, expectedHeader);
      if (!isValid) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Estrutura da planilha inválida!',
        });
        return;
      }
      setSelectedFile(true);
      setLoading(true);

      const reader = new FileReader();

      reader.onload = async (e: ProgressEvent<FileReader>) => {

        const data = e.target?.result;

        const workbook = xlsx.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows: any[][] = xlsx.utils.sheet_to_json(sheet, { header: 1 });
        console.log(rows.length)
        const rotas: PostoJson[] = [];
        const rotasComErro: PostoJson[] = [];
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];

          const tarefas: Tarefa[] = row[8]
            .split("*")
            .map((tarefa: string) => tarefa.trim())
            .filter((tarefa: string) => tarefa !== "")
            .map((tarefa: string) => ({ name: tarefa }));

          const rota: PostoJson = {
            impClienteId: "",
            importId: "",
            origem: "cad",
            active: true,
            rotaHoje: false,
            horaIni: "",
            horaFim: "",
            clienteId: row[0],
            name: row[1],
            empresaId: row[2],
            endereco: row[3],
            typePeriodicidade: row[4],
            freqSem: row[5],
            freqMes: row[5],
            freqAno: row[5],
            vigIni: parseDateCell(row[6]),
            vigFim: parseDateCell(row[7]),
            Tarefas: tarefas,
            turno: row[9]
          };
          rotas.push(rota);
        }

        for (let i = rotas.length - 1; i >= 0; i--) {
          const posto = rotas[i];
          const geocodeData0 = await getGeocodeData(posto.endereco);
          const geocodeData = await getGeocodeDataComponents(geocodeData0!.place_id);

          if (geocodeData && geocodeData0 && geocodeData0.geometry.location) {
            posto.endereco = geocodeData.formatted_address;
            posto.bairro =
              getAddressComponent(geocodeData.address_components, 'sublocality_level_1') ||
              getAddressComponent(geocodeData.address_components, 'sublocality');
            posto.municipio = getAddressComponent(geocodeData.address_components, 'administrative_area_level_2');
            posto.estado = getAddressComponent(geocodeData.address_components, 'administrative_area_level_1');
            posto.cep = getAddressComponent(geocodeData.address_components, 'postal_code');
            posto.latitude = geocodeData0.geometry.location.lat.toString();
            posto.longitude = geocodeData0.geometry.location.lng.toString();
          } else {
            rotasComErro.push(posto);
            rotas.splice(i, 1);
          }

          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        setLoading(false);
        setRotasComErro(rotasComErro);
        setRotas(rotas.filter(e => e.bairro != ''));
      };

      reader.readAsArrayBuffer(file);

    } catch (error) {
      console.log(error)
    }
  };

  const validateXLSHeader = async (file: File, expectedHeader: string[]): Promise<boolean> => {
    const data = await file.arrayBuffer();
    const workbook = xlsx.read(data, { type: "array" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const headerRow = xlsx.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];

    return expectedHeader.every((col, index) => col === headerRow[index]);
  }

  const formatDateShow = (isoString: string) => {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, '0'); // Pega o dia com dois dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pega o mês com dois dígitos (getMonth é 0-indexado)
    const year = date.getFullYear(); // Pega o ano com quatro dígitos

    return `${day}-${month}-${year}`;
  };

  const confirmPostos = async () => {
    setLoading(true)
    await onHandleSavePostos(rotas)
    setSelectedFile(false)
    setLoading(false)

  }

  const closeModal = () => {
    setSelectedFile(false)
    setRotas([])
    const fileInput = document.getElementById("file-upload") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }
  return (
    <>
      <Container>
        <Label htmlFor="file-upload">Cadastrar postos via planilha</Label>
        <HiddenInput
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          accept=".xlsx"
        />
      </Container>
      {loading && <Loading />}
      {(selectedFile && !loading) && (
        <ContainerModal id="modal" onClick={(e) => { }}>
          <ContainerM>
            <ContainerButton>
              <ContainerHeaderDados>
                <p className="titulo">Validar lista de postos a serem salvos</p>
              </ContainerHeaderDados>
              <button
                type="button"
                onClick={() => closeModal()}
              >
                <FiX style={{ margin: '10px' }} />
              </button>
            </ContainerButton>

            <ModalContent>
              <ScrollableList>
                {rotas.map((rota, index) => {
                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <p>
                        <b>ID Cliente:</b> {rota.clienteId},
                        <b>ID Empresa:</b> {rota.empresaId},
                        <b>Posto:</b> {rota.name},
                        <b>Turno:</b> {rota.turno},
                        <b>Início:</b> {formatDateShow(rota.vigIni)},
                        <b>Fim:</b> {formatDateShow(rota.vigFim)}
                      </p>
                    </div>
                  );
                })}
                <ContainerHeaderDados>
                  <p className="titulo">Postos com erro</p>
                </ContainerHeaderDados>
                {rotasComErro.map((rota, index) => {
                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <p>
                        <b>ID Cliente:</b> {rota.clienteId},
                        <b>ID Empresa:</b> {rota.empresaId},
                        <b>Posto:</b> {rota.name},
                        <b>Turno:</b> {rota.turno},
                        <b>Início:</b> {formatDateShow(rota.vigIni)},
                        <b>Fim:</b> {formatDateShow(rota.vigFim)}
                      </p>
                    </div>
                  );
                })}
              </ScrollableList>
              <ContainerButtonAtualizar>
                <button type="button" onClick={() => confirmPostos()}>
                  Confirmar
                </button>
                <button type="button" onClick={() => closeModal()}>
                  Cancelar
                </button>
              </ContainerButtonAtualizar>
            </ModalContent>

          </ContainerM>
        </ContainerModal>
      )}
    </>
  );
};

export default FileInput;

